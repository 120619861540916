import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAsync } from 'react-use'

import { Button, Card, Col, Form, Input, Row, Table } from 'antd'
import { SearchOutlined, PlusOutlined, DownloadOutlined } from '@ant-design/icons'

import _ from 'lodash'
import dayjs from 'dayjs'
import { mkConfig, generateCsv, download } from 'export-to-csv'

import { useAuth } from '../../contexts/AuthContextProvider'

const List = () => {
  const columns = [
    {
      title: 'Item Code',
      dataIndex: 'itemCode',
      key: 'itemCode',
      width: 150,
      sorter: (a, b) => a.itemCode - b.itemCode
    },
    {
      title: 'Product (Ref. Customer PO)',
      dataIndex: 'productRef',
      key: 'productRef',
      width: 250,
      defaultSortOrder: 'ascend',
      sorter: (a, b) => {
        if (_.get(a, 'productRef', '') < _.get(b, 'productRef', '')) return -1
        if (_.get(b, 'productRef', '') < _.get(a, 'productRef', '')) return 1
        return 0
      }
    },
    {
      title: 'Weight per Bag (gram)',
      dataIndex: 'weight',
      key: 'weight',
      width: 150,
      sorter: {
        compare: (a, b) => a.weight - b.weight,
        multiple: 3
      }
    },
    {
      title: 'Bag per Carton',
      dataIndex: 'bagPerCarton',
      key: 'bagPerCarton',
      width: 150,
      sorter: (a, b) => a.bagPerCarton - b.bagPerCarton
    },
    {
      title: 'Price Each($)',
      dataIndex: 'price',
      key: 'price',
      sorter: (a, b) => a.price - b.price,
      width: 150,
    },
    {
      title: 'Marks',
      dataIndex: 'marks',
      key: 'marks',
      width: 120,
      sorter: (a, b) => {
        if (_.get(a, 'marks', '') < _.get(b, 'marks', '')) return -1
        if (_.get(b, 'marks', '') < _.get(a, 'marks', '')) return 1
        return 0
      }
    },
    {
      title: 'Operation',
      dataIndex: 'operation',
      key: 'operation',
      width: 50,
      render: (data, row) =>
        <div style={{ textAlign: 'center' }}>
          <Button style={{ width: '32px', height: '30px', borderRadius: '4px' }} type="primary" size="small" onClick={() => handleEdit(row)}><SearchOutlined /></Button>
        </div>
    }
  ]
  const navigate = useNavigate()
  const [filter, setfilter] = useState()
  const [btnLoading, setBtnLoading] = useState(false)
  const { db, role, companyId } = useAuth()
  const { value } = useAsync(async () => {
    let ref = db.collection('products').where('cid', '==', companyId)
    const snap = await ref.get()
    let data = snap.docs.map(doc => {
      return {
        ...doc.data(),
        id: doc.id
      }
    })
    if (filter) {
      if (filter.name) {
        data = data.filter(item => {
          return ((item.productRef && item.productRef.toLowerCase().search(filter.name.toLowerCase()) !== -1) || (item.itemCode && item.itemCode.toLowerCase().search(filter.name.toLowerCase()) !== -1))
        })
        // ref = ref.orderBy('productNameEN').startAt(filter.name).endAt(filter.name+'\uf8ff')
      }
    }
    return data
  }, [filter, companyId])

  const handleSearch = (formData) => {
    setfilter(formData)
  }
  const handleCreateProduct = () => {
    navigate('/admin/product/create')
  }
  const handleEdit = (row) => {
    if (row && row.id) navigate(`/admin/product/view/${row.id}`)
  }
  const exportTable = async () => {
    try {
      setBtnLoading(true)
      const csvConfig = mkConfig({
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: false,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        filename: `Product List_${dayjs().format('YYYY-MM-DD')}`
      })
      const data = value.map((o) => {
        return {
          'Item Code': _.get(o, 'itemCode', ''),
          'Name of Product': _.get(o, 'productNameEN', ''),
          'Country': _.get(o, 'country', ''),
          'Weight per Bag (gram)': _.get(o, 'weight', ''),
          'Bag per Carton': _.get(o, 'bagPerCarton', ''),
          'Price': _.get(o, 'price', ''),
          'Marks': _.get(o, 'marks', '')
        }
      })
      const csv = generateCsv(csvConfig)(data)
      download(csvConfig)(csv)
      setBtnLoading(false)
      return Promise.resolve()
    } catch (error) {
      setBtnLoading(false)
      return Promise.reject(error)
    }
  }
  return (
    <Card
      title="Product List"
      headStyle={{ fontWeight: 'bold', fontSize: '20px', lineHeight: '23px', backgroundColor: '#CFE2FF', color: '#154D9F' }}
      bodyStyle={{ boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)', borderRadius: '0 0 10px 10px' }}>
      <Row style={{ marginBottom: 20 }}>
        <Col span={12}>
          <Form onFinish={handleSearch} layout="inline">
            <Form.Item name="name">
              <Input style={{ width: '250px', height: '32px', borderRadius: '3px' }} placeholder="Seach name of product or Item code" />
            </Form.Item>
            <Form.Item>
              <Button style={{ borderRadius: '8px' }} htmlType="submit" type="primary"><SearchOutlined />Search</Button>
            </Form.Item>
          </Form>
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          {
            ['Admin', 'Financial', 'Purchasing', 'Planner'].indexOf(role) !== -1 && <Button style={{ borderRadius: '8px' }} onClick={handleCreateProduct} type="primary"><PlusOutlined />Create Product</Button>
          }
        </Col>
      </Row>
      <Table
        bordered
        rowKey="id"
        style={{ overflowX: 'auto' }}
        columns={columns}
        dataSource={value}
        sortDirections={['ascend', 'descend', 'ascend']}
      />
      <Button style={{ marginTop: 16, borderRadius: '8px' }} loading={btnLoading} onClick={exportTable} type="primary"><DownloadOutlined />Export Table</Button>
    </Card>
  )
}
export default List

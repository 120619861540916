import React from 'react'
// import { useNavigate } from 'react-router-dom'

import { Button, Form, Input, message } from 'antd'

import { useAuth } from '../../contexts/AuthContextProvider'

const RegisterPage = () => {
  const { firebaseApp, auth, user } = useAuth()
  // const navigate = useNavigate()
  const confirmPasswordValidator = ({ getFieldValue }) => ({
    validator(rule, value) {
      if (!value || getFieldValue('password') === value) {
        return Promise.resolve();
      }
      return Promise.reject('The two passwords that you entered do not match!');
    }
  })
  const reauthenticate = async (currentPassword) => {
    var cred = await firebaseApp.auth.EmailAuthProvider.credential(
      user.email, currentPassword);
    return user.reauthenticateWithCredential(cred);
  }
  const handleChange = async ({ currentPassword, password }) => {
    // ยิง API
    const user = auth().currentUser
    await reauthenticate(currentPassword)
    user.updatePassword(password).then(function () {
      // Update successful.
      message.success('Reset password successfully');
      auth().signOut()
    }).catch(function (error) {
      // An error happened.
    });
  }
  return (
    <Form layout="vertical" onFinish={handleChange}>
      <Form.Item name="currentPassword" label="รหัสผ่านปัจจุบัน" rules={[{ required: true }, { min: 6 }]}>
        <Input.Password />
      </Form.Item>
      <Form.Item name="password" label="รหัสผ่าน ใหม่" rules={[{ required: true }, { min: 6 }]}>
        <Input.Password />
      </Form.Item>
      <Form.Item name="confirm-password" label="ยืนยันรหัสผ่าน ใหม่" rules={[{ required: true }, { min: 6 }, confirmPasswordValidator]}>
        <Input.Password />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Reset Password
        </Button>
      </Form.Item>
    </Form>
  )
}

export default RegisterPage

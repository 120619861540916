import React from 'react'
import { Outlet } from 'react-router-dom'

import PurchaseOrderContextProvider from '../../contexts/PurchaseOrderContextProvider'

import './PurchaseOrderPage.less'

const PurchaseOrderPage = () => {
  return (
    <div className='purchase-order'>
      <PurchaseOrderContextProvider>
        <Outlet />
      </PurchaseOrderContextProvider>
    </div>
  )
}

export default PurchaseOrderPage

import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Form as AntdForm, Input, InputNumber, Skeleton, Card, Row, Col, Button, Divider } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import _ from 'lodash'

import { DatePicker } from '../../../components/DateTime'

import { useAuth } from '../../../contexts/AuthContextProvider'
import { dbTodayjs, dayjsToDB } from '../../../utils/tools'
import { usePurchaseOrderContext } from '../../../contexts/PurchaseOrderContextProvider'

const ConfirmProduct = () => {
  const navigate = useNavigate()
  const { poId } = useParams()

  const { db } = useAuth()
  const { productData, productId, productStatus, productAction } = usePurchaseOrderContext()

  const [loading, setLoading] = useState(false)

  const [confirmForm] = AntdForm.useForm()

  const data = {
    id: poId,
    products: productData.map((o) => ({
      ...o,
      prodDate: dbTodayjs(o.prodDate),
      bbd: dbTodayjs(o.bbd)
    }))
  }

  const handleSubmit = async (formData) => {
    try {
      await setLoading(true)
      const data = {
        isConfirmProduct: true,
        products: [
          ...formData.products.map((o) => {
            delete (o.key)
            return {
              ...o,
              quantityCTN: parseFloat(o.quantityCTN),
              quantityBAG: parseFloat(o.quantityBAG),
              prodDate: dayjsToDB(o.prodDate),
              bbd: dayjsToDB(o.bbd)
            }
          })
        ]
      }
      data.products = data.products.map((o) => _.pickBy(o))
      await db.collection('po').doc(poId).set(data, {
        merge: true
      })
      await productAction.clearProduct()
      await setLoading(false)
      await navigate(`/admin/purchase-order/${productId}`)
      return Promise.resolve()
    } catch (error) {
      await setLoading(false)
      return Promise.reject(error)
    }
  }

  const handleSave = async (formData) => {
    try {
      await setLoading(true)
      const data = {
        isConfirmProduct: false,
        products: [
          ...formData.products.map((o) => {
            delete (o.key)
            return {
              ...o,
              quantityCTN: parseFloat(o.quantityCTN),
              quantityBAG: parseFloat(o.quantityBAG),
              prodDate: dayjsToDB(o.prodDate),
              bbd: dayjsToDB(o.bbd)
            }
          })
        ]
      }
      data.products = data.products.map((o) => _.pickBy(o))
      await db.collection('po').doc(poId).set(data, {
        merge: true
      })
      await productAction.clearProduct()
      await setLoading(false)
      await navigate(-1)
      return Promise.resolve()
    } catch (error) {
      await setLoading(false)
      return Promise.reject(error)
    }
  }

  if (loading) return <Skeleton />
  return (
    <Card
      title='Confirm Product'
      headStyle={{ fontWeight: 'bold', fontSize: '20px', lineHeight: '23px', backgroundColor: '#CFE2FF', color: '#154D9F' }}
      bodyStyle={{ boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)', borderRadius: '0 0 10px 10px' }}>
      <AntdForm
        layout="vertical"
        form={confirmForm}
        name="confirmForm"
        initialValues={data}
        onFinish={handleSubmit}
      >
        <AntdForm.List name="products">
          {(fields) => (
            <>
              {fields.map((field) => (
                <div key={field.key}>
                  <Row>
                    <Col span={3}></Col>
                    <Col span={18}>
                      <Row>
                        <div style={{ marginBottom: '8px', fontSize: '18px', fontWeight: 'bold', color: '#154D9F' }}>{data?.products[field.key]?.productRef ?? ''}</div>
                      </Row>
                      <Row gutter={[32, 0]}>
                        <Col span={12}>
                          <AntdForm.Item name={[field.name, 'itemCode']} label="Item Code">
                            <Input />
                          </AntdForm.Item>
                        </Col>
                        <Col span={12}>
                          <AntdForm.Item name={[field.name, 'productRef']} label="Item Name">
                            <Input />
                          </AntdForm.Item>
                        </Col>
                      </Row>
                      <Row gutter={[32, 0]}>
                        <Col span={12}>
                          <AntdForm.Item name={[field.name, 'quantityBAG']} label="Quantity (BAG)">
                            <InputNumber
                              disabled={true}
                              formatter={value => new Intl.NumberFormat('en-US').format(value)}
                              parser={value => value.replace(/\\s?|(,*)/g, '')}
                            />
                          </AntdForm.Item>
                        </Col>
                        <Col span={12}>
                          <AntdForm.Item name={[field.name, 'confirmBAG']} label="Confirm Quantity (BAG)" rules={[{ required: true, message: '*required' }]}>
                            <InputNumber
                              formatter={value => new Intl.NumberFormat('en-US').format(value)}
                              parser={value => value.replace(/\\s?|(,*)/g, '')}
                            />
                          </AntdForm.Item>
                        </Col>
                      </Row>
                      <Row gutter={[32, 0]}>
                        <Col span={12}>
                          <AntdForm.Item name={[field.name, 'quantityCTN']} label="Quantity (CTN)">
                            <InputNumber
                              disabled={true}
                              formatter={value => new Intl.NumberFormat('en-US').format(value)}
                              parser={value => value.replace(/\\s?|(,*)/g, '')}
                            />
                          </AntdForm.Item>
                        </Col>
                        <Col span={12}>
                          <AntdForm.Item name={[field.name, 'confirmCTN']} label="Confirm Quantity (CTN)" rules={[{ required: true, message: '*required' }]}>
                            <InputNumber
                              formatter={value => new Intl.NumberFormat('en-US').format(value)}
                              parser={value => value.replace(/\\s?|(,*)/g, '')}
                            />
                          </AntdForm.Item>
                        </Col>
                      </Row>
                      <Row gutter={[32, 0]}>
                        <Col span={12}>
                          <AntdForm.Item name={[field.name, 'prodDate']} label="Production Date" rules={[{ required: true, message: '*required' }]}>
                            <DatePicker className="date-picker-input" />
                          </AntdForm.Item>
                        </Col>
                        <Col span={12}>
                          <AntdForm.Item name={[field.name, 'bbd']} label="Best Before Date" rules={[{ required: true, message: '*required' }]}>
                            <DatePicker className="date-picker-input" />
                          </AntdForm.Item>
                        </Col>
                      </Row>
                      <Row gutter={[32, 0]}>
                        <Col span={12}>
                          <AntdForm.Item name={[field.name, 'batchNo']} label="Batch No." rules={[{ required: true, message: '*required' }]}>
                            <Input.TextArea autoSize={{ minRows: 5 }} />
                          </AntdForm.Item>
                        </Col>
                        <Col span={12}>
                          <AntdForm.Item name={[field.name, 'weightPerCTN']} label="Wt.(KG)/CTN" rules={[{ required: true, message: '*required' }]}>
                            <InputNumber
                              formatter={value => new Intl.NumberFormat('en-US').format(value)}
                              parser={value => value.replace(/\\s?|(,*)/g, '')}
                            />
                          </AntdForm.Item>
                        </Col>
                      </Row>
                      <Row gutter={[32, 0]}>
                        <Col span={12}>
                          <AntdForm.Item name={[field.name, 'packagingWidth']} label="Packaging Width (CM)" rules={[{ required: true, message: '*required' }]}>
                            <InputNumber
                              formatter={value => new Intl.NumberFormat('en-US').format(value)}
                              parser={value => value.replace(/\\s?|(,*)/g, '')}
                            />
                          </AntdForm.Item>
                        </Col>
                        <Col span={12}>
                          <AntdForm.Item name={[field.name, 'packagingHeight']} label="Packaging Height (CM)" rules={[{ required: true, message: '*required' }]}>
                            <InputNumber
                              formatter={value => new Intl.NumberFormat('en-US').format(value)}
                              parser={value => value.replace(/\\s?|(,*)/g, '')}
                            />
                          </AntdForm.Item>
                        </Col>
                      </Row>
                      <Row gutter={[32, 0]}>
                        <Col span={12}>
                          <AntdForm.Item name={[field.name, 'packagingLength']} label="Packaging Length (CM)" rules={[{ required: true, message: '*required' }]}>
                            <InputNumber
                              formatter={value => new Intl.NumberFormat('en-US').format(value)}
                              parser={value => value.replace(/\\s?|(,*)/g, '')}
                            />
                          </AntdForm.Item>
                        </Col>
                        <Col span={12}></Col>
                      </Row>
                    </Col>
                    <Col span={3}></Col>
                  </Row>
                  <Divider style={{ margin: '16px 0', borderTop: 'none' }} />
                </div>
              ))}
            </>
          )}
        </AntdForm.List>
        <Row justify="center" gutter={[8, 0]}>
          <Button
            type="secondary"
            size="large"
            style={{ margin: '20px 10px 0', width: '300px' }}
            loading={loading}
            onClick={() => {
              const data = confirmForm.getFieldsValue()
              handleSave(data)
            }}
          >Save Only</Button>
          {(['Booking Confirmed', 'Warning'].indexOf(productStatus) !== -1) && (
            <AntdForm.Item>
              <Button
                type="text"
                size="large"
                style={{ margin: '20px 10px 0', width: '300px', backgroundColor: '#67C23A', color: '#FFF' }}
                loading={loading}
                onClick={() => {
                  confirmForm.submit()
                }}
              >Loaded</Button>
            </AntdForm.Item>
          )}
          <Button style={{ margin: '20px 10px 0', backgroundColor: '#B4B4B4', color: '#FFF' }} type="text" size="large" icon={<FontAwesomeIcon icon={faArrowLeft} className="fa-history-icon" />} onClick={() => navigate(-1)}></Button>
        </Row>
      </AntdForm>
    </Card>
  )
}

export default ConfirmProduct

import React from 'react'
import { useParams } from 'react-router-dom'
import { useAsync } from 'react-use'

import { Card, Table, Row, Col } from 'antd'

import dayjs from 'dayjs'

import { useAuth } from '../../contexts/AuthContextProvider'
import DocumentPdf from './Components/DocumentPdf'

const List = () => {
  const { piId } = useParams()
  const { db } = useAuth()

  const { value } = useAsync(async () => {
    const snap = await db.collection('piHistory').where('piId', '==', piId).orderBy('updatedAt', 'desc').get()
    return snap.docs.map(doc => {
      return {
        ...doc.data(),
        id: doc.id
      }
    })
  }, [])

  const columns = [
    {
      title: 'Revision',
      dataIndex: 'rev',
      width: 100
    },
    {
      title: 'Create Date',
      dataIndex: 'updatedAt',
      width: 200,
      render: text => (
        <>
          {(text && text.seconds) ? dayjs(text.seconds * 1000).format('DD/MM/YYYY') : null}
        </>
      )
    },
    {
      title: 'Create By',
      dataIndex: 'createdBy',
      width: 300
    },
    {
      title: 'Operation',
      dataIndex: 'operation',
      width: 150,
      render: (_, row) =>
        <div style={{ textAlign: 'center' }}>
          <DocumentPdf record={row}></DocumentPdf>
        </div>
    }
  ]

  return (
    <Card
      title='Revision'
      headStyle={{ fontWeight: 'bold', fontSize: '20px', lineHeight: '23px', backgroundColor: '#CFE2FF', color: '#154D9F' }}
      bodyStyle={{ boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)', borderRadius: '0 0 10px 10px' }}
    >
      <Row>
        <Col span={3}></Col>
        <Col span={18}>
          <Row gutter={[0, 24]}>
            <div className='content-title'>Document Revision</div>
          </Row>
          <Row gutter={[0, 24]}>
            <Table
              bordered
              rowKey='id'
              style={{ overflowX: 'auto' }}
              columns={columns}
              dataSource={value}
            />
          </Row>
        </Col>
        <Col span={3}></Col>
      </Row>
    </Card>
  )
}

export default List

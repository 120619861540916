import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import { useAsync } from 'react-use'

import { Button, Image, Select, Layout } from 'antd'

import { useAuth } from '../../contexts/AuthContextProvider'
import Sidebar from './Sidebar'
import Logo from '../../assets/images/logo.jpg'

const AdminLayout = () => {
  const { db, auth, user, role, updateCompanySelected } = useAuth()

  const [companySelected, setCompanySelected] = useState({})
  const [companyList, setCompanyList] = useState([])
  const [image, setImage] = useState()

  let roleName = ''
  switch (user?.userInfo?.role ?? '') {
    case 'Planner':
      roleName = 'Planner & Production'
      break
    case 'Purchasing':
      roleName = 'Sales Management'
      break
    default:
      roleName = user?.userInfo?.role ?? ''
  }

  useAsync(async () => {
    const comList = user?.userInfo?.companies ?? []
    const snap = await db.collection('company').orderBy('date', 'asc').get()
    const data = snap.docs.map(doc => ({
      ...doc.data(),
      id: doc.id,
      url: doc.data()?.logo?.length && doc.data()?.logo[0]?.url
    }))
    const list = (role === 'Admin') ? data : data.filter((o) => ((comList.length === 0) || (comList.indexOf(o.id) !== -1)))
    setCompanyList(list)
    const selected = list[0]
    updateCompanySelected(selected)
    setCompanySelected(selected)
    const img = (!selected?.url) ? Logo : selected.url
    setImage(img)
  })

  const handleLogout = async () => {
    try {
      await auth().signOut()
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const handleChange = (v) => {
    const selected = companyList.find((o) => o.id === v)
    updateCompanySelected(selected)
    setCompanySelected(selected)
    const img = (!selected?.url) ? Logo : selected.url
    setImage(img)
  }

  return (
    <Layout>
      <Layout.Sider
        width={210}
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
          backgroundColor: '#154D9F'
        }}>
        <Sidebar />
        <div style={{ position: 'absolute', bottom: 0, padding: 20 }}>
          <div style={{ paddingBottom: 24 }}>
            <Select
              placeholder="Select Company"
              style={{ width: 170 }}
              value={companySelected?.id ?? ''}
              onChange={(v) => handleChange(v)}
              options={(companyList).map((o) => ({
                label: o.name,
                value: o.id
              }))}
            />
          </div>
          <Image src={image} preview={false} ></Image>
          <div style={{ color: 'white', fontWeight: 'bold' }}>Name: {user?.userInfo?.userName ?? ''}</div>
          <div style={{ color: 'white', fontWeight: 'bold' }}>Role: {roleName}</div>
          <div style={{ textAlign: 'center', marginTop: 20 }}>
            <Button style={{ backgroundColor: 'red', color: 'white', borderRadius: '20px', border: 0, width: 100 }} onClick={handleLogout}>Sign Out</Button>
          </div>
        </div>
      </Layout.Sider>
      <Layout style={{ marginLeft: 210 }}>
        <Layout.Content style={{ padding: 8, minHeight: '100vh' }}>
          <Outlet />
        </Layout.Content>
      </Layout>
    </Layout>
  )
}

export default AdminLayout

import React, { useState } from 'react'

import { Button, Form, Input, message } from 'antd'

import { useAuth } from '../../contexts/AuthContextProvider'

const LoginPage = () => {
  const { auth } = useAuth()

  const [loading, setLoading] = useState(false)
  const [sended, setSended] = useState(false)

  const handleChange = async ({
    email
  }) => {
    // ยิง API
    try {
      setLoading(true)
      await auth().sendPasswordResetEmail(email)
      message.success('Successfully')
      setSended(true)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      message.error(error.message);
    }
  }

  const RenderFunc = () => {
    if (!sended) {
      return (
        <Form layout="vertical" onFinish={handleChange}>
          <Form.Item name="email" label="Email" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: '100%' }} loading={loading}>
              Reset Password
            </Button>
          </Form.Item>
        </Form>
      )
    } else {
      return (
        <p>An email has been sent. Please check your inbox</p>
      )
    }
  }
  return (
    <div>
      <h2 style={{ textAlign: 'center', marginBottom: 10, marginTop: 10 }}>Forgot Password</h2>
      <RenderFunc></RenderFunc>
    </div>
  )
}

export default LoginPage

import React, { useEffect, useRef, useState } from 'react'

import { Form as AntdForm, Button, Input, InputNumber, Row, Col, Select, Modal } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import { dayjsToDB } from '../../../utils/tools'

const useResetFormOnCloseModal = ({ data, productForm, visible }) => {
  const prevVisibleRef = useRef()
  const prevDataRef = useRef()

  useEffect(() => {
    prevVisibleRef.current = visible
    prevDataRef.current = data
  }, [visible, data])

  const prevVisible = prevVisibleRef.current
  const prevData = prevDataRef.current

  useEffect(() => {
    if ((data || (!data && !prevData)) && !visible && prevVisible) {
      productForm.resetFields()
    } else {
      productForm.setFieldsValue(data)
    }
  }, [visible, data, prevData, prevVisible, productForm])
}

const ProductModal = ({ data, visible, productList, onCancel }) => {
  const [title, setTitle] = useState()
  const products = (productList || []).map((o) => ({
    ...o,
    weight: parseFloat(o.weight),
    bagPerCarton: parseFloat(o.bagPerCarton),
    price: parseFloat(o.price),
    quantityCTN: '',
    quantityBAG: ''
  }))

  const [productForm] = AntdForm.useForm()

  useResetFormOnCloseModal({
    data,
    productForm,
    visible,
  })

  const handleSubmit = () => {
    const product = productForm.getFieldsValue()
    productForm.setFieldsValue({
      ...product,
      prodDate: dayjsToDB(product.prodDate),
      bbd: dayjsToDB(product.bbd)
    })
    productForm.submit()
  }

  const onItemCodeChange = (value) => {
    const product = products.find((o) => o.itemCode === value)
    if (!product) {
      return
    }
    productForm.setFieldsValue(product)
    setTitle(product.productRef)
  }

  const onProductNameChange = (value) => {
    const product = products.find((o) => o.productRef === value)
    if (!product) {
      return
    }
    productForm.setFieldsValue(product)
    setTitle(product.productRef)
  }

  const onQuantityBAGChange = () => {
    const product = productForm.getFieldsValue()
    productForm.setFieldsValue({
      ...product,
      quantityCTN: (product.quantityBAG && product.bagPerCarton) ? (parseFloat(product.quantityBAG) / parseFloat(product.bagPerCarton)) : '',
      amount: (product.quantityBAG && product.price) ? parseFloat(product.quantityBAG) * parseFloat(product.price) : ''
    })
  }

  return (
    <Modal
      forceRender
      open={visible}
      title={
        <div style={{ padding: '16px 24px', fontWeight: 'bold', fontSize: '20px', lineHeight: '23px', backgroundColor: '#CFE2FF', color: '#154D9F', borderRadius: '10px 10px 0px 0px' }}>
          {(title) ? title : (data && data.productRef) ? data.productRef : 'Add Product'}
        </div>
      }
      width={'75%'}
      closable={false}
      maskClosable={false}
      footer={
        <Row justify="center" gutter={[8, 0]}>
          {(data) ? (
            <>
              <Col span={8}>
                <Button type="primary" size="large" htmlType="submit" block onClick={handleSubmit}>Save</Button>
              </Col>
            </>
          ) : (
            <>
              <Col span={8}>
                <Button type="primary" size="large" htmlType="submit" block onClick={handleSubmit} style={{ borderRadius: '5px' }}>Add</Button>
              </Col>
              <Col span={1}>
                <Button type="danger" size="large" icon={<FontAwesomeIcon icon={faTimes} />} onClick={onCancel} style={{ borderRadius: '5px' }}></Button>
              </Col>
            </>
          )}
        </Row>
      }
    >
      <AntdForm form={productForm} name="productForm" layout="vertical" initialValues={{}}>
        <AntdForm.Item name="id" hidden={true}>
          <Input />
        </AntdForm.Item>
        <AntdForm.Item name="hsCode" hidden={true} initialValue="">
          <Input />
        </AntdForm.Item>
        <AntdForm.Item name="marks" hidden={true} initialValue="">
          <Input />
        </AntdForm.Item>
        <AntdForm.Item name="productNameEN" hidden={true} initialValue="">
          <Input />
        </AntdForm.Item>
        <AntdForm.Item name="confirmBAG" hidden={true} initialValue="">
          <Input />
        </AntdForm.Item>
        <AntdForm.Item name="confirmCTN" hidden={true} initialValue="">
          <Input />
        </AntdForm.Item>
        <AntdForm.Item name="prodDate" hidden={true} initialValue="">
          <Input />
        </AntdForm.Item>
        <AntdForm.Item name="bbd" hidden={true} initialValue="">
          <Input />
        </AntdForm.Item>
        <AntdForm.Item name="batchNo" hidden={true} initialValue="">
          <Input />
        </AntdForm.Item>
        <AntdForm.Item name="weightPerCTN" hidden={true} initialValue="">
          <Input />
        </AntdForm.Item>
        <AntdForm.Item name="packagingWidth" hidden={true} initialValue="">
          <Input />
        </AntdForm.Item>
        <AntdForm.Item name="packagingHeight" hidden={true} initialValue="">
          <Input />
        </AntdForm.Item>
        <AntdForm.Item name="packagingLength" hidden={true} initialValue="">
          <Input />
        </AntdForm.Item>
        <Row>
          <Col span={3}></Col>
          <Col span={18}>
            <Row gutter={[32, 0]}>
              <Col span={12}>
                <AntdForm.Item name="productRef" label="Product Name" initialValue="" rules={[{ required: true, message: 'Please select Product Name!' }]}>
                  <Select
                    showSearch
                    placeholder="Select Product Name"
                    optionFilterProp="value"
                    onChange={onProductNameChange}
                    filterOption={(input, option) => {
                      return (option.value || '').toLowerCase().indexOf(input.toLowerCase()) !== -1
                    }}>
                    {((products).map((o) => (
                      <Select.Option key={o.id} value={o.productRef}>{o.productRef}</Select.Option>
                    ))
                    )}
                  </Select>
                </AntdForm.Item>
              </Col>
              <Col span={12}>
                <AntdForm.Item name="itemCode" label="Item Code" initialValue="" rules={[{ required: true, message: 'Please select Item Code!' }]}>
                  <Select
                    showSearch
                    placeholder="Select Item Code"
                    optionFilterProp="value"
                    onChange={onItemCodeChange}
                    filterOption={(input, option) => {
                      return (option.value || '').toLowerCase().indexOf(input.toLowerCase()) !== -1
                    }}>
                    {((products).map((o) => (
                      <Select.Option key={o.id} value={o.itemCode}>{o.itemCode}</Select.Option>
                    ))
                    )}
                  </Select>
                </AntdForm.Item>
              </Col>
            </Row>
            <Row gutter={[32, 0]}>
              <Col span={12}>
                <AntdForm.Item name="quantityBAG" label="Quantity (BAG)" initialValue="" rules={[{ required: true, message: 'Please input Quantity (BAG)' }]}>
                  <InputNumber
                    formatter={value => new Intl.NumberFormat('en-US').format(value)}
                    parser={value => value.replace(/\\s?|(,*)/g, '')}
                    onChange={onQuantityBAGChange}
                    style={{ width: '100%', height: '40px', borderRadius: '8px' }}
                  />
                </AntdForm.Item>
              </Col>
              <Col span={12}>
                <AntdForm.Item name="quantityCTN" label="Quantity (CTN)" initialValue="" rules={[{ required: true, message: 'Please input Quantity (CTN)' }]}>
                  <InputNumber
                    precision={2}
                    formatter={value => new Intl.NumberFormat('en-US').format(value)}
                    parser={value => value.replace(/\\s?|(,*)/g, '')}
                    style={{ width: '100%', height: '40px', borderRadius: '8px' }}
                  />
                </AntdForm.Item>
              </Col>
            </Row>
            <Row gutter={[32, 0]}>
              <Col span={12}>
                <AntdForm.Item name="weight" label="Weight per Bag (Gram)" initialValue="">
                  <InputNumber
                    formatter={value => new Intl.NumberFormat('en-US').format(value)}
                    parser={value => value.replace(/\\s?|(,*)/g, '')}
                    style={{ width: '100%', height: '40px', borderRadius: '8px' }}
                  />
                </AntdForm.Item>
              </Col>
              <Col span={12}>
                <AntdForm.Item name="bagPerCarton" label="Bag per Carton" initialValue="">
                  <InputNumber
                    formatter={value => new Intl.NumberFormat('en-US').format(value)}
                    parser={value => value.replace(/\\s?|(,*)/g, '')}
                    style={{ width: '100%', height: '40px', borderRadius: '8px' }}
                  />
                </AntdForm.Item>
              </Col>
            </Row>
            <Row gutter={[32, 0]}>
              <Col span={12}>
                <AntdForm.Item name="price" label="Price Each (USD)" initialValue="" rules={[{ required: true, message: 'Please input Price Each (USD)' }]}>
                  <InputNumber
                    formatter={value => new Intl.NumberFormat('en-US').format(value)}
                    parser={value => value.replace(/\\s?|(,*)/g, '')}
                    style={{ width: '100%', height: '40px', borderRadius: '8px' }}
                  />
                </AntdForm.Item>
              </Col>
              <Col span={12}>
                <AntdForm.Item name="amount" label="Amount (USD)" initialValue="">
                  <InputNumber
                    precision={2}
                    formatter={value => new Intl.NumberFormat('en-US').format(value)}
                    parser={value => value.replace(/\\s?|(,*)/g, '')}
                    style={{ width: '100%', height: '40px', borderRadius: '8px' }}
                  />
                </AntdForm.Item>
              </Col>
            </Row>
          </Col>
          <Col span={3}></Col>
        </Row>
      </AntdForm>
    </Modal>
  )
}

export default ProductModal

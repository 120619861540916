import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useAsync } from 'react-use'

import { Button, Card, Table, Form, Row, Col } from 'antd'
import { FormOutlined, PlusOutlined } from '@ant-design/icons';

import { useAuth } from '../../../contexts/AuthContextProvider';

const List = () => {
   const navigate = useNavigate()
   const { db } = useAuth()
   const columns = [
      {
         title: 'Type',
         dataIndex: 'type',
         width: 400,
         sorter: (a, b) => a.type - b.type
      },
      {
         title: 'Status',
         dataIndex: 'status',
         width: 300,
         render: (data, row) =>
            <div style={{ color: data === 'Active' ? 'green' : 'red' }}>
               {data}
            </div>
      },
      {
         title: 'Operation',
         dataIndex: 'operation',
         width: 300,
         render: (data, row) =>
            <div style={{ textAlign: 'center' }}>
               <Button style={{ backgroundColor: '#F6C001', color: '#FFFFFF', width: '32px', height: '30px', borderRadius: '4px' }} size="small" onClick={() => handleEdit(row)}><FormOutlined /></Button>
            </div>
      }
   ]
   const { value } = useAsync(async () => {
      const snap = await db.collection('loadingType').get()
      return snap.docs.map(doc => {
         return {
            ...doc.data(),
            id: doc.id
         }
      })
   })
   const handleCreate = () => {
      navigate('/admin/master-data/loadingType/create')
   }
   const handleEdit = (row) => {
      if (row && row.id) return navigate(`/admin/master-data/loadingType/view/${row.id}`)
   }

   return (
      <Card
         title="Loading Type"
         // style={{ margin: '1em', borderRadius: '15px', boxShadow: '0px 2px 12px rgba(0,0,0,0.1)', border: 0 }}
         // headStyle={{ backgroundColor: '#CFE2FF', color: '#154D9F', fontWeight: 'bold', borderRadius: '10px 10px 0px 0px' }}
         headStyle={{ fontWeight: 'bold', fontSize: '20px', lineHeight: '23px', backgroundColor: '#CFE2FF', color: '#154D9F' }}
         bodyStyle={{ boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)', borderRadius: '0 0 10px 10px' }}
      >
         <Form>
            <Row style={{ textAlign: 'right' }}>
               <Col span={12} style={{ textAlign: 'right' }}>
               </Col>
               <Col span={12}>
                  <Form.Item>
                     <Button htmlType="submit" onClick={handleCreate} style={{ borderRadius: '8px', marginLeft: '20px', backgroundColor: '#154D9F', color: 'white' }}><PlusOutlined />Create Loading Type</Button>
                  </Form.Item>
               </Col>
            </Row>
         </Form>
         <Table
            bordered
            rowKey="id"
            style={{ overflowX: 'auto' }}
            columns={columns}
            dataSource={value}
            sortDirections={['ascend', 'descend', 'ascend']}
         />
      </Card>
   )
}

export default List

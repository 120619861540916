import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAsync } from 'react-use'

import { Form as AntdForm, Card, Col, Row, Input, Button, Skeleton, Select } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'

// import _ from 'lodash'

import { useAuth } from '../../../contexts/AuthContextProvider'

const Form = () => {
  const navigate = useNavigate()
  const { db } = useAuth()
  const { loadingTypeId } = useParams()
  const [btnLoading, setBtnLoading] = useState(false)
  const { value, loading } = useAsync(async () => {
    if (!loadingTypeId) {
      return {
        answer: [
          {
            type: ''
          }
        ]
      }
    }
    const ref = await db.collection('loadingType').doc(loadingTypeId).get()
    return {
      ...ref.data(),
      id: ref.id,
      answer: [
        {
          type: ref.type,
          status: ref.status
        }
      ]
    }
  }, [loadingTypeId])
  const mode = loadingTypeId ? 'Submit' : 'Create'
  const handleSubmit = async (formData) => {
    const batch = db.batch()
    try {
      setBtnLoading(true)
      if (loadingTypeId) {
        formData.answer.forEach((o) => {
          batch.set(db.collection('loadingType').doc(loadingTypeId), { ...o })
        })
      } else {
        formData.answer.forEach((o) => {
          batch.set(db.collection('loadingType').doc(), { ...o })
        })
      }
      await batch.commit()
      navigate('/admin/master-data/loadingType/list')
    } catch {
      setBtnLoading(false)
    }
  }
  if (loading) return <Skeleton></Skeleton>

  return (
    <AntdForm
      layout="vertical"
      onFinish={handleSubmit}
      initialValues={value}
    >
      <Card
        title="Loading Type"
        headStyle={{ fontWeight: 'bold', fontSize: '20px', lineHeight: '23px', backgroundColor: '#CFE2FF', color: '#154D9F' }}
        bodyStyle={{ boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)', borderRadius: '0 0 10px 10px' }}
      >
        <Row>
          <Col span={6}></Col>
          <Col span={14}>
            <AntdForm.List name="answer">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(field => (
                    <Row gutter={[32, 0]} key={field.key}>
                      <Col span={10}>
                        <AntdForm.Item
                          {...field}
                          name={[field.name, 'type']}
                          label="Answer"
                          fieldKey={[field.fieldKey, 'type']}
                          initialValue={value.type}
                          rules={[{ required: true, message: 'Answer cannot be blank' }]}
                        >
                          <Input />
                        </AntdForm.Item>
                      </Col>
                      <Col span={10}>
                        <AntdForm.Item
                          label="Status"
                          fieldKey={[field.fieldKey, 'status']}
                          name={[field.name, 'status']}
                          initialValue={mode === 'Create' ? 'Active' : value.status}
                        >
                          <Select >
                            <Select.Option value="Active">Active</Select.Option>
                            {loadingTypeId &&
                              <Select.Option value="InActive">InActive</Select.Option>
                            }
                          </Select>
                        </AntdForm.Item>
                      </Col>
                      {mode === 'Create' &&
                        <Col span={4}>
                          <Button style={{ marginTop: '40px', backgroundColor: '#EA3939', color: '#FFF', width: '42px', height: '40px', borderRadius: '4px' }} onClick={() => remove(field.name)} icon={<FontAwesomeIcon icon={faTrashAlt} />}></Button>
                        </Col>
                      }
                    </Row>
                  ))}
                  {mode === 'Create' &&
                    <Row justify="center" style={{ marginTop: '20px' }}>
                      <AntdForm.Item>
                        <Button style={{ marginRight: '120px', width: '150px', borderRadius: '8px' }} disabled={mode === 'Submit'} type="primary" onClick={() => add()}><PlusOutlined />Add Answer</Button>
                      </AntdForm.Item>
                    </Row>
                  }
                </>
              )}
            </AntdForm.List>
            <Row justify="center" style={{ marginTop: '20px' }}>
              <Button style={{ marginRight: '120px', height: '40px', width: '300px', borderRadius: '8px' }} type="primary" loading={btnLoading} htmlType="submit">{mode}</Button>
            </Row>
          </Col>
          <Col span={4}></Col>
        </Row>
      </Card>
    </AntdForm>
  )
}

export default Form

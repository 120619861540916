import React, { useState } from 'react'
// import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'

import { Button, Form, Input, message } from 'antd'

import { useAuth } from '../../contexts/AuthContextProvider'

const LoginPage = () => {
  const { auth } = useAuth()
  // const navigate = useNavigate()

  const [loading, setLoading] = useState(false)

  const handleChange = async ({
    email,
    password
  }) => {
    // ยิง API
    try {
      setLoading(true)
      await auth().signInWithEmailAndPassword(email, password)
      // console.log('credential', credential)
      // if (credential) navigate('/admin')
      setLoading(false)
    } catch (error) {
      setLoading(false)
      message.error(error.message);
    }
  }
  return (
    <Form layout="vertical" onFinish={handleChange}>
      <Form.Item name="email" label="Email" rules={[{ required: true }]}>
        <Input className="input-auth" />
      </Form.Item>
      <Form.Item name="password" label="Password" rules={[{ required: true }, { min: 6 }]}>
        <Input.Password className="input-auth" />
      </Form.Item>
      <Form.Item>
        <Button type="primary" className="input-auth" htmlType="submit" style={{ width: '100%' }} loading={loading}>
          Login
        </Button>
      </Form.Item>
      <Form.Item>
        <div style={{ textAlign: 'center' }}>
          <Link to="/forget-password" style={{ textDecoration: 'underline' }}> For got password</Link>
        </div>
      </Form.Item>
    </Form>
  )
}

export default LoginPage

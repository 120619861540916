import React from 'react'
import { BrowserRouter } from 'react-router-dom'

import { ConfigProvider } from 'antd'

import AuthContextProvider from './contexts/AuthContextProvider'

import './App.less'

import Router from './route'
const App = () => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#004ca5',
        },
        components: {
          Menu: {
            itemColor: '#D2D2D2',
            itemHoverColor: '#D2D2D2',
            itemSelectedColor: '#D2D2D2',
          },
        },
      }}
    >
      <BrowserRouter>
        <AuthContextProvider>
          <Router></Router>
        </AuthContextProvider>
      </BrowserRouter>
    </ConfigProvider>
  )
}

export default App
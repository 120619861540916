import React, { useState } from 'react'
import { useAsync } from 'react-use'

import { Form as AntdForm, Button, Row, Col, Modal, Input, Upload } from 'antd'
import { InboxOutlined } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import { DatePicker } from '../../../components/DateTime'

import { useAuth } from '../../../contexts/AuthContextProvider'
import { dbTodayjs, dayjsToDB } from '../../../utils/tools'

const { Dragger } = Upload

const StatusModal = ({ piId, visible, onCancel, onUpdate }) => {
  const { storage, db, functions, user } = useAuth()

  const [files, setFiles] = useState([])
  const [status, setStatus] = useState('')
  const [loading, setLoading] = useState(false)
  const [count, setCount] = useState(0)

  const [piForm] = AntdForm.useForm()

  const ref = db.collection('pi')

  useAsync(async () => {
    const snap = await ref.doc(piId).get()
    const data = snap.data()
    setStatus(data.status)
    setFiles(data.files ?? [])
    piForm.setFieldsValue({
      transDate: dbTodayjs(data.transDate),
      approvedBy: data?.approvedBy || (user?.userInfo?.userName ?? 'Admin'),
      statusRemark: data?.statusRemark ?? '',
      files: data.files
    })
  }, [piId, count])

  const handleSubmit = async () => {
    try {
      setLoading(true)
      await piForm.validateFields()
      const formData = piForm.getFieldsValue()
      const approvePI = functions.httpsCallable('piHttp-approvePI')
      await approvePI({
        id: piId,
        transDate: dayjsToDB(formData.transDate),
        approvedBy: formData?.approvedBy ?? 'Admin',
        statusRemark: formData?.statusRemark ?? '',
        files: files,
        status: status
      })
      setCount(count + 1)
      onUpdate()
      onCancel()
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  let tempFiles = []
  const beforeUpload = async (file, fileList) => {
    try {
      const storageRef = storage.ref('pi').child(`${file.uid}-${file.name}`)
      const task = await storageRef.put(file, {
        contentType: file.type
      })
      const data = {
        uid: file.uid,
        name: file.name,
        type: file.type,
        lastModified: file.lastModified,
        url: `https://firebasestorage.googleapis.com/v0/b/${task.metadata.bucket}/o/${encodeURIComponent(task.metadata.fullPath)}?alt=media`
      }
      tempFiles = [...tempFiles, data]
      setFiles([...files, ...tempFiles])
      return Promise.resolve(file)
    } catch (e) {
      return false
    }
  }

  const onRemoveFile = async (file) => {
    try {
      const filterFiles = files.filter((o) => o.uid !== file.uid)
      const storageRef = storage.ref('pi').child(`${file.uid}-${file.name}`)
      await storageRef.delete()
      setFiles([...filterFiles])
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  return (
    <Modal
      forceRender
      open={visible}
      title={
        <div style={{ padding: '16px 24px', fontWeight: 'bold', fontSize: '20px', lineHeight: '23px', backgroundColor: '#CFE2FF', color: '#154D9F', borderRadius: '10px 10px 0px 0px' }}>
          Payment Information
        </div>
      }
      width={'75%'}
      closable={false}
      maskClosable={false}
      footer={
        <Row justify="center" gutter={[8, 0]}>
          <Col span={8}>
            <Button type="primary" size="large" htmlType="submit" block onClick={() => handleSubmit()} style={{ borderRadius: '5px', backgroundColor: '#67C23A' }} loading={loading}>Mark As Complete</Button>
          </Col>
          {/* {(['deposit', 'balance'].indexOf(status) !== -1) && (
            <Col span={8}>
              <Button type="primary" size="large" htmlType="submit" block onClick={() => handleSubmit()} style={{ borderRadius: '5px', backgroundColor: '#67C23A' }} loading={loading}>Mark As Complete</Button>
            </Col>
          )} */}
          <Col span={1}>
            <Button type="danger" size="large" icon={<FontAwesomeIcon icon={faTimes} />} onClick={onCancel} style={{ borderRadius: '5px' }}></Button>
          </Col>
        </Row>
      }
    >
      <AntdForm form={piForm} name="piForm" layout="vertical" initialValues={{}}>
        <Row gutter={[32, 0]}>
          <Col span={12}>
            <AntdForm.Item name="transDate" label="Paid Date" rules={[{ required: true, message: 'Please input Paid Date!' }]}>
              <DatePicker className="date-picker-input" disabled={false} />
            </AntdForm.Item>
          </Col>
          <Col span={12}>
            <AntdForm.Item name="approvedBy" label="Recorded By">
              <Input disabled={false} />
            </AntdForm.Item>
          </Col>
        </Row>
        <Row gutter={[32, 0]}>
          <Col span={24}>
            <AntdForm.Item name="statusRemark" label="Remark">
              <Input.TextArea autoSize={true} disabled={false} />
            </AntdForm.Item>
          </Col>
        </Row>
        <AntdForm.Item>
          <Dragger
            name='files'
            action=""
            accept='.webp, .png, .jpg, .jpeg, .pdf'
            fileList={files}
            multiple={true}
            className="upload-list-inline"
            beforeUpload={beforeUpload}
            onRemove={onRemoveFile}
            disabled={false}
            progress={{
              strokeColor: {
                '0%': '#108ee9',
                '100%': '#87d068',
              },
              strokeWidth: 3,
              format: percent => `${parseFloat(percent.toFixed(2))}%`
            }}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload. Strictly prohibit from uploading company data or other
              band files
            </p>
          </Dragger>
        </AntdForm.Item>
      </AntdForm>
    </Modal>
  )
}

export default StatusModal

import React, { useEffect, useState } from 'react'

import { Button, Row, Col, Modal, Popover, Card, Form as AntdForm, Space, Select, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faInfoCircle } from '@fortawesome/free-solid-svg-icons'

import _ from 'lodash'
import axios from 'axios'
import dayjs from 'dayjs'
import pdfMake from 'pdfmake/build/pdfmake'
import { mkConfig, generateCsv, download } from 'export-to-csv'

import { DatePicker } from '../../../components/DateTime'

import { useAuth } from '../../../contexts/AuthContextProvider'
import config from '../../../config'
import './ExportListPage.less'

import pdfFonts from '../../../assets/pdfMakeFonts/vfs_fonts'
pdfMake.vfs = pdfFonts.pdfMake.vfs
pdfMake.fonts = {
  THSarabunNew: {
    normal: 'THSarabunNew.ttf',
    bold: 'THSarabunNew-Bold.ttf',
    italics: 'THSarabunNew-Italic.ttf',
    bolditalics: 'THSarabunNew-BoldItalic.ttf'
  },
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-MediumItalic.ttf'
  }
}

const StatusConfirmModal = () => {
  const [btnLoading, setBtnLoading] = useState(false)
  const [isModalFinancialVisible, setIsModalFinancialVisible] = useState(false)
  const [isModalSaleOrderVisible, setIsModalSaleOrderVisible] = useState(false)
  const [dataProducts, setDataProducts] = useState(null)
  const [dateFinancial, setDateFinancial] = useState(null)
  const [dataProductName, setDataProductName] = useState(null)
  const [dataItemCode, setDataItemCode] = useState(null)
  const { db, role, companyId } = useAuth()
  const [formSaleOrder] = AntdForm.useForm()
  useEffect(() => {
  }, [formSaleOrder])
  const roleFilter = (role !== 'Planner') ? 'purchasing' : 'planner'
  const statusText = (roleFilter, status) => {
    return (roleFilter === 'purchasing') ? status : (['Waiting for Planner'].indexOf(status) !== -1) ? 'New' : (['Booking Request', 'Waiting for Booking Confirm', 'Booking Confirmed', 'Warning', 'Problem'].indexOf(status) !== -1) ? 'Processing' : status
  }
  const exportTable = async () => {
    const csvConfig = mkConfig({
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename: `Purchase Order List_${dayjs().format('YYYY-MM-DD')}`
    })
    try {
      setBtnLoading(true)
      const result = await axios({
        method: 'post',
        url: `${config.apiPath}/poApi-poList`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          role: role
        }
      })
      const data = result.data.data.map((o) => {
        return (roleFilter === 'purchasing') ? {
          'Status': statusText(roleFilter, o.status),
          'INV No.': o.invNo || '',
          'PO No.': o.poNo || '',
          'Item': o.productRef || '',
          'Quantity (BAG)': o.quantityBAG || '',
          'Quantity (CTN)': o.quantityCTN || '',
          'Country': o.country || '',
          'Port': o.port || '',
          'HBL': o.hbl || '',
          'BL Status': o.blStatus || '',
          'CY Date': (o.cyDate) ? dayjs(o.cyDate._seconds * 1000).format('YYYY/MM/DD') : '',
          'RTN Date': (o.rtnDate) ? dayjs(o.rtnDate._seconds * 1000).format('YYYY/MM/DD') : '',
          'ETD': (o.etd) ? dayjs(o.etd._seconds * 1000).format('YYYY/MM/DD') : '',
          'ETA': (o.eta) ? dayjs(o.eta._seconds * 1000).format('YYYY/MM/DD') : ''
        } : {
          'Status': statusText(roleFilter, o.status),
          'INV No.': o.invNo || '',
          'PO No.': o.poNo || '',
          'Item': o.productRef || '',
          'Quantity (CTN)': o.quantityCTN || '',
          'Country': o.country || '',
          'Port': o.port || '',
          'Weight per Bag (Gram)': o.weight || '',
          'Bag per Carton': o.bagPerCarton || '',
          'Loading Type': o.loadingType || '',
          'CY Date': (o.cyDate) ? dayjs(o.cyDate._seconds * 1000).format('YYYY/MM/DD') : '',
          'RTN Date': (o.rtnDate) ? dayjs(o.rtnDate._seconds * 1000).format('YYYY/MM/DD') : '',
          'Closing Date': (o.closingDate) ? dayjs(o.closingDate._seconds * 1000).format('YYYY/MM/DD') : '',
          'Closing Time': (o.closingTime) ? dayjs(o.closingTime._seconds * 1000).format('HH:mm') : '',
          'ETD': (o.etd) ? dayjs(o.etd._seconds * 1000).format('YYYY/MM/DD') : '',
          'ETA': (o.eta) ? dayjs(o.eta._seconds * 1000).format('YYYY/MM/DD') : ''
        }
      })
      if (data.length) {
        const csv = generateCsv(csvConfig)(data)
        download(csvConfig)(csv)
      }
      setBtnLoading(false)
      return Promise.resolve()
    } catch (error) {
      setBtnLoading(false)
      return Promise.reject(error)
    }
  }
  const exportFinancial = async () => {
    if (!dateFinancial) return
    const csvConfig = mkConfig({
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename: `Financial_${dayjs(dateFinancial).format('YYYY-MM')}`
    })
    try {
      setBtnLoading(true)
      const result = await axios({
        method: 'post',
        url: `${config.apiPath}/poApi-poFinancial`,
        // url: 'http://localhost:5001/craftfood-bcaac/asia-southeast2/poApi-poFinancial',
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          date: dateFinancial,
          role: role
        }
      })
      const newResult = [...result.data.data, {}]
      let totalAmount = 0
      let data = newResult.map((o, i) => {
        if (o.amount) totalAmount += o.amount
        return (roleFilter === 'purchasing') ? {
          'Status': i === newResult.length - 1 ? 'Total' : statusText(roleFilter, o.status),
          'Amount': i === newResult.length - 1 ? totalAmount : o.amount,
          'INV No.': o.invNo || '',
          'Item': o.productRef || '',
          'Quantity (BAG)': o.quantityBAG || '',
          'Quantity (CTN)': o.quantityCTN || '',
          'Country': o.country || '',
          'Port': o.port || '',
          'HBL': o.hbl || '',
          'BL Status': o.blStatus || '',
          'CY Date': (o.cyDate) ? dayjs(o.cyDate._seconds * 1000).format('YYYY/MM/DD') : '',
          'RTN Date': (o.rtnDate) ? dayjs(o.rtnDate._seconds * 1000).format('YYYY/MM/DD') : '',
          'ETD': (o.etd) ? dayjs(o.etd._seconds * 1000).format('YYYY/MM/DD') : '',
          'ETA': (o.eta) ? dayjs(o.eta._seconds * 1000).format('YYYY/MM/DD') : ''
        } : {
          'Status': i === newResult.length - 1 ? 'Total' : statusText(roleFilter, o.status),
          'Amount': i === newResult.length - 1 ? totalAmount : o.amount,
          'INV No.': o.invNo || '',
          'PO No.': o.poNo || '',
          'Item': o.productRef || '',
          'Quantity (CTN)': o.quantityCTN || '',
          'Country': o.country || '',
          'Port': o.port || '',
          'Weight per Bag (Gram)': o.weight || '',
          'Bag per Carton': o.bagPerCarton || '',
          'Loading Type': o.loadingType || '',
          'CY Date': (o.cyDate) ? dayjs(o.cyDate._seconds * 1000).format('YYYY/MM/DD') : '',
          'RTN Date': (o.rtnDate) ? dayjs(o.rtnDate._seconds * 1000).format('YYYY/MM/DD') : '',
          'Closing Date': (o.closingDate) ? dayjs(o.closingDate._seconds * 1000).format('YYYY/MM/DD') : '',
          'Closing Time': (o.closingTime) ? dayjs(o.closingTime._seconds * 1000).format('HH:mm') : '',
          'ETD': (o.etd) ? dayjs(o.etd._seconds * 1000).format('YYYY/MM/DD') : '',
          'ETA': (o.eta) ? dayjs(o.eta._seconds * 1000).format('YYYY/MM/DD') : ''
        }
      })
      if (data.length) {
        const csv = generateCsv(csvConfig)(data)
        download(csvConfig)(csv)
      }
      setBtnLoading(false)
      return Promise.resolve()
    } catch (error) {
      setBtnLoading(false)
      return Promise.reject(error)
    }
  }
  const formatPort = (data) => {
    const uniqP = _.uniqBy(data, 'port')
    const ports = uniqP.map(e => { return { port: e.port, country: e.country } })
    let arr = []
    ports.forEach(e => {
      const pfilter = data.filter(f => f.port === e.port)
      const totalOrder = _.sumBy(pfilter, (o) => { return o.quantityCTN })
      const shipped = pfilter.filter(a => a.status === 'Loaded')
      const orderShipped = _.sumBy(shipped, (o) => { return o.quantityCTN })
      const orderProcess = totalOrder - orderShipped
      arr = [...arr, {
        table: {
          width: [20, 80, 80, 80],
          headerRows: 1,
          body: [
            [{ text: `Destination: ${e.port}, ${e.country}`, colSpan: 4 }, '', '', ''],
            ['No', 'Details', 'Quantity', 'Unit'],
            ['1', 'Total Order', totalOrder, 'cartons'],
            ['2', 'Shipped Order', orderShipped, 'cartons'],
            ['3', 'On Processing', orderProcess, 'cartons']
          ],
        }
      }]
      arr = [...arr, '\n']
    })
    return arr

  }
  const formatTablePdf = (data) => {
    const result = data.map((e, i) => {
      return [i + 1, _.get(e, 'invNo', ''), _.get(e, 'poNo', ''), _.get(e, 'status', ''), _.get(e, 'itemCode', ''), _.get(e, 'country', ''), _.get(e, 'port', ''),
      _.get(e, 'productRef', ''), _.get(e, 'weight', ''), _.get(e, 'bagPerCarton', ''), _.get(e, 'quantityCTN', ''), _.get(e, 'quantityBAG', ''),
      _.get(e, 'deliveryDate', '') ? dayjs(_.get(e, 'deliveryDate', '')).format('DD-MM-YYYY') : '',
      _.get(e, 'rtnDate', '') ? dayjs(_.get(e, 'rtnDate', '')).format('DD-MM-YYYY') : '',
      _.get(e, 'etd', '') ? dayjs(_.get(e, 'etd', '')).format('DD-MM-YYYY') : '',
      _.get(e, 'eta', '') ? dayjs(_.get(e, 'eta', '')).format('DD-MM-YYYY') : '', _.get(e, 'remark', '')]
    })
    return result
  }
  const exportSaleOrder = async (formData) => {
    const csvConfig = mkConfig({
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename: `SaleOrder_${formData.productRef}`
    })
    try {
      setBtnLoading(true)
      const result = await axios({
        method: 'post',
        url: `${config.apiPath}/poApi-poSaleOrder`,
        // url: 'http://localhost:5001/craftfood-bcaac/asia-southeast2/poApi-poSaleOrder',
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          role: role,
          productRef: formData.productRef,
          itemCode: formData.itemCode
        }
      })
      const data = result.data.data.map((e, i) => {
        return {
          'No.': i + 1,
          'INV No.': _.get(e, 'invNo', ''),
          'PO No.': _.get(e, 'poNo', ''),
          'Status': _.get(e, 'status', ''),
          'Item no.': _.get(e, 'itemCode', ''),
          'Country': _.get(e, 'country', ''),
          'Port': _.get(e, 'port', ''),
          'Items': _.get(e, 'productRef', ''),
          'Weight per Bag(Gram)': _.get(e, 'weight', ''),
          'Bag per Carton': _.get(e, 'bagPerCarton', ''),
          'Quantity(Ctn)': _.get(e, 'quantityCTN', ''),
          'Quantity(Bag)': _.get(e, 'quantityBAG', ''),
          'Delivery date(In PO.)': _.get(e, 'deliveryDate', '') ? dayjs(_.get(e, 'deliveryDate', '')).format('DD-MM-YYYY') : '',
          'Loading Plang': _.get(e, 'rtnDate', '') ? dayjs(_.get(e, 'rtnDate', '')).format('DD-MM-YYYY') : '',
          'ETD': _.get(e, 'etd', '') ? dayjs(_.get(e, 'etd', '')).format('DD-MM-YYYY') : '',
          'ETA': _.get(e, 'eta', '') ? dayjs(_.get(e, 'eta', '')).format('DD-MM-YYYY') : '',
          'Remark': _.get(e, 'remark', '')
        }
      })
      if (data.length && formData.fileType === 'csv') {
        const csv = generateCsv(csvConfig)(data)
        download(csvConfig)(csv)
      } else if (formData.fileType === 'pdf') {
        formatTablePdf(result.data.data)
        const objExport = {
          pageOrientation: 'landscape',
          content: [
            {
              table: {
                headerRows: 1,
                body: [
                  ['No.', 'INV No.', 'PO No.', 'Status', 'Item no.', 'Country', 'Port', 'Items', 'Weight per Bag(Gram)', 'Bag per Carton', 'Quantity(Ctn)', 'Quantity(Bag)', 'Delivery date(In PO.)', 'Loading Plang', 'ETD', 'ETA', 'Remark'],
                  ...formatTablePdf(result.data.data)
                ],
              }
            }, '\n',
            ...formatPort(result.data.data)
          ],
          defaultStyle: {
            fontSize: 10,
            font: 'THSarabunNew',
            bold: true
          }
        }
        /** Export */
        pdfMake.createPdf(objExport).download(`SaleOrder_${formData.productRef}`)
      }
      setBtnLoading(false)
      return Promise.resolve()
    } catch (error) {
      setBtnLoading(false)
      return Promise.reject(error)
    }
  }
  const openModalFinancial = () => {
    setIsModalFinancialVisible(true)
  }
  const openModalSaleOrder = async () => {
    try {
      const ref = await db.collection('products').where('cid', '==', companyId).get()
      const masterData = ref.docs.map(e => {
        return {
          ...e.data(),
          id: e.id
        }
      })
      setDataProducts(masterData)
      const productNameList = ref.docs.map(e => {
        return e.data().productRef
      })
      setDataProductName(productNameList)
      const itemCodeList = ref.docs.map(e => {
        return e.data().itemCode
      })
      setDataItemCode(itemCodeList)
      setIsModalSaleOrderVisible(true)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  const handleCancel = () => {
    setIsModalFinancialVisible(false)
    setIsModalSaleOrderVisible(false)
    formSaleOrder.setFieldsValue({ productRef: null, itemCode: null, fileType: null })
    setDateFinancial(null)
  }
  const handleSelect = (data) => {
    const [filter] = dataProducts.filter(e => e.productRef === data || e.itemCode === data)
    if (filter) {
      formSaleOrder.setFieldsValue({ productRef: filter.productRef, itemCode: filter.itemCode })
    } else {
      formSaleOrder.setFieldsValue({ productRef: null, itemCode: null })
    }
  }
  const monthChange = (date, dateString) => {
    if (dateString) setDateFinancial(dayjs(dateString))
    else setDateFinancial(null)
  }
  const content = (
    <div style={{ minWidth: 100 }}>
      <Row><Button block onClick={exportTable}>Export All PO</Button></Row>
      <Row><Button block onClick={openModalSaleOrder}>Sale Order by Product</Button></Row>
      <Row><Button block onClick={openModalFinancial}>Financial</Button></Row>
    </div>
  )
  return (
    <div className="exportListPage">
      <Popover content={content} trigger="hover">
        <Button type="primary" size="middle" icon={<FontAwesomeIcon icon={faDownload} className="fa-icon-button" />} loading={btnLoading} >Export</Button>
      </Popover>
      <Modal open={isModalFinancialVisible}
        onCancel={handleCancel}
        styles={{
          body: { padding: 0 }
        }}
        footer={null}>
        <Card
          title="Financial"
          style={{ borderRadius: 10 }}
          headStyle={{ fontWeight: 'bold', fontSize: '20px', lineHeight: '23px', backgroundColor: '#CFE2FF', color: '#154D9F' }}
          bodyStyle={{ boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)', borderRadius: '0 0 10px 10px' }}
        >

          <Row className="exportListPage">
            <Col span={5}></Col>
            <Col span={14}>
              <AntdForm
                labelCol={{ span: 16 }}
                wrapperCol={{ span: 16 }}
                layout="vertical"
                onFinish={exportFinancial}>
                <div >
                  <p style={{ fontWeight: 'bold' }}>Select Month</p>
                  <div style={{ marginBottom: '25px' }}>
                    <Space direction="vertical" style={{ width: '100%' }}>
                      <DatePicker value={dateFinancial ? dayjs(dateFinancial) : null} onChange={monthChange} picker="month" />
                    </Space>
                  </div>
                  <Button htmlType="submit" type="primary" style={{ width: '100%' }} loading={btnLoading}>Export</Button>
                </div>
              </AntdForm>
            </Col>
            <Col span={5}></Col>
          </Row>
        </Card>
      </Modal>
      <Modal open={isModalSaleOrderVisible}
        width="50%"
        onCancel={handleCancel}
        styles={{
          body: { padding: 0, borderRadius: 10 }
        }}
        footer={null}>
        <Card
          title="Sale Order By product"
          style={{ borderRadius: 10 }}
          headStyle={{ fontWeight: 'bold', fontSize: '20px', lineHeight: '23px', backgroundColor: '#CFE2FF', color: '#154D9F', borderRadius: 10 }}
          bodyStyle={{ boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)', borderRadius: 10 }}
        >

          <Row className="exportListPage">
            <Col span={24}>
              <AntdForm
                form={formSaleOrder}
                name="formSaleOrder"
                labelCol={{ span: 16 }}
                wrapperCol={{ span: 16 }}
                layout="vertical"
                onFinish={exportSaleOrder}>
                <Row>
                  <Col span={12}>
                    <AntdForm.Item label={
                      <span style={{ fontWeight: 'bold' }}>Product Name</span>
                    } name="productRef" rules={[{ required: true, message: 'Please select Product Name' }]}>
                      <Select
                        showSearch
                        onChange={handleSelect}
                        optionFilterProp="value"
                      >
                        {
                          dataProductName && dataProductName.map((o, i) => (
                            <Select.Option key={i} value={o}>{o}</Select.Option>
                          ))
                        }
                      </Select>
                    </AntdForm.Item>
                  </Col>
                  <Col span={12}>
                    <AntdForm.Item label={
                      <span style={{ fontWeight: 'bold' }}>Item Code</span>
                    } name="itemCode" rules={[{ required: true, message: 'Please select item code' }]}>
                      <Select
                        onChange={handleSelect}
                      >
                        {
                          dataItemCode && dataItemCode.map((o, i) => (
                            <Select.Option key={i} value={o}>{o}</Select.Option>
                          ))
                        }
                      </Select>
                    </AntdForm.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <AntdForm.Item label={
                      <span style={{ fontWeight: 'bold' }}>
                        File Type &nbsp;
                        <Tooltip title={`CSV(Excel) จะไม่มีตารางรวม Total Order ในแต่ละ Destination
                                     PDF จะมีครบทุกตารางในไฟล์`}>
                          <FontAwesomeIcon icon={faInfoCircle} />
                        </Tooltip>
                      </span>

                    } name="fileType" rules={[{ required: true, message: 'Please select file type' }]}>
                      <Select
                      >
                        <Select.Option value="csv">CSV (Excel)</Select.Option>
                        <Select.Option value="pdf">PDF</Select.Option>
                      </Select>
                    </AntdForm.Item>
                  </Col>
                </Row>
                <Row >
                  <div style={{ margin: '0 auto' }}>  <Button style={{ width: 200 }} htmlType="submit" type="primary" loading={btnLoading}>Export</Button></div>

                </Row>
              </AntdForm>
            </Col>
          </Row>
        </Card>
      </Modal>
    </div>
  )
}

export default StatusConfirmModal

import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAsync } from 'react-use'

import { Form as AntdForm, Card, Col, Row, Input, Button, Skeleton, Select } from 'antd'
import { PlusOutlined } from '@ant-design/icons';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// import _ from 'lodash'

import { useAuth } from '../../../contexts/AuthContextProvider'

const Form = () => {
  const navigate = useNavigate()
  const { db } = useAuth()
  const { consigneeId } = useParams()
  const [btnLoading, setBtnLoading] = useState(false)
  const { value, loading } = useAsync(async () => {
    if (!consigneeId) {
      return {
        answer: [
          {
            consigName: '',
            consigAddress: '',
            consigContact: '',
            consigPhone: ''
          }
        ]
      }
    } else {
      const ref = await db.collection('consignee').doc(consigneeId).get()
      return {
        ...ref.data(),
        id: ref.id,
        answer: [
          {
            consigName: ref.consigName,
            consigAddress: ref.consigAddress,
            consigContact: ref.consigContact,
            consigPhone: ref.consigPhone,
            status: ref.status
          }
        ]
      }
    }
  }, [consigneeId])
  const mode = consigneeId ? 'Submit' : 'Create'
  const handleSubmit = async (formData) => {
    const batch = db.batch()
    try {
      setBtnLoading(true)
      if (consigneeId) {
        formData.answer.forEach((o) => {
          batch.set(db.collection('consignee').doc(consigneeId), { ...o })
        })
      } else {
        formData.answer.forEach((o) => {
          batch.set(db.collection('consignee').doc(), { ...o })
        })
      }
      await batch.commit()
      navigate('/admin/master-data/consignee/list')
    } catch {
      setBtnLoading(false)
    }
  }
  if (loading) return <Skeleton></Skeleton>

  return (
    <AntdForm
      layout="vertical"
      onFinish={handleSubmit}
      initialValues={value}
    >
      <Card
        title="Consignee"
        headStyle={{ fontWeight: 'bold', fontSize: '20px', lineHeight: '23px', backgroundColor: '#CFE2FF', color: '#154D9F' }}
        bodyStyle={{ boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)', borderRadius: '0 0 10px 10px' }}
      >
        <Row>
          <Col span={1}></Col>
          <Col span={23}>
            <AntdForm.List name="answer">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(field => (
                    <Row gutter={[32, 0]} key={field.key}>
                      <Col span={4}>
                        <AntdForm.Item
                          {...field}
                          name={[field.name, 'consigName']}
                          label="Consignee Name"
                          fieldKey={[field.fieldKey, 'consigName']}
                          initialValue={value.consigName}
                          rules={[{ required: true, message: 'Consignee name cannot be blank' }]}
                        >
                          <Input />
                        </AntdForm.Item>
                      </Col>
                      <Col span={6}>
                        <AntdForm.Item
                          {...field}
                          name={[field.name, 'consigAddress']}
                          label="Address"
                          fieldKey={[field.fieldKey, 'consigAddress']}
                          initialValue={value.consigAddress}
                          rules={[{ required: true, message: 'Address cannot be blank' }]}
                        >
                          <Input />
                        </AntdForm.Item>
                      </Col>
                      <Col span={4}>
                        <AntdForm.Item
                          {...field}
                          name={[field.name, 'consigContact']}
                          label="Contact Name"
                          fieldKey={[field.fieldKey, 'consigContact']}
                          initialValue={value.consigContact}
                        >
                          <Input />
                        </AntdForm.Item>
                      </Col>
                      <Col span={4}>
                        <AntdForm.Item
                          {...field}
                          name={[field.name, 'consigPhone']}
                          label="Phone"
                          fieldKey={[field.fieldKey, 'consigPhone']}
                          initialValue={value.consigPhone}
                        >
                          <Input />
                        </AntdForm.Item>
                      </Col>
                      <Col span={4}>
                        <AntdForm.Item
                          label="Status"
                          fieldKey={[field.fieldKey, 'status']}
                          name={[field.name, 'status']}
                          initialValue={mode === 'Create' ? 'Active' : value.status}
                        >
                          <Select >
                            <Select.Option value="Active">Active</Select.Option>
                            {consigneeId &&
                              <Select.Option value="InActive">InActive</Select.Option>
                            }
                          </Select>
                        </AntdForm.Item>
                      </Col>
                      <Col span={2}>
                        {mode === 'Create' &&
                          <Button style={{ marginTop: '40px', backgroundColor: '#EA3939', color: '#FFF', width: '42px', height: '40px', borderRadius: '4px' }} onClick={() => remove(field.name)} icon={<FontAwesomeIcon icon={faTrashAlt} />}></Button>
                        }
                      </Col>
                    </Row>
                  ))}
                  {mode === 'Create' &&
                    <Row justify="center" style={{ marginTop: '20px' }}>
                      <AntdForm.Item>
                        <Button style={{ width: '150px', borderRadius: '8px' }} disabled={mode === 'Submit'} type="primary" onClick={() => add()}><PlusOutlined />Add Consignee</Button>
                      </AntdForm.Item>
                    </Row>
                  }
                </>
              )}
            </AntdForm.List>
            <Row justify="center" style={{ marginTop: '20px' }}>
              <Button style={{ height: '40px', width: '300px', borderRadius: '8px' }} type="primary" loading={btnLoading} htmlType="submit">{mode}</Button>
            </Row>
          </Col>
        </Row>
      </Card>
    </AntdForm>
  )
}

export default Form

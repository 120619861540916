import React, { useContext, useEffect, useRef, useState } from 'react'
import { useAsync } from 'react-use'

import { Card, Table, Form, Modal, InputNumber } from 'antd'
import { ExclamationCircleFilled } from '@ant-design/icons'

import { useAuth } from '../../../contexts/AuthContextProvider'

const { confirm } = Modal

const EditableContext = React.createContext(null)

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm()
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  )
}
const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false)
  const inputRef = useRef(null)
  const form = useContext(EditableContext)
  useEffect(() => {
    if (editing) {
      inputRef.current.focus()
    }
  }, [editing])
  const toggleEdit = () => {
    setEditing(!editing)
    form.setFieldsValue({
      [dataIndex]: record[dataIndex]
    })
  }
  const save = async () => {
    try {
      const values = await form.validateFields()
      toggleEdit()
      handleSave({
        ...record,
        ...values
      })
    } catch (errInfo) {
      console.log('Save failed:', errInfo)
    }
  }
  let childNode = children
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`
          }
        ]}
      >
        <InputNumber ref={inputRef} onPressEnter={save} onBlur={save} style={{ height: 32 }} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    )
  }
  return <td {...restProps}>{childNode}</td>
}

const List = () => {
  const { db } = useAuth()

  const [dataSource, setDataSource] = useState([])

  useAsync(async () => {
    const snap = await db.collection('plate').get()
    const data = snap.docs.map(doc => {
      return {
        ...doc.data(),
        id: doc.id
      }
    })
    setDataSource(data)
  })

  const defaultColumns = [
    {
      title: 'Plate / Pallet',
      dataIndex: 'name',
      width: 500
    },
    {
      title: 'Value (USD / Unit)',
      dataIndex: 'value',
      editable: true
    }
  ]

  const handleSave = (row) => {
    confirm({
      title: `Do you want to change value to ${row.value}`,
      icon: <ExclamationCircleFilled />,
      onOk() {
        const newData = [...dataSource]
        const index = newData.findIndex((item) => row.id === item.id)
        const item = newData[index]
        newData.splice(index, 1, {
          ...item,
          ...row
        })
        setDataSource(newData)
        return new Promise((resolve, reject) => {
          db.collection('plate').doc(row.id).set({
            name: row.name,
            value: row.value
          }, {
            merge: true
          })
          resolve()
        })
      },
      onCancel() { }
    })
  }
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell
    }
  }
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave
      })
    }
  })

  return (
    <Card
      title="Plate & Pallet"
      headStyle={{ fontWeight: 'bold', fontSize: '20px', lineHeight: '23px', backgroundColor: '#CFE2FF', color: '#154D9F' }}
      bodyStyle={{ boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)', borderRadius: '0 0 10px 10px' }}
    >
      <Table
        bordered
        rowKey="id"
        style={{ overflowX: 'auto' }}
        components={components}
        rowClassName={() => 'editable-row'}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />
    </Card>
  )
}

export default List

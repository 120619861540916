import React from 'react'

import { Button, Row, Col, Modal } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

const StatusConfirmModal = ({ headerText, title, buttonText, visible, onSubmit, onCancel, loading }) => {
  return (
    <Modal
      forceRender
      open={visible}
      title={
        <div style={{ padding: '16px 24px', fontWeight: 'bold', fontSize: '20px', lineHeight: '23px', backgroundColor: '#CFE2FF', color: '#154D9F', borderRadius: '10px 10px 0px 0px' }}>
          {headerText}
        </div>
      }
      onOk={onSubmit}
      onCancel={onCancel}
      width={'50%'}
      closable={false}
      maskClosable={false}
      footer={null}>
      <Row justify="center" gutter={[0, 16]}>
        <span style={{ fontSize: '1.25em', fontWeight: 'bold', margin: '24px 0' }}>{title}</span>
      </Row>
      <Row justify="center" gutter={[16, 0]}>
        <Col span={8}>
          <Button type="primary" size="large" htmlType="submit" block onClick={onSubmit} loading={loading} style={{ borderRadius: '5px' }}>{buttonText}</Button>
        </Col>
        <Col span={1}>
          <Button type="danger" size="large" icon={<FontAwesomeIcon icon={faTimes} />} onClick={onCancel} style={{ borderRadius: '5px' }}></Button>
        </Col>
      </Row>
    </Modal>
  )
}

export default StatusConfirmModal

const projectID = process.env.REACT_APP_FIREBASE_PROJECT_ID || "craftfood-bcaac";
const hostnames = {
  'craftfood-bcaac': 'https://craftfood-bcaac.web.app',
  'croftfood-be365': 'https://croftfoods.co',
}
const dev = {
  hostName: 'http://localhost:3000',
  apiPath: `https://asia-southeast2-${projectID}.cloudfunctions.net`
};

const prod = {
  hostName: hostnames[projectID],
  apiPath: `https://asia-southeast2-${projectID}.cloudfunctions.net`
};

const config = process.env.NODE_ENV === 'production' ? prod : dev

// eslint-disable-next-line
export default {
  ...config
}

import React from 'react'

import { Button } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExport } from '@fortawesome/free-solid-svg-icons'

import dayjs from 'dayjs'
import { ToWords } from 'to-words'
import pdfMake from 'pdfmake/build/pdfmake'

import { useAuth } from '../../../contexts/AuthContextProvider'

import pdfFonts from '../../../assets/pdfMakeFonts/vfs_fonts'
pdfMake.vfs = pdfFonts.pdfMake.vfs
pdfMake.fonts = {
  THSarabunNew: {
    normal: 'THSarabunNew.ttf',
    bold: 'THSarabunNew-Bold.ttf',
    italics: 'THSarabunNew-Italic.ttf',
    bolditalics: 'THSarabunNew-BoldItalic.ttf'
  },
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-MediumItalic.ttf'
  }
}

pdfMake.tableLayouts = {
  piLayout: {
    paddingLeft: () => 2,
    paddingRight: () => 2
  }
}

const toWords = new ToWords({
  localeCode: 'en-US',
  converterOptions: {
    currency: true,
    ignoreDecimal: false,
    ignoreZeroCurrency: false,
    doNotAddOnly: false
  }
})

const dbDateToText = (date) => {
  return (date) ? dayjs(date.toDate()).format('DD/MM/YYYY') : ''
}

const formatNumber = (value, dec = 0) => {
  if (!value || isNaN(value)) {
    return '-'
  }
  // const str = (value.toFixed(dec)).toString()
  // return str.replace(/\d(?=(\d{3})+\.)/g, '$&,')
  return new Intl.NumberFormat('en-US', { maximumFractionDigits: dec }).format(value)
}

const toDataURL = url => fetch(url)
  .then(response => response.blob())
  .then(blob => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsDataURL(blob)
  }))

const DocumentPdf = ({ record }) => {
  const { db, companySelected, companyMaster } = useAuth()

  const headerProforma = (data) => {
    const header = [
      {
        columns: [
          {
            width: 200,
            stack: [
              {
                text: data?.company?.name ?? '',
                fontSize: 18,
                bold: true
              },
              {
                text: data?.company?.address ?? '',
                bold: true
              },
              {
                text: `EMAIL : ${data?.company?.email ?? ''}`,
                bold: true
              }
            ]
          },
          {
            width: '*',
            text: ''
          },
          {
            alignment: 'right',
            image: data.imageData,
            width: 80
          }
        ]
      }
    ]
    return header
  }

  const footerProforma = (data) => {
    const wLabel = 80
    const fontSize = 12
    const footer = [
      {
        text: 'Banking Information:',
        fontSize
      },
      {
        stack: [
          {
            columns: [
              {
                width: wLabel,
                fontSize,
                text: 'Bank Name:'
              },
              {
                fontSize,
                text: data?.company?.bankname ?? ''
              }
            ]
          },
          {
            columns: [
              {
                width: wLabel,
                fontSize,
                text: 'Branch:'
              },
              {
                fontSize,
                text: data?.company?.bankbranch ?? ''
              }
            ]
          },
          {
            columns: [
              {
                width: wLabel,
                fontSize,
                text: 'Branch Address:'
              },
              {
                fontSize,
                text: data?.company?.bankaddress ?? ''
              }
            ]
          },
          {

            columns: [
              {
                width: wLabel,
                fontSize,
                text: 'Account Name: '
              },
              {
                fontSize,
                text: data?.company?.bankaccount ?? ''
              }
            ]
          },
          {
            columns: [
              {
                width: wLabel,
                fontSize,
                text: 'Account Number:'
              },
              {
                fontSize,
                text: data?.company?.banknumber ?? ''
              },
            ]
          },
          {
            columns: [
              {
                width: wLabel,
                fontSize,
                text: 'Swift No.:'
              },
              {
                fontSize,
                text: data?.company?.bankswift ?? ''
              }
            ]
          }
        ]
      }
    ]
    return footer
  }

  const detailProforma = (data) => {
    const wLabel = 80
    const fontSize = 12
    const detail = [
      {
        columns: [
          {
            width: '*',
            stack: [
              {
                columns: [
                  {
                    width: 20,
                    text: 'TO:'
                  },
                  {
                    width: '*',
                    stack: [
                      {
                        text: data?.consignee?.consigName ?? ''
                      },
                      {
                        text: data?.consignee?.consigAddress ?? ''
                      },
                      {
                        text: data?.consignee?.consigPhone ?? ''
                      },
                      {
                        text: data?.consignee?.consigContact ?? ''
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            width: 120,
            text: ''
          },
          {
            width: '*',
            stack: [
              {
                columns: [
                  {
                    width: wLabel,
                    fontSize,
                    text: 'INVOICE NO:'
                  },
                  {
                    fontSize,
                    bold: true,
                    text: data?.piForm?.piNo ?? ''
                  }
                ]
              },
              {
                columns: [
                  {
                    width: wLabel,
                    fontSize,
                    text: 'DATE:'
                  },
                  {
                    fontSize,
                    text: dbDateToText(data?.piForm?.date)
                  }
                ]
              },
              {
                columns: [
                  {
                    width: wLabel,
                    fontSize,
                    text: 'REVISION NO.'
                  },
                  {
                    fontSize,
                    text: data?.piForm?.rev ?? ''
                  }
                ]
              },
              {
                columns: [
                  {
                    width: wLabel,
                    fontSize,
                    text: 'Due date:'
                  },
                  {
                    fontSize,
                    bold: true,
                    text: dbDateToText(data?.piForm?.duedate)
                  }
                ]
              },
              {
                columns: [
                  {
                    width: wLabel,
                    fontSize,
                    text: (data?.piForm?.type === 'deposit') ? 'Loading plan:' : ''
                  },
                  {
                    fontSize,
                    text: (data?.piForm?.type === 'deposit') ? dbDateToText(data?.piForm?.loadingdate) : ''
                  }
                ]
              },
              {
                columns: [
                  {
                    width: wLabel,
                    fontSize,
                    text: (!data?.piForm?.type) ? 'Remark:' : (data.piForm.type === 'deposit') ? 'Remark (Deposit):' : 'Remark (Balance):'
                  },
                  {
                    fontSize,
                    text: data?.piForm?.remark ?? ''
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
    return detail
  }

  const tablePODeposit = (data) => {
    const headerFontSize = 9
    let prevPO = ''
    let totalAmount = 0
    const list = (data.piForm?.invs ?? []).reduce((acc, o, i) => {
      totalAmount += o.totaldepamt
      if (i !== 0 && (o.poid !== prevPO)) {
        acc[acc.length - 1]['isLast'] = true
      }
      if (i === (data.piForm.invs.length - 1)) {
        o['isLast'] = true
      }
      prevPO = o.poid
      return [...acc, { ...o }]
    }, [])
    const words = toWords.convert(totalAmount)
    const tablePO = [
      {
        stack: [
          {
            layout: 'piLayout',
            table: {
              headerRows: 1,
              widths: [42, 40, 35, '*', 30, 30, 20, 30, 30, 40, 22, 40],
              body: [
                [
                  {
                    text: 'INV No.',
                    fontSize: headerFontSize,
                    bold: true,
                    alignment: 'center'
                  },
                  {
                    text: 'PO Number',
                    fontSize: headerFontSize,
                    bold: true,
                    alignment: 'center'
                  },
                  {
                    text: 'Item No.',
                    fontSize: headerFontSize,
                    bold: true,
                    alignment: 'center'
                  },
                  {
                    text: 'Item Description',
                    fontSize: headerFontSize,
                    bold: true,
                    alignment: 'center'
                  },
                  {
                    text: 'Packing size',
                    fontSize: headerFontSize,
                    bold: true,
                    alignment: 'center'
                  },
                  {
                    text: 'Price/bag (USD)',
                    fontSize: headerFontSize,
                    bold: true,
                    alignment: 'center'
                  },
                  {
                    text: 'Unit/CTN',
                    fontSize: headerFontSize,
                    bold: true,
                    alignment: 'center'
                  },
                  {
                    text: 'Total number of bag',
                    fontSize: headerFontSize,
                    bold: true,
                    alignment: 'center'
                  },
                  {
                    text: 'Total number of Cartons',
                    fontSize: headerFontSize,
                    bold: true,
                    alignment: 'center'
                  },
                  {
                    text: 'Total P.O. Amount (USD)',
                    fontSize: headerFontSize,
                    bold: true,
                    alignment: 'center'
                  },
                  {
                    text: '% Deposit',
                    fontSize: headerFontSize,
                    bold: true,
                    alignment: 'center'
                  },
                  {
                    text: 'Total Amount (USD)',
                    fontSize: headerFontSize,
                    bold: true,
                    alignment: 'center'
                  }
                ],
                ...[...(list.map((o) => {
                  return [
                    {
                      border: (o.isLast) ? [true, false, true, true] : [true, false, true, false],
                      text: o.invNo ?? '',
                      fontSize: 10
                    },
                    {
                      border: (o.isLast) ? [true, false, true, true] : [true, false, true, false],
                      text: o.poNo ?? '',
                      fontSize: 10
                    },
                    {
                      border: (o.isLast) ? [true, false, true, true] : [true, false, true, false],
                      text: o.itemCode ?? '',
                      fontSize: 10
                    },
                    {
                      border: (o.isLast) ? [true, false, true, true] : [true, false, true, false],
                      text: o.productRef ?? '',
                      fontSize: 10
                    },
                    {
                      border: (o.isLast) ? [true, false, true, true] : [true, false, true, false],
                      text: formatNumber(o.weight, 2),
                      fontSize: 11,
                      alignment: (o?.weight) ? 'right' : 'center'
                    },
                    {
                      border: (o.isLast) ? [true, false, true, true] : [true, false, true, false],
                      text: formatNumber(o.price, 2),
                      fontSize: 11,
                      alignment: (o?.price) ? 'right' : 'center'
                    },
                    {
                      border: (o.isLast) ? [true, false, true, true] : [true, false, true, false],
                      text: formatNumber(o.bagPerCarton, 0),
                      fontSize: 11,
                      alignment: (o?.bagPerCarton) ? 'right' : 'center'
                    },
                    {
                      border: (o.isLast) ? [true, false, true, true] : [true, false, true, false],
                      text: formatNumber(o.confirmBAG, 0),
                      fontSize: 11,
                      alignment: (o?.confirmBAG) ? 'right' : 'center'
                    },
                    {
                      border: (o.isLast) ? [true, false, true, true] : [true, false, true, false],
                      text: formatNumber(o.confirmCTN, 0),
                      fontSize: 11,
                      alignment: (o?.confirmCTN) ? 'right' : 'center'
                    },
                    {
                      border: (o.isLast) ? [true, false, true, true] : [true, false, true, false],
                      text: formatNumber(o.amount, 2),
                      fontSize: 11,
                      alignment: (o?.amount) ? 'right' : 'center'
                    },
                    {
                      border: (o.isLast) ? [true, false, true, true] : [true, false, true, false],
                      text: formatNumber(o.perdeposit, 2),
                      fontSize: 11,
                      alignment: (o?.perdeposit) ? 'right' : 'center'
                    },
                    {
                      border: (o.isLast) ? [true, false, true, true] : [true, false, true, false],
                      text: formatNumber(o.totaldepamt, 2),
                      fontSize: 11,
                      alignment: (o?.totaldepamt) ? 'right' : 'center'
                    }
                  ]
                })), [
                  {
                    border: [false, false, false, false],
                    text: ''
                  },
                  {
                    border: [false, false, false, false],
                    text: ''
                  },
                  {
                    border: [false, false, false, false],
                    text: ''
                  },
                  {
                    border: [false, false, false, false],
                    text: ''
                  },
                  {
                    border: [false, false, false, false],
                    text: ''
                  },
                  {
                    border: [false, false, false, false],
                    text: ''
                  },
                  {
                    border: [false, false, false, false],
                    text: ''
                  },
                  {
                    border: [false, false, false, false],
                    text: ''
                  },
                  {
                    colSpan: 2,
                    border: [true, false, true, true],
                    text: 'Grand Total (USD)',
                    fontSize: 14,
                    bold: true,
                    alignment: 'center'
                  },
                  {},
                  {
                    colSpan: 2,
                    border: [true, false, true, true],
                    text: formatNumber(totalAmount, 2),
                    fontSize: 15,
                    bold: true,
                    alignment: 'center'
                  },
                  {}
                ]]
              ]
            }
          },
          '\n',
          {
            text: words.toUpperCase()
          },
          '\n'
        ]
      }
    ]
    return tablePO
  }

  const tablePOBalance = (data) => {
    const headerFontSize = 9
    let prevPO = ''
    let totalAmount = 0
    const list = (data.piForm?.invs ?? []).reduce((acc, o, i) => {
      totalAmount = (o.cn) ? totalAmount - o.totalbalamt : totalAmount + o.totalbalamt
      if (i !== 0 && (o.poid !== prevPO)) {
        acc[acc.length - 1]['isLast'] = true
      }
      if (i === (data.piForm.invs.length - 1)) {
        o['isLast'] = true
      }
      prevPO = o.poid
      return [...acc, { ...o }]
    }, [])
    const words = toWords.convert(totalAmount)
    const tablePO = [
      {
        stack: [
          {
            layout: 'piLayout',
            table: {
              headerRows: 1,
              widths: [37, 40, 43, 33, '*', 20, 24, 34, 27, 27, 34, 34, 34],
              body: [
                [
                  {
                    text: 'INV No.',
                    fontSize: headerFontSize,
                    bold: true,
                    alignment: 'center'
                  },
                  {
                    text: 'PO Number',
                    fontSize: headerFontSize,
                    bold: true,
                    alignment: 'center'
                  },
                  {
                    text: 'BL/SWB No.',
                    fontSize: headerFontSize,
                    bold: true,
                    alignment: 'center'
                  },
                  {
                    text: 'Date of BL/SWB',
                    fontSize: headerFontSize,
                    bold: true,
                    alignment: 'center'
                  },
                  {
                    text: 'Item Description',
                    fontSize: headerFontSize,
                    bold: true,
                    alignment: 'center'
                  },
                  {
                    text: 'Price/bag (USD)',
                    fontSize: headerFontSize,
                    bold: true,
                    alignment: 'center'
                  },
                  {
                    text: 'Total number of bag',
                    fontSize: headerFontSize,
                    bold: true,
                    alignment: 'center'
                  },
                  {
                    text: 'Total Amount (USD)',
                    fontSize: headerFontSize,
                    bold: true,
                    alignment: 'center'
                  },
                  {
                    text: 'Plate USD',
                    fontSize: headerFontSize,
                    bold: true,
                    alignment: 'center'
                  },
                  {
                    text: 'Pallet USD',
                    fontSize: headerFontSize,
                    bold: true,
                    alignment: 'center'
                  },
                  {
                    text: 'Total Amount (USD)',
                    fontSize: headerFontSize,
                    bold: true,
                    alignment: 'center'
                  },
                  {
                    text: 'Deposit paid (USD)',
                    fontSize: headerFontSize,
                    bold: true,
                    alignment: 'center'
                  },
                  {
                    text: 'Total Balance Amount (USD)',
                    fontSize: headerFontSize,
                    bold: true,
                    alignment: 'center'
                  }
                ],
                ...[...(list.map((o) => {
                  return [
                    {
                      border: (o.isLast) ? [true, false, true, true] : [true, false, true, false],
                      text: o?.invNo ?? '',
                      fontSize: 9
                    },
                    {
                      border: (o.isLast) ? [true, false, true, true] : [true, false, true, false],
                      text: o?.poNo ?? '',
                      fontSize: 10
                    },
                    {
                      border: (o.isLast) ? [true, false, true, true] : [true, false, true, false],
                      text: o?.bl ?? '',
                      fontSize: 10
                    },
                    {
                      border: (o.isLast) ? [true, false, true, true] : [true, false, true, false],
                      text: dbDateToText(o?.blDate),
                      fontSize: 9
                    },
                    {
                      border: (o.isLast) ? [true, false, true, true] : [true, false, true, false],
                      text: o?.productRef ?? '',
                      fontSize: 9
                    },
                    {
                      border: (o.isLast) ? [true, false, true, true] : [true, false, true, false],
                      text: formatNumber(o.price, 2),
                      fontSize: 10,
                      alignment: (o?.price) ? 'right' : 'center'
                    },
                    {
                      border: (o.isLast) ? [true, false, true, true] : [true, false, true, false],
                      text: formatNumber(o.confirmBAG, 0),
                      fontSize: 10,
                      alignment: (o?.confirmBAG) ? 'right' : 'center'
                    },
                    {
                      border: (o.isLast) ? [true, false, true, true] : [true, false, true, false],
                      text: formatNumber(o.amount, 2),
                      fontSize: 10,
                      alignment: (o?.amount) ? 'right' : 'center'
                    },
                    {
                      border: (o.isLast) ? [true, false, true, true] : [true, false, true, false],
                      text: formatNumber(o.plateusd, 2),
                      fontSize: 10,
                      alignment: (o?.plateusd) ? 'right' : 'center'
                    },
                    {
                      border: (o.isLast) ? [true, false, true, true] : [true, false, true, false],
                      text: formatNumber(o.palletusd, 2),
                      fontSize: 10,
                      alignment: (o?.palletusd) ? 'right' : 'center'
                    },
                    {
                      border: (o.isLast) ? [true, false, true, true] : [true, false, true, false],
                      text: (o.cn) ? 'C/N' : formatNumber((o.amount + (o?.plateusd ?? 0) + (o?.palletusd ?? 0)), 2),
                      fontSize: 10,
                      alignment: (o.cn) ? 'center' : 'right'
                    },
                    {
                      border: (o.isLast) ? [true, false, true, true] : [true, false, true, false],
                      text: formatNumber(o.totaldepamt, 2),
                      fontSize: 10,
                      alignment: (o?.totaldepamt) ? 'right' : 'center'
                    },
                    {
                      border: (o.isLast) ? [true, false, true, true] : [true, false, true, false],
                      text: (o.cn) ? `(${formatNumber(o.totalbalamt, 2)})` : formatNumber(o.totalbalamt, 2),
                      fontSize: 10,
                      alignment: (o?.totalbalamt) ? 'right' : 'center'
                    }
                  ]
                })), [
                  {
                    border: [false, false, false, false],
                    text: ''
                  },
                  {
                    border: [false, false, false, false],
                    text: ''
                  },
                  {
                    border: [false, false, false, false],
                    text: ''
                  },
                  {
                    border: [false, false, false, false],
                    text: ''
                  },
                  {
                    border: [false, false, false, false],
                    text: ''
                  },
                  {
                    border: [false, false, false, false],
                    text: ''
                  },
                  {
                    border: [false, false, false, false],
                    text: ''
                  },
                  {
                    border: [false, false, false, false],
                    text: ''
                  },
                  {
                    border: [false, false, false, false],
                    text: ''
                  },
                  {
                    colSpan: 2,
                    border: [true, false, true, true],
                    text: 'Grand Total (USD)',
                    fontSize: 14,
                    bold: true,
                    alignment: 'center'
                  },
                  {},
                  {
                    colSpan: 2,
                    border: [true, false, true, true],
                    text: formatNumber(totalAmount, 2),
                    fontSize: 15,
                    bold: true,
                    alignment: 'center'
                  },
                  {}
                ]]
              ]
            }
          },
          '\n',
          {
            text: words.toUpperCase()
          },
          '\n'
        ]
      }
    ]
    return tablePO
  }

  const exportProforma = (data) => {
    const tablePO = (data.piForm.type === 'deposit') ? tablePODeposit(data) : tablePOBalance(data)
    const result = {
      content: [
        ...headerProforma(data),
        { text: 'PROFORMA INVOICE', fontSize: 18, bold: true, alignment: 'center', },
        '\n',
        ...detailProforma(data),
        '\n',
        ...tablePO,
        ...footerProforma(data)
      ],
      defaultStyle: {
        fontSize: 12,
        font: 'THSarabunNew',
        bold: true
      }
    }
    return result
  }

  const genPdf = async () => {
    const collection = (record?.piId) ? 'piHistory' : 'pi'
    const snap = await db.collection(collection).doc(record.id).get()
    const value = snap.data()
    const company = (value?.isfoodfarm) ? companyMaster : companySelected
    const imageData = await toDataURL(company.url)

    let consignee = {}
    if (value?.consignee) {
      const consineeSnap = await db.collection('consignee').doc(value.consignee).get()
      consignee = {
        ...consineeSnap.data()
      }
    }

    const data = {
      piForm: value,
      company,
      consignee,
      imageData
    }

    const proformaObj = exportProforma(data)
    pdfMake.createPdf(proformaObj).download(`Proforma_${value.piNo}`)
  }

  return (
    <Button
      type='text'
      size='middle'
      style={{ backgroundColor: '#154D9F', color: '#FFF' }}
      icon={<FontAwesomeIcon icon={faFileExport} />}
      onClick={() => genPdf()}
    ></Button>
  )
}

export default DocumentPdf

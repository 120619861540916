import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAsync } from 'react-use'

import { Button, Card, Row, Col, Select, Skeleton, Table, Popconfirm, message } from 'antd'
import { PlusOutlined, SearchOutlined, CopyOutlined, DeleteOutlined, RetweetOutlined } from '@ant-design/icons';

import axios from 'axios'

import { useAuth } from '../../contexts/AuthContextProvider'
import config from '../../config'

const filterObj = {
  All: ['Active', 'InActive'],
  Active: ['Active'],
  InActive: ['InActive']
}

const List = () => {
  const navigate = useNavigate()
  const { db, role } = useAuth()

  const [filter, setfilter] = useState('Active')
  const [updateCount, setUpdateCount] = useState(0)

  const { value } = useAsync(async () => {
    const snap = await db.collection('users').where('status', 'in', filterObj[filter]).get()
    let data = snap.docs.map(doc => {
      return {
        ...doc.data(),
        id: doc.id
      }
    })
    data = data.sort((x, y) => (x?.userName ?? '').localeCompare(y?.userName ?? ''))
    return data
  }, [updateCount, filter])

  const handleCreateUser = () => {
    // var auth = auth();
    // var emailAddress = "jk1@yopmail.com";

    // auth.sendPasswordResetEmail(emailAddress).then(function() {
    //   // Email sent.
    // }).catch(function(error) {
    //   // An error happened.
    // });
    navigate('/admin/user/create')
  }

  const handleEdit = (row) => {
    if (row && row.id) navigate(`/admin/user/${row.id}/edit`)
  }

  const handleCopy = async (row) => {
    try {
      const snap = await db.collection('inviteEmails').where('uId', '==', row.id).get()
      let list = snap.docs.map(doc => doc.data()?.token)
      if (list.length) {
        list = list.sort((x, y) => x.createAt.seconds - y.createAt.seconds)
        const url = `${config.hostName}/register/${list[0]}`
        await navigator.clipboard.writeText(url)
        message.success('Copy Verification URL เรียบร้อยแล้ว')
      }
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const handleDelete = async (row) => {
    try {
      await db.collection('users').doc(row.id).delete()
      setUpdateCount(updateCount + 1)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const handleSelect = (value) => {
    setfilter(value)
  }

  const handleUpdateUser = async () => {
    try {
      await axios({
        method: 'post',
        url: `${config.apiPath}/httpSeedUser-updateUser`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const columns = [
    {
      title: 'User Name',
      dataIndex: 'userName',
      width: 250
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: 150
    },
    {
      title: 'Role',
      dataIndex: 'role',
      width: 200,
      render: (data) => (
        <span>{data === 'Planner' ? 'Planner & Production' : data === 'Purchasing' ? 'Sales Management' : data}</span>
      )
    },
    {
      title: 'Active',
      dataIndex: 'status',
      width: 150,
      render: (data) => (
        <div style={{ color: data === 'Active' ? 'green' : 'red' }}>
          {data}
        </div>
      )
    },
    {
      title: 'Operation',
      dataIndex: 'Operation',
      render: (_, row) => (
        <div>
          <Button style={{ width: '32px', height: '30px', borderRadius: '4px' }} type="primary" size="small" onClick={() => { handleEdit(row) }}><SearchOutlined /></Button>
          {(!row?.verified) && (
            <>
              <Button style={{ marginLeft: 12, width: '32px', height: '30px', borderRadius: '4px' }} size="small" onClick={() => { handleCopy(row) }}><CopyOutlined /></Button>
              <Popconfirm
                title={`Are you sure to delete ${row.userName} user?`}
                onConfirm={() => handleDelete(row)}
                okText='Yes'
                cancelText='No'
              >
                <Button style={{ marginLeft: 12, width: '32px', height: '30px', borderRadius: '4px' }} type="danger" size="small" icon={<DeleteOutlined />}></Button>
              </Popconfirm>
            </>
          )}
        </div>
      )
    }
  ]

  if (!value) return <Skeleton></Skeleton>
  return (
    <Card
      title="User List"
      // style={{ margin: '1em', borderRadius: '15px', boxShadow: '0px 2px 12px rgba(0,0,0,0.1)', border: 0 }}
      // headStyle={{ backgroundColor: '#CFE2FF', color: '#154D9F', fontWeight: 'bold', borderRadius: '10px 10px 0px 0px' }}
      headStyle={{ fontWeight: 'bold', fontSize: '20px', lineHeight: '23px', backgroundColor: '#CFE2FF', color: '#154D9F' }}
      bodyStyle={{ boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)', borderRadius: '0 0 10px 10px' }}
    >
      <Row justify="space-between" gutter={[24, 24]}>
        <Col flex="auto">
          <Select defaultValue="Active" style={{ width: 250 }} onChange={(v) => handleSelect(v)}>
            <Select.Option value="All">All</Select.Option>
            <Select.Option value="Active">Active</Select.Option>
            <Select.Option value="InActive">InActive</Select.Option>
          </Select>
        </Col>
        <Col flex="300px">
          {((role === 'Admin') && (value.find((o) => o?.verified === undefined))) && (
            <Button style={{ marginRight: 8, borderRadius: '8px' }} onClick={() => handleUpdateUser()} type="secondary"><RetweetOutlined />Update User</Button>
          )}
          <Button style={{ borderRadius: '8px' }} onClick={() => handleCreateUser()} type="primary"><PlusOutlined />Create User</Button>
        </Col>
      </Row>

      {/* <div style={{ textAlign: "right", marginBottom: 20 }}>
        <Button style={{ borderRadius: '8px' }} onClick={handleCreateUser} type="primary"><PlusOutlined />Create User</Button>
      </div> */}
      <Table
        bordered
        rowKey="id"
        style={{ overflowX: 'auto' }}
        columns={columns}
        dataSource={value}
      />
    </Card>
  )
}

export default List

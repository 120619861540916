import React, { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useAsync } from 'react-use'

import { Button, Form, Input, Skeleton } from 'antd'

import { useAuth } from '../../contexts/AuthContextProvider'

const RegisterPage = () => {
  const navigate = useNavigate()
  const { tokenId } = useParams()
  const { functions, auth } = useAuth()

  const [btnLoading, setLoading] = useState(false)

  const { value } = useAsync(async () => {
    if (!tokenId) {
      return {}
    }

    const verifyToken = functions.httpsCallable('httpRegister-verifyToken')
    const result = await verifyToken({ token: tokenId })
    if (!result.data.email) navigate('/login')
    return result.data
  }, [tokenId])

  const confirmPasswordValidator = ({ getFieldValue }) => ({
    validator(rule, value) {
      if (!value || getFieldValue('password') === value) {
        return Promise.resolve();
      }
      return Promise.reject('The two passwords that you entered do not match!');
    },
  })
  const handleChange = async ({ email, password }) => {
    // ยิง API
    try {
      setLoading(true)
      await auth().createUserWithEmailAndPassword(email, password)
      const updateInviteEmails = functions.httpsCallable('httpRegister-updateInviteEmails');
      await updateInviteEmails({ token: tokenId })
      setLoading(false)
      await auth().signOut()
      navigate('/login')
    } catch (error) {
      setLoading(false)
    }
  }

  if (!value) return <Skeleton></Skeleton>
  return (
    <Form layout="vertical" onFinish={handleChange}>
      <Form.Item name="email" label="Email" rules={[{ required: true }]} initialValue={value.email}>
        <Input disabled className="input-auth" />
      </Form.Item>
      <Form.Item name="name" label="Name" rules={[{ required: true }]} initialValue={value.userName}>
        <Input className="input-auth" />
      </Form.Item>
      <Form.Item name="role" label="Role" initialValue={value.role}>
        <Input disabled className="input-auth" />
      </Form.Item>
      <Form.Item name="password" label="Password" rules={[{ required: true }, { min: 6 }]}>
        <Input.Password className="input-auth" />
      </Form.Item>
      <Form.Item name="confirm-password" label="Confirm Password" rules={[{ required: true }, { min: 6 }, confirmPasswordValidator]}>
        <Input.Password className="input-auth" />
      </Form.Item>
      <Form.Item>
        <Button className="input-auth" style={{ width: '100%' }} type="primary" htmlType="submit" loading={btnLoading}>
          Register
        </Button>
      </Form.Item>
    </Form>
  )
}

export default RegisterPage

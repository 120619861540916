import React from 'react'
import { Outlet } from 'react-router-dom'

import './ProductPage.less'

const ProductPage = () => {
  return (
    <div className='product'>
      <Outlet />
    </div>
  )
}

export default ProductPage

import React from 'react'
import { Routes, Route } from 'react-router-dom'

import PrivateRoute from './privateroute'

import AuthLayout from '../layouts/AuthLayout/AuthLayout'
import LoginPage from '../pages/LoginPage/LoginPage'
import RegisterPage from '../pages/RegisterPage/RegisterPage'
import EmailVerifyPage from '../pages/EmailVerifyPage/EmailVerifyPage'
import ResetPassword from '../pages/Reset-PasswordPage/Reset-PasswordPage'
import ForgetPassword from '../pages/ForgetPasswordPage/ForgetPassword'

import AdminLayout from '../layouts/AdminLayout/AdminLayout'
import ProductPage from '../pages/ProductPage/ProductPage'
import ProductList from '../pages/ProductPage/List'
import ProductForm from '../pages/ProductPage/Form'
import ProductDetail from '../pages/ProductPage/Detail'
import PurchaseOrderPage from '../pages/PurchaseOrderPage/PurchaseOrderPage'
import PurchaseOrderList from '../pages/PurchaseOrderPage/List'
import PurchaseOrderForm from '../pages/PurchaseOrderPage/Form/POForm'
import PurchaseOrderConfirmProduct from '../pages/PurchaseOrderPage/Form/ConfirmProductForm'
import PurchaseOrderHistory from '../pages/PurchaseOrderPage/History'
import ProformaInvoicePage from '../pages/ProformaInvoicePage/ProformaInvoicePage'
import ProformaInvoiceList from '../pages/ProformaInvoicePage/List'
import ProformaInvoiceForm from '../pages/ProformaInvoicePage/Form'
import ProformaInvoiceHistory from '../pages/ProformaInvoicePage/History'
import CreateUserPage from '../pages/CreateUsers/CreateUserPage'
import CreateUserList from '../pages/CreateUsers/List'
import CreateUserForm from '../pages/CreateUsers/Form'
import MasterDataPage from '../pages/MasterDataPage/MasterDataPage'
import MasterDataContainerList from '../pages/MasterDataPage/Container/List'
import MasterDataContainerForm from '../pages/MasterDataPage/Container/Form'
import MasterDataLoadingTypeList from '../pages/MasterDataPage/LoadingType/List'
import MasterDataLoadingTypeForm from '../pages/MasterDataPage/LoadingType/Form'
import MasterDataConsigneeList from '../pages/MasterDataPage/Consignee/List'
import MasterDataConsigneeForm from '../pages/MasterDataPage/Consignee/Form'
import MasterDataCountryPortList from '../pages/MasterDataPage/CountryPort/List'
import MasterDataCountryPortForm from '../pages/MasterDataPage/CountryPort/Form'
import MasterDataCompanyList from '../pages/MasterDataPage/Company/List'
import MasterDataCompanyForm from '../pages/MasterDataPage/Company/Form'
import MasterDataPlateList from '../pages/MasterDataPage/Plate/List'

import Forbidden from '../pages/403/Forbidden'
import NotFound from '../pages/404/NotFound'

const Index = () => {
  return (
    <Routes>
      <Route path='/' element={<AuthLayout />} >
        <Route index element={<LoginPage />} />
        <Route path='/login' element={<LoginPage />} />
        <Route path='/register/:tokenId' element={<RegisterPage />} />
        <Route path='/verifyEmail' element={<EmailVerifyPage />} />
        <Route path='/reset-password' element={<ResetPassword />} />
        <Route path='/forget-password' element={<ForgetPassword />} />
      </Route>
      <Route path='/admin' element={<AdminLayout />} >
        <Route
          path='product'
          element={
            <PrivateRoute roles={['Admin', 'Financial', 'Purchasing', 'Planner', 'Customer']}>
              <ProductPage />
            </PrivateRoute>
          }
        >
          <Route index element={<ProductList />} />
          <Route path='create' element={<ProductForm />} />
          <Route path=':productId/edit' element={<ProductForm />} />
          <Route path='view/:productId' element={<ProductDetail />} />
        </Route>
        <Route
          path='purchase-order'
          element={
            <PrivateRoute roles={['Admin', 'Financial', 'Purchasing', 'Planner', 'Customer']}>
              <PurchaseOrderPage />
            </PrivateRoute>
          }
        >
          <Route index element={<PurchaseOrderList />} />
          <Route path='create' element={<PurchaseOrderForm />} />
          <Route path=':poId' element={<PurchaseOrderForm />} />
          <Route path=':poId/edit' element={<PurchaseOrderForm />} />
          <Route path=':poId/confirm' element={<PurchaseOrderForm />} />
          <Route path=':poId/load' element={<PurchaseOrderConfirmProduct />} />
          <Route path=':poId/history' element={<PurchaseOrderHistory />} />
          <Route path=':poId/history/:revId' element={<PurchaseOrderForm />} />
        </Route>
        <Route
          path='proforma-invoice'
          element={
            <PrivateRoute roles={['Admin', 'Financial']}>
              <ProformaInvoicePage />
            </PrivateRoute>
          }
        >
          <Route index element={<ProformaInvoiceList />} />
          <Route path='create/:type' element={<ProformaInvoiceForm />} />
          <Route path=':piId/edit' element={<ProformaInvoiceForm />} />
          <Route path=':piId/history' element={<ProformaInvoiceHistory />} />
        </Route>
        <Route
          path='user'
          element={
            <PrivateRoute roles={['Admin']}>
              <CreateUserPage />
            </PrivateRoute>
          }
        >
          <Route index element={<CreateUserList />} />
          <Route path='create' element={<CreateUserForm />} />
          <Route path=':userId/edit' element={<CreateUserForm />} />
        </Route>
        <Route
          path='master-data'
          element={
            <PrivateRoute roles={['Admin']}>
              <MasterDataPage />
            </PrivateRoute>
          }
        >
          <Route index element={<MasterDataContainerList />} />
          <Route path='container/list' element={<MasterDataContainerList />} />
          <Route path='container/create' element={<MasterDataContainerForm />} />
          <Route path='container/:containerId/edit' element={<MasterDataContainerForm />} />
          <Route path='container/view/:containerId' element={<MasterDataContainerForm />} />
          <Route path='loadingType/list' element={<MasterDataLoadingTypeList />} />
          <Route path='loadingType/create' element={<MasterDataLoadingTypeForm />} />
          <Route path='loadingType/:loadingTypeId/edit' element={<MasterDataLoadingTypeForm />} />
          <Route path='loadingType/view/:loadingTypeId' element={<MasterDataLoadingTypeForm />} />
          <Route path='consignee/list' element={<MasterDataConsigneeList />} />
          <Route path='consignee/create' element={<MasterDataConsigneeForm />} />
          <Route path='consignee/:consigneeId/edit' element={<MasterDataConsigneeForm />} />
          <Route path='consignee/view/:consigneeId' element={<MasterDataConsigneeForm />} />
          <Route path='country-port/list' element={<MasterDataCountryPortList />} />
          <Route path='country-port/create' element={<MasterDataCountryPortForm />} />
          <Route path='country-port/:countryPort/edit' element={<MasterDataCountryPortForm />} />
          <Route path='country-port/view/:countryPort' element={<MasterDataCountryPortForm />} />
          <Route path='company/list' element={<MasterDataCompanyList />} />
          <Route path='company/create' element={<MasterDataCompanyForm />} />
          <Route path='company/:companyId/edit' element={<MasterDataCompanyForm />} />
          <Route path='company/view/:companyId' element={<MasterDataCompanyForm />} />
          <Route path='plate-pallet/list' element={<MasterDataPlateList />} />
        </Route>
      </Route>
      <Route path='/403' element={<Forbidden />} />
      <Route path='/404' element={<NotFound />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  )
}
export default Index

import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAsync } from 'react-use'

import { Form, Card, Table, Col, Row, Input, InputNumber, Select, Checkbox, Button, message, Skeleton } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/free-solid-svg-icons'

import { DatePicker } from '../../components/DateTime'

import { useAuth } from '../../contexts/AuthContextProvider'
import POModal from './Components/POModal'
import { pickBy, dbTodayjs, dayjsToDB, sleep, isNumber } from '../../utils/tools'

const piTypeObj = {
  deposit: 'Deposit',
  balance: 'Balance'
}

const EditableContext = React.createContext(null)

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm()
  return (
    <Form form={form} name='form' component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  )
}
const EditableCell = ({
  title,
  editable,
  isRequiredEditable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false)
  const inputRef = useRef(null)
  const form = useContext(EditableContext)
  useEffect(() => {
    if (editing) {
      inputRef.current.focus()
    }
  }, [editing])
  const toggleEdit = (values) => {
    if (editing || record[dataIndex]) {
      setEditing(!editing)
    }
    const data = (values) ? values : { [dataIndex]: record[dataIndex] }
    form.setFieldsValue(data)
  }
  const save = async () => {
    try {
      const values = await form.validateFields()
      toggleEdit(values)
      handleSave({
        ...record,
        ...values
      })
    } catch (errInfo) {
      console.log('Save failed:', errInfo)
    }
  }
  let childNode = children
  if (editable) {
    childNode = (editing || (record && !record[dataIndex])) ? (
      <Form.Item
        style={{
          margin: 0
        }}
        name={dataIndex}
        rules={[
          {
            required: isRequiredEditable,
            message: `${title} is required.`
          }
        ]}
      >
        <InputNumber ref={inputRef} onPressEnter={save} onBlur={save} style={{ height: 32 }} />
      </Form.Item>
    ) : (
      <div
        className='editable-cell-value-wrap'
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    )
  }
  return <td {...restProps}>{childNode}</td>
}

const PIForm = () => {
  const navigate = useNavigate()
  const [messageApi, contextHolder] = message.useMessage()
  const { db, companyId, companySelected, user } = useAuth()
  const { piId, type } = useParams()
  const [btnLoading, setBtnLoading] = useState(false)
  const [piType, setPiType] = useState(type)
  const [foodFarmChecked, setFoodFarmChecked] = useState(false)
  const [dataSource, setDataSource] = useState([])
  const [rev, setRev] = useState()
  const [poVisible, setPOVisible] = useState(false)
  const [pnp, setPnp] = useState({})
  const [disabled, setDisabled] = useState(false)

  const [mainForm] = Form.useForm()

  const mode = piId ? 'Submit' : 'Create'

  const consignee = useAsync(async () => {
    const snap = await db.collection('consignee').where('status', '==', 'Active').get()
    return snap.docs.map(doc => ({
      ...doc.data(),
      id: doc.id
    }))
  })

  const { value } = useAsync(async () => {
    if (piType === 'balance') {
      const pnpSnap = await db.collection('plate').get()
      const pnpData = pnpSnap.docs.reduce((acc, doc) => {
        acc[doc.id] = doc.data().value
        return acc
      }, {})
      setPnp(pnpData)
    }

    if (!piId) {
      const year = (new Date()).getFullYear().toString().substr(-2)
      const snapD = await db.collection('pi').where('cid', '==', companyId).orderBy('createdAt', 'desc').limit(1).get()
      const [d] = snapD.docs.map((doc) => doc.data())
      const n = (!d) ? 0 : d.piNo.toString().trim().substr(-3)
      const y = (!d) ? year : d.piNo.toString().trim().substring(2, 4)
      const count = (isNaN(n) || (y !== year)) ? 1 : parseInt(n) + 1
      const piNo = `${companySelected.abbr}${year}${(count).toString().padStart(3, '0')}`
      return {
        piNo: piNo,
        type: piType,
        cid: companyId,
        createdBy: user?.userName ?? 'Admin'
      }
    }
    const snap = await db.collection('pi').doc(piId).get()
    const data = snap.data()
    setPiType(data.type)
    setRev(data?.rev)
    setDataSource(data?.invs ?? [])
    setFoodFarmChecked(data.isfoodfarm)
    setDisabled(['deposited', 'balanced'].indexOf(data?.status) !== -1)
    return {
      ...data,
      date: dbTodayjs(data.date),
      loadingdate: dbTodayjs(data.loadingdate),
      duedate: dbTodayjs(data.duedate)
    }
  }, [piId, piType, companyId, companySelected])

  const handleSubmit = async () => {
    try {
      await mainForm.validateFields()
      setBtnLoading(true)
      const formData = mainForm.getFieldsValue()
      const invs = (piType === 'deposit') ? dataSource.map((o) => {
        // o['totaldepamt'] = o.amount * o.perdeposit / 100
        return pickBy(o)
      }) : dataSource.map((o) => {
        // o['plateusd'] = o.plateunit * pnp['Plate']
        // o['palletusd'] = o.palletunit * pnp['Pallet']
        return pickBy(o)
      })
      if (piType === 'deposit') {
        const c = invs.find((o) => !o.perdeposit)
        if (c) {
          messageApi.open({
            type: 'error',
            content: '% Deposit is required for all Invoices.',
            duration: 5
          })
          setBtnLoading(false)
          return Promise.resolve()
        }
      }
      const rawData = {
        ...formData,
        date: dayjsToDB(formData.date),
        loadingdate: (piType === 'deposit') ? dayjsToDB(formData.loadingdate) : null,
        duedate: dayjsToDB(formData.duedate),
        isfoodfarm: foodFarmChecked,
        invs: invs
      }
      const data = pickBy(rawData)

      let doc = db.collection('pi').doc()
      if (piId) {
        doc = db.collection('pi').doc(piId)
      }
      await doc.set(data, {
        merge: true
      })
      await sleep(4000)
      setBtnLoading(false)
      navigate('/admin/proforma-invoice')
      return Promise.resolve()
    } catch (error) {
      setBtnLoading(false)
      return Promise.resolve(error)
    }
  }

  const handleCancel = () => {
    setPOVisible(false)
  }

  const setInvoiceList = (value) => {
    let newValue = [...value]
    if (piType === 'balance') {
      newValue = newValue.map((o) => {
        const totalbalamt = (o.totalbalamt) ? o.totalbalamt : o.amount + (o?.plateusd ?? 0) + (o?.palletusd ?? 0) - o.totaldepamt
        return {
          ...o,
          totalbalamt: totalbalamt
        }
      })
    }
    setDataSource(newValue)
  }

  const handleSave = (row) => {
    const newData = [...dataSource]
    const index = newData.findIndex((item) => row.key === item.key)
    const item = newData[index]
    if (row.perdeposit && (row.perdeposit !== item.perdeposit)) {
      row.totaldepamt = row.amount * row.perdeposit / 100
    }
    if ((isNumber(row.plateunit) && (row.plateunit !== item.plateunit)) || (isNumber(row.palletunit) && (row.palletunit !== item.palletunit))) {
      row.totalbalamt = row.amount + ((row?.plateunit ?? 0) * pnp['Plate']) + ((row?.palletunit ?? 0) * pnp['Pallet']) - row.totaldepamt
      row.plateusd = (row?.plateunit ?? 0) * pnp['Plate']
      row.palletusd = (row?.palletunit ?? 0) * pnp['Pallet']
    }
    newData.splice(index, 1, {
      ...item,
      ...row
    })
    setDataSource(newData)
  }

  const handleDelete = (row) => {
    const newData = dataSource.filter((o) => o.key !== row.key)
    setDataSource(newData)
  }

  const handleChangeValue = (col, val, row) => {
    const newData = [...dataSource]
    const index = newData.findIndex((item) => row.key === item.key)
    const item = newData[index]
    item[col] = val
    newData.splice(index, 1, {
      ...item,
      ...row
    })
    setDataSource(newData)
  }

  const normalColumns = [
    {
      title: 'INV No.',
      dataIndex: 'invNo',
      key: 'invNo'
    },
    {
      title: 'PO No.',
      dataIndex: 'poNo',
      key: 'poNo'
    },
    {
      title: 'Item Code',
      dataIndex: 'itemCode',
      key: 'itemCode'
    },
    {
      title: 'Item Description',
      dataIndex: 'productRef',
      key: 'productRef'
    },
    {
      title: 'Packing Size',
      dataIndex: 'weight',
      key: 'weight',
      align: 'right'
    },
    {
      title: 'Price Bags',
      dataIndex: 'price',
      key: 'price',
      align: 'right'
    },
    {
      title: 'CTNS',
      dataIndex: 'confirmCTN',
      key: 'confirmCTN',
      align: 'right'
    },
    {
      title: 'Total Bags',
      dataIndex: 'confirmBAG',
      key: 'confirmBAG',
      align: 'right'
    },
    {
      title: 'Total PO Amount (USD)',
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      render: (data, _) => (!isNaN(data)) ? new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(data) : ''
    }
  ]

  const depositColumns = [
    {
      title: '% Deposit',
      dataIndex: 'perdeposit',
      key: 'perdeposit',
      fixed: 'right',
      align: 'right',
      width: 100,
      editable: true,
      isRequiredEditable: true
    },
    {
      title: 'Total Amount (USD)',
      dataIndex: 'totaldepamt',
      key: 'totaldepamt',
      fixed: 'right',
      align: 'right',
      width: 150,
      render: (data, _) => (!isNaN(data)) ? new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(data) : ''
    },
    {
      title: 'Operation',
      dataIndex: 'operation',
      width: 100,
      fixed: 'right',
      render: (_, row) =>
        <div style={{ textAlign: 'center' }}>
          <Button style={{ backgroundColor: '#EA3939', color: '#FFFFFF', width: '32px', height: '30px', borderRadius: '4px' }} size='small' onClick={() => handleDelete(row)} disabled={disabled}><DeleteOutlined /></Button>
        </div>
    }
  ]

  const balanceColumns = [
    {
      title: 'Plate (Unit)',
      dataIndex: 'plateunit',
      key: 'plateunit',
      fixed: 'right',
      align: 'right',
      width: 100,
      editable: true,
      isRequiredEditable: false
    },
    {
      title: 'Plate (USD)',
      dataIndex: 'plateusd',
      key: 'plateusd',
      fixed: 'right',
      align: 'right',
      width: 100,
      render: (data, _) => (!isNaN(data)) ? new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(data) : ''
    },
    {
      title: 'Pallets (Unit)',
      dataIndex: 'palletunit',
      key: 'palletunit',
      fixed: 'right',
      align: 'right',
      width: 100,
      editable: true,
      isRequiredEditable: false
    },
    {
      title: 'Pallets (USD)',
      dataIndex: 'palletusd',
      key: 'palletusd',
      fixed: 'right',
      align: 'right',
      width: 100,
      render: (data, _) => (!isNaN(data)) ? new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(data) : ''
    },
    {
      title: 'Deposit Paid (USD)',
      dataIndex: 'totaldepamt',
      key: 'totaldepamt',
      fixed: 'right',
      align: 'right',
      width: 100,
    },
    {
      title: 'Total Balance Amount (USD)',
      dataIndex: 'totalbalamt',
      key: 'totalbalamt',
      fixed: 'right',
      align: 'right',
      width: 100,
      editable: true,
      isRequiredEditable: false,
      render: (data, _) => (!isNaN(data)) ? new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(data) : ''
    },
    {
      title: 'Operation',
      dataIndex: 'operation',
      width: 175,
      fixed: 'right',
      render: (_, row) =>
        <div style={{ textAlign: 'center' }}>
          <Checkbox checked={row.cn} onChange={(e) => handleChangeValue('cn', e.target.checked, row)}>C/N</Checkbox>
          <Button style={{ backgroundColor: '#EA3939', color: '#FFFFFF', width: '32px', height: '30px', borderRadius: '4px' }} size='small' onClick={() => handleDelete(row)} disabled={disabled}><DeleteOutlined /></Button>
        </div>
    }
  ]

  const defaultColumns = (piType === 'deposit') ? [...normalColumns, ...depositColumns] : [...normalColumns, ...balanceColumns]

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell
    }
  }
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        isRequiredEditable: col.isRequiredEditable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave
      })
    }
  })

  if (!value) return <Skeleton />
  return (
    <Card
      title='Proforma Invoice'
      headStyle={{ fontWeight: 'bold', fontSize: '20px', lineHeight: '23px', backgroundColor: '#CFE2FF', color: '#154D9F' }}
      bodyStyle={{ boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)', borderRadius: '0 0 10px 10px' }}
    >
      {contextHolder}
      <Form
        layout='vertical'
        initialValues={value}
        form={mainForm}
        name='mainForm'
      >
        <Form.Item name='type' hidden={true}>
          <Input />
        </Form.Item>
        <Form.Item name='cid' hidden={true}>
          <Input />
        </Form.Item>
        <Form.Item name='createdBy' hidden={true}>
          <Input />
        </Form.Item>
        <Row>
          <Col span={3}></Col>
          <Col span={18}>
            <Row gutter={[0, 24]}>
              <div className='content-title'>Proforma Invoice Information ({piTypeObj[piType]})</div>
            </Row>
            <Row gutter={[32, 0]}>
              <Col span={12}>
                <Form.Item name='piNo' label='Proforma Invoice No.' rules={[{ required: true, message: 'Please input Proforma Invoice No.!' }]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name='date' label='Date' rules={[{ required: true, message: 'Please input Date!' }]}>
                  <DatePicker className='date-picker-input' />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[32, 0]}>
              <Col span={12}>
                {(piType === 'deposit') ? (
                  <Form.Item name='loadingdate' label='Loading Plan' rules={[{ required: true, message: 'Please input Loading Plan!' }]}>
                    <DatePicker className='date-picker-input' />
                  </Form.Item>
                ) : (
                  <Form.Item name='creditdays' label='Credit (Days)' rules={[{ required: true, message: 'Please input Credit!' }]}>
                    <InputNumber min={0} />
                  </Form.Item>
                )}
              </Col>
              <Col span={12}>
                <Form.Item name='duedate' label='Due Date' rules={[{ required: true, message: 'Please input Due Date!' }]}>
                  <DatePicker className='date-picker-input' />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[32, 0]}>
              <Col span={12}>
                <Form.Item name='consignee' label='Consignee' rules={[{ required: true, message: 'Please input Consignee!' }]}>
                  <Select
                    placeholder='Select Consignee'>
                    {((consignee.value || []).map((o) => (
                      <Select.Option key={o.id} value={o.id}>{o.consigName}</Select.Option>
                    )))
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name='remark' label={(piType === 'deposit') ? 'Remark (Deposit)' : 'Remark (Balance)'}>
                  <Input.TextArea autoSize={true} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[32, 0]}>
              <Col span={12}>
                <Form.Item name='isfoodfarm' label='Food Farm'>
                  <Checkbox checked={foodFarmChecked} onChange={(e) => setFoodFarmChecked(e.target.checked)}>Export As Food Farm</Checkbox>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name='rev' label='Revision'>
                  <InputNumber min={0} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={3}></Col>
        </Row>
      </Form>
      <Row>
        <Col span={3}></Col>
        <Col span={18}>
          <Row gutter={[0, 24]}>
            <Col span={12}>
              <div className='content-title'>Invoice List</div>
            </Col>
            <Col span={12}>
              <Button style={{ float: 'right' }} onClick={() => setPOVisible(true)} type='primary' disabled={disabled}>Add Invoice</Button>
            </Col>
          </Row>
        </Col>
        <Col span={3}></Col>
      </Row>
      <Table
        bordered
        rowKey='key'
        style={{ overflowX: 'auto', margin: '16px 0' }}
        components={components}
        rowClassName={() => 'editable-row'}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />
      <Row>
        <Col span={3}></Col>
        <Col span={18}>
          Current Revision: {rev || ''}
        </Col>
        <Col span={3}></Col>
      </Row>
      <Row justify='center' style={{ marginTop: '20px' }}>
        <Button style={{ margin: '20px 10px 0', width: '300px', backgroundColor: '#67C23A' }} type='primary' size='large' onClick={() => handleSubmit()} loading={btnLoading} disabled={disabled}>{mode} Invoice</Button>
        {((rev || 0) > 1) && (
          <Button style={{ margin: '20px 10px 0', backgroundColor: '#B4B4B4', color: '#FFF' }} type='text' size='large' icon={<FontAwesomeIcon icon={faClock} className='fa-history-icon' />} onClick={() => navigate(`/admin/proforma-invoice/${piId}/history`)}></Button>
        )}
      </Row>
      <POModal type={piType} list={dataSource} piId={piId} visible={poVisible} setInvoiceList={setInvoiceList} onCancel={handleCancel} />
    </Card>
  )
}

export default PIForm

import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAsync } from 'react-use'

import { Button, Card, Table, Form, Row, Col, Input, Dropdown, Divider, Pagination } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faDownload } from '@fortawesome/free-solid-svg-icons'

import dayjs from 'dayjs'
import { mkConfig, generateCsv, download } from 'export-to-csv'

import { useAuth } from '../../contexts/AuthContextProvider'
import StatusModal from './Components/StatusModal'
import DocumentPdf from './Components/DocumentPdf'

const statusObj = {
  deposit: { text: 'Deposit', color: '#5089FF' },
  deposited: { text: 'Deposit', color: '#67C23A' },
  balance: { text: 'Balance', color: '#5089FF' },
  balanced: { text: 'Balance', color: '#67C23A' }
}

const fixedDecimal = (value, dec = 2) => {
  return (!isNaN(value)) ? parseFloat(value.toFixed(dec)) : ''
}

const List = () => {
  const navigate = useNavigate()
  const { db, companyId } = useAuth()

  const [initValue, setInitValue] = useState([])
  const [value, setValue] = useState([])
  const [searchPI, setsearchPI] = useState()
  const [statusVisible, setStatusVisible] = useState(false)
  const [piModal, setPIModal] = useState({})
  const [count, setCount] = useState(0)
  const [total, setTotal] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(1000)
  const [btnLoading, setBtnLoading] = useState(false)

  useAsync(async () => {
    const consigneeSnap = await db.collection('consignee').where('status', '==', 'Active').get()
    const consigneeObj = consigneeSnap.docs.reduce((acc, o) => {
      acc[o.id] = o.data()
      return acc
    }, {})

    const snap = await db.collection('pi').where('cid', '==', companyId).orderBy('createdAt', 'desc').limit(1000).get()
    let result = snap.docs.map(doc => {
      const data = doc.data()
      return {
        ...data,
        id: doc.id,
        consignee: consigneeObj[data.consignee]['consigName'],
        total: data.invs.reduce((acc, o) => acc + ((data.type === 'deposit' && o?.totaldepamt) ? o.totaldepamt : (data.type === 'balance' && o?.totalbalamt) ? o.totalbalamt : 0), 0)
      }
    })
    result = result.sort((x, y) => (x?.piNo ?? '').localeCompare(y?.piNo ?? ''))
    setInitValue(result)
  }, [companyId, count])

  useEffect(() => {
    const result = initValue.reduce((acc, o) => {
      return (
        (!searchPI) ||
        (o?.invs ?? []).find((p) => (
          (((p?.invNo ?? '').toLowerCase()).indexOf(searchPI.toLowerCase()) !== -1) ||
          (((p?.poNo ?? '').toLowerCase()).indexOf(searchPI.toLowerCase()) !== -1)
        ))
      ) ? [...acc, o] : acc
    }, [])
    const start = (currentPage - 1) * pageSize
    const end = start + pageSize
    const list = (pageSize > 0) ? result.filter((o, index) => index >= start && index < end) : result
    setValue(list)
    setTotal(result.length)
  }, [initValue, searchPI, currentPage, pageSize])

  const handleSearchPI = (q) => {
    setsearchPI(q)
  }

  const changeStatus = (v) => {
    setPIModal(v)
    setStatusVisible(true)
  }

  const handleCancel = () => {
    setStatusVisible(false)
  }

  const handleUpdate = () => {
    setCount(count + 1)
  }

  const onChange = (page, pageSize) => {
    const no = (page === 0) ? 1 : page
    setCurrentPage(no)
    setPageSize(pageSize)
  }

  const expandedRowRender = (data) => {
    const normalColumns = [
      {
        title: 'INV No.',
        dataIndex: 'invNo',
        key: 'invNo',
      },
      {
        title: 'PO No.',
        dataIndex: 'poNo',
        key: 'poNo',
      },
      {
        title: 'Item Description',
        dataIndex: 'productRef',
        key: 'productRef'
      },
      {
        title: 'Total PO Amount (USD)',
        dataIndex: 'amount',
        key: 'amount',
        align: 'right',
        render: (data, _) => (!isNaN(data)) ? new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(data) : ''
      }
    ]

    const depositColumns = [
      {
        title: '% Deposit',
        dataIndex: 'perdeposit',
        key: 'perdeposit',
        align: 'right',
      },
      {
        title: 'Total Amount (USD)',
        dataIndex: 'totaldepamt',
        key: 'totaldepamt',
        align: 'right',
        render: (data, _) => (!isNaN(data)) ? new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(data) : ''
      }
    ]

    const balanceColumns = [
      {
        title: 'Plate (Unit)',
        dataIndex: 'plateunit',
        key: 'plateunit',
        align: 'right',
      },
      {
        title: 'Plate (USD)',
        dataIndex: 'plateusd',
        key: 'plateusd',
        align: 'right'
      },
      {
        title: 'Pallets (Unit)',
        dataIndex: 'palletunit',
        key: 'palletunit',
        align: 'right'
      },
      {
        title: 'Pallets (USD)',
        dataIndex: 'palletusd',
        key: 'palletusd',
        align: 'right'
      },
      {
        title: 'Deposit Paid (USD)',
        dataIndex: 'totaldepamt',
        key: 'totaldepamt',
        align: 'right',
        render: (data, _) => (!isNaN(data)) ? new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(data) : ''
      },
      {
        title: 'Total Balance Amount (USD)',
        dataIndex: 'totalbalamt',
        key: 'totalbalamt',
        align: 'right',
        render: (data, _) => (!isNaN(data)) ? new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(data) : ''
      }
    ]

    const defaultColumns = (data.type === 'deposit') ? [...normalColumns, ...depositColumns] : [...normalColumns, ...balanceColumns]

    return <Table columns={defaultColumns} dataSource={data.invs} pagination={false} />
  }

  const columns = [
    {
      title: 'PI No.',
      dataIndex: 'piNo',
      defaultSortOrder: 'descend',
      sorter: {
        compare: (a, b) => b.piNo.localeCompare(a.piNo)
      }
    },
    {
      title: 'Consignee',
      dataIndex: 'consignee'
    },
    {
      title: 'Credit Days',
      dataIndex: 'creditdays'
    },
    {
      title: 'Due Date',
      dataIndex: 'duedate',
      render: text => (
        <>
          {(text && text.seconds) ? dayjs(text.seconds * 1000).format('DD/MM/YYYY') : null}
        </>
      )
    },
    {
      title: 'Grand Total (USD)',
      dataIndex: 'total',
      align: 'right',
      render: (data, _) => (!isNaN(data)) ? new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(data) : ''
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 100,
      render: (data, row) => <Button type='link' style={{ color: 'black' }} onClick={() => changeStatus(row)}><span style={{ color: statusObj[data] && statusObj[data]['color'] }}>&#x25cf;&#160;&#160;</span>{statusObj[data] && statusObj[data]['text']}</Button>
    },
    {
      title: 'Operation',
      dataIndex: 'operation',
      width: 150,
      render: (_, row) =>
        <div style={{ textAlign: 'center' }}>
          <DocumentPdf record={row}></DocumentPdf>
          <Button type='text' size='middle' style={{ backgroundColor: '#154D9F', color: '#FFF', marginLeft: 8 }} icon={<FontAwesomeIcon icon={faSearch} />} onClick={() => navigate(`/admin/proforma-invoice/${row.id}/edit`)}></Button>
        </div>
    }
  ]

  const exportTable = () => {
    const csvConfig = mkConfig({
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename: `Financial_List_${dayjs().format('YYYY_MM_DD')}`
    })
    setBtnLoading(true)
    const data = initValue.reduce((acc1, o) => {
      return [...acc1, ...(o?.invs ?? []).reduce((acc2, p) => {
        return [...acc2, {
          'INV No.': p?.invNo ?? '',
          'PO No.': p?.poNo ?? '',
          'Item Description': p?.productRef ?? '',
          'Total PO Amount (USD)': fixedDecimal(p.amount),
          'Status': o?.status ? statusObj[o.status]['text'] : '',
          'PI No.': o?.piNo ?? '',
          'Consignee': o?.consignee ?? '',
          '% Deposit': p?.perdeposit ?? '',
          'Credit Days': o?.creditdays ?? '',
          'Due Date': (o.duedate) ? dayjs(o.duedate.seconds * 1000).format('YYYY/MM/DD') : '',
          'Grand Total (USD)': fixedDecimal(o.total),
          'Total Deposit Amount (USD)': fixedDecimal(p.totaldepamt),
          'Plate (Unit)': p?.plateunit ?? '',
          'Plate (USD)': p?.plateusd ?? '',
          'Pallets (Unit)': p?.palletunit ?? '',
          'Pallets (USD)': p?.palletusd ?? '',
          'Total Balance Amount (USD)': fixedDecimal(p.totalbalamt),
        }]
      }, [])]
    }, [])
    if (data.length) {
      const csv = generateCsv(csvConfig)(data)
      download(csvConfig)(csv)
    }
    setBtnLoading(false)
  }

  return (
    <Card
      title='Proforma Invoice'
      headStyle={{ fontWeight: 'bold', fontSize: '20px', lineHeight: '23px', backgroundColor: '#CFE2FF', color: '#154D9F' }}
      bodyStyle={{ boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)', borderRadius: '0 0 10px 10px' }}
    >
      <Form>
        <Row style={{ textAlign: 'right', marginBottom: 16 }}>
          <Col span={6}>
            <Input.Search placeholder='search by INV No. or PO No.' onSearch={(q) => handleSearchPI(q)} />
          </Col>
          <Col span={18}>
            <Dropdown
              menu={{
                onClick: (e) => {
                  navigate(`/admin/proforma-invoice/create/${e.key}`)
                },
                items: [
                  { key: 'deposit', label: 'Deposit' },
                  { key: 'balance', label: 'Balance' }
                ]
              }}
              placement='bottomRight'
            >
              <Button type='primary'>Create PI.</Button>
            </Dropdown>
          </Col>
        </Row>
      </Form>
      <Table
        bordered
        rowKey='id'
        style={{ overflowX: 'auto' }}
        columns={columns}
        expandable={{
          expandedRowRender: (record) => expandedRowRender(record),
          defaultExpandedRowKeys: ['0'],
        }}
        dataSource={value}
        pagination={false}
      />
      <Divider style={{ margin: '16px 0', borderTop: 'none' }} />
      <div style={{ float: 'right' }}>
        <Pagination
          showSizeChanger
          onChange={onChange}
          pageSizeOptions={[5, 10, 20, 50, 100, 1000]}
          defaultPageSize={1000}
          total={total} />
      </div>
      <div style={{ float: 'left' }}>
        <Button type="primary" size="middle" icon={<FontAwesomeIcon icon={faDownload} className="fa-icon-button" />} loading={btnLoading} onClick={() => exportTable()}>Export</Button>
      </div>
      <StatusModal piId={piModal.id} visible={statusVisible} onCancel={handleCancel} onUpdate={handleUpdate} />
    </Card>
  )
}

export default List

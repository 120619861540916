import React, { useState } from 'react'
import { useAsync } from 'react-use'

import { Form as AntdForm, Button, Row, Col, Modal } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import TableTransfer from '../../../components/TableTransfer'
import { useAuth } from '../../../contexts/AuthContextProvider'
import { dayjsToDB } from '../../../utils/tools'

const POModal = ({ type, list, piId, visible, setInvoiceList, onCancel }) => {
  const { db, companyId } = useAuth()

  const [targets, setTargets] = useState([])

  const [poForm] = AntdForm.useForm()

  const { value } = useAsync(async () => {
    const listObj = list.reduce((acc, o) => {
      acc[o.key] = o
      return acc
    }, {})
    const ref = (type === 'deposit') ? db.collection('po').where('cid', '==', companyId).where('piStatus', 'in', ['no', 'deposit']) : db.collection('po').where('cid', '==', companyId).where('piStatus', 'in', ['no', 'deposit', 'deposited', 'balance'])
    const snap = await ref.get()
    const data = snap.docs.reduce((acc1, o) => {
      const d = o.data()
      const cond = (type === 'deposit' && d.piStatus === 'no') ? true : (type === 'deposit' && d.piDeposit === piId) ? true : (type === 'balance' && ['no', 'deposit', 'deposited'].indexOf(d.piStatus) !== -1) ? true : (type === 'balance' && d.piBalance === piId) ? true : false
      return (!cond) ? [...acc1] : [...acc1, ...((d?.products ?? []).reduce((acc2, p) => {
        const key = `${d.invNo}${p.itemCode}`
        return [...acc2, {
          ...(listObj[key] || {}),
          poid: o.id,
          key: key,
          invNo: d.invNo,
          poNo: d.poNo,
          itemCode: p.itemCode,
          productRef: p.productRef,
          bl: d.hbl,
          blDate: dayjsToDB(d?.etd),
          weight: p.weight,
          price: p.price,
          bagPerCarton: p.bagPerCarton,
          confirmCTN: p.confirmCTN,
          confirmBAG: p.confirmBAG,
          amount: p.amount,
          totaldepamt: (d?.piData && d?.piData[key]) ?? 0,
          cn: false
        }]
      }, []))]
    }, [])
    const l = list.map((o) => o.key)
    setTargets(l)
    return data
  }, [companyId, list])

  const handleSubmit = () => {
    const t = value.filter((o) => targets.indexOf(o.key) !== -1)
    setInvoiceList(t)
    onCancel()
  }

  const columns = [
    {
      title: 'INV No.',
      dataIndex: 'invNo',
      width: 110
    },
    {
      title: 'PO No.',
      dataIndex: 'poNo',
      width: 110
    },
    {
      title: 'Item Description',
      dataIndex: 'productRef'
    }
  ]

  return (
    <Modal
      forceRender
      open={visible}
      title={
        <div style={{ padding: '16px 24px', fontWeight: 'bold', fontSize: '20px', lineHeight: '23px', backgroundColor: '#CFE2FF', color: '#154D9F', borderRadius: '10px 10px 0px 0px' }}>
          Create Proforma Invoice
        </div>
      }
      width={'75%'}
      closable={false}
      maskClosable={false}
      footer={
        <Row justify="center" gutter={[8, 0]}>
          <Col span={8}>
            <Button type="primary" size="large" htmlType="submit" block onClick={handleSubmit} style={{ borderRadius: '5px' }}>Submit</Button>
          </Col>
          <Col span={1}>
            <Button type="danger" size="large" icon={<FontAwesomeIcon icon={faTimes} />} onClick={onCancel} style={{ borderRadius: '5px' }}></Button>
          </Col>
        </Row>
      }
    >
      <AntdForm form={poForm} name="poForm" layout="vertical" initialValues={{}}>
        <AntdForm.Item name='po' >
          <TableTransfer
            titles={['Invoice List', 'Selected List']}
            locale={{ itemUnit: 'Item', itemsUnit: 'Items' }}
            dataSource={value}
            targetKeys={targets}
            showSearch={true}
            listStyle={{
              width: '100%',
              minHeight: 300,
            }}
            leftColumns={columns}
            rightColumns={columns}
            onChange={(v) => {
              setTargets(v)
            }}
            filterOption={(inputValue, item) => (
              (((item.invNo || '').toLowerCase()).indexOf(inputValue.toLowerCase()) !== -1) ||
              (((item.poNo || '').toLowerCase()).indexOf(inputValue.toLowerCase()) !== -1)
            )}
          />
        </AntdForm.Item>
      </AntdForm>
    </Modal>
  )
}

export default POModal

import React, { useEffect } from 'react'

import { Form as AntdForm, Button, Input, Select, Row, Col, Modal, Space } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faTimes } from '@fortawesome/free-solid-svg-icons'

import dayjs from 'dayjs'
import pdfMake from 'pdfmake/build/pdfmake'

import { DatePicker } from '../../../components/DateTime'

import { useAuth } from '../../../contexts/AuthContextProvider'

import pdfFonts from '../../../assets/pdfMakeFonts/vfs_fonts'
pdfMake.vfs = pdfFonts.pdfMake.vfs
pdfMake.fonts = {
  THSarabunNew: {
    normal: 'THSarabunNew.ttf',
    bold: 'THSarabunNew-Bold.ttf',
    italics: 'THSarabunNew-Italic.ttf',
    bolditalics: 'THSarabunNew-BoldItalic.ttf'
  },
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-MediumItalic.ttf'
  }
}

const BookingRequestPdf = ({ poId, data, products, consignee, countries, visible, disabled, onCancel }) => {
  const { db, role } = useAuth()

  const [bookingRequestForm] = AntdForm.useForm()

  const showDataOnly = (['Financial', 'Purchasing'].indexOf(role) === -1) || disabled

  const getData = (obj1, obj2) => {
    return Object.keys(obj1).reduce((acc, o) => {
      acc[o] = (obj2[o] && obj2[o] !== obj1[o]) ? obj2[o] : obj1[o]
      return acc
    }, {})
  }

  useEffect(() => {
    const obj = getData(data, bookingRequestForm.getFieldsValue())
    bookingRequestForm.setFieldsValue({
      ...obj,
      finalDestination: data.country || '',
      portOfDischarge: data.port || '',
      commodity: products.map((o) => o.productRef || '').join('\n '),
      hsCode: products.map((o) => o.hsCode || '').join('\n '),
      nw: `${products.reduce((a, b) => a + ((+b.quantityBAG) * (+b.weight)), 0) / 1000} KGS / CONTAINER`
    })
  }, [data, products, bookingRequestForm])

  const genPdf = async () => {
    if (!visible) {
      return
    }
    const poData = bookingRequestForm.getFieldsValue()
    const consigneeObj = consignee.find((o) => o.id === poData.consigneeId)
    const tableData = {
      'PO.': poData.poNo || '',
      'CONSIGNEE': (consigneeObj) ? consigneeObj.consigName : '',
      'SHIPPER': 'FOOD FARM CO.,LTD',
      'COMMODITY': poData.commodity || '',
      'HS.CODE.': poData.hsCode || '',
      'VOLUME': poData.volume || '',
      'N.W.': poData.nw || '',
      'PORT OF LOAD': poData.portOfLoading || '',
      'DESTINATION': `${poData.portOfDischarge || ''}, ${poData.finalDestination || ''}`,
      'CY DATE': poData.cyDate ? dayjs(poData.cyDate).format('D-MMM-YY') : '',
      'RETURN DATE': poData.rtnDate ? dayjs(poData.rtnDate).format('D-MMM-YY') : '',
      'ETD': poData.etd ? dayjs(poData.etd).format('D-MMM-YY') : ''
    }
    const docObj = {
      pageOrientation: 'portrait',
      content: [
        {
          style: 'tableStyle',
          table: {
            widths: [125, '*'],
            body: [...Object.keys(tableData).map((o) => ([
              {
                text: o,
                margin: [10, 4],
                style: 'subjectStyle'
              },
              {
                text: tableData[o],
                margin: [10, 4],
                style: (o === 'PO.') ? 'highlightStyle' : (['DESTINATION', 'ETD'].indexOf(o) !== -1) ? 'subjectStyle' : null
              }
            ]))
            ]
          },
          layout: {
            fillColor(rowIndex, node, columnIndex) {
              return (rowIndex === 0 && columnIndex === 0) ? '#f0ad4e' : (rowIndex === 0 && columnIndex === 1) ? '#faebcc' : null
            }
          }
        }
      ],
      styles: {
        tableStyle: {
          margin: [16, 16]
        },
        subjectStyle: {
          bold: true
        },
        highlightStyle: {
          bold: true,
          color: 'red'
        }
      },
      defaultStyle: {
        fontSize: 13,
        font: 'Roboto'
        // fontSize: 18,
        // font: 'THSarabunNew'
      }
    }

    await pdfMake.createPdf(docObj).open()
    await onCancel()

    if (['Financial', 'Purchasing'].indexOf(role) !== -1) {
      await db.collection('po').doc(poId).set({
        shipperName: poData.shipperName,
        volume: poData.volume,
        portOfLoading: poData.portOfLoading,
        finalDestination: poData.finalDestination,
        portOfDischarge: poData.portOfDischarge
      }, {
        merge: true
      })
    }
    bookingRequestForm.submit()
  }

  return (
    <Modal
      forceRender
      open={visible}
      title={
        <div style={{ padding: '16px 24px', fontWeight: 'bold', fontSize: '20px', lineHeight: '23px', backgroundColor: '#CFE2FF', color: '#154D9F', borderRadius: '10px 10px 0px 0px' }}>
          Export Booking Request Information
        </div>
      }
      width={'50%'}
      closable={false}
      maskClosable={false}
      footer={
        <Row justify="center">
          <Space align="center">
            <Col>
              <Button type="primary" size="large" icon={<FontAwesomeIcon icon={faDownload} className="fa-icon-button" />} onClick={genPdf} style={{ borderRadius: '5px' }}>Export Booking Request</Button>
            </Col>
            <Col>
              <Button type="danger" size="large" icon={<FontAwesomeIcon icon={faTimes} />} onClick={onCancel} style={{ borderRadius: '5px' }}></Button>
            </Col>
          </Space>
        </Row>
      }
    >
      <AntdForm form={bookingRequestForm} name="bookingRequestForm" layout="vertical" initialValues={{}}>
        <AntdForm.Item name="portOfDischarge" hidden={true}>
          <Input />
        </AntdForm.Item>
        <Row>
          <Col span={2}></Col>
          <Col span={20}>
            <Row gutter={[32, 0]}>
              <Col span={6}>
                <strong>PO.</strong>
              </Col>
              <Col span={18}>
                <AntdForm.Item name="poNo" initialValue="">
                  <Input disabled={true} style={{ height: '40px', borderRadius: '8px' }} />
                </AntdForm.Item>
              </Col>
            </Row>
            <Row gutter={[32, 0]}>
              <Col span={6}>
                <strong>CONSIGNEE</strong>
              </Col>
              <Col span={18}>
                <AntdForm.Item name="consigneeId" initialValue="">
                  <Select
                    disabled={showDataOnly}
                    placeholder="Select Consignee">
                    {((consignee || []).map((o) => (
                      <Select.Option key={o.id} value={o.id}>{o.consigName}</Select.Option>
                    )))
                    }
                  </Select>
                </AntdForm.Item>
              </Col>
            </Row>
            <Row gutter={[32, 0]}>
              <Col span={6}>
                <strong>SHIPPER</strong>
              </Col>
              <Col span={18}>
                <AntdForm.Item name="shipperName" initialValue="FOOD FARM CO.,LTD">
                  <Input disabled={showDataOnly} style={{ height: '40px', borderRadius: '8px' }} />
                </AntdForm.Item>
              </Col>
            </Row>
            <Row gutter={[32, 0]}>
              <Col span={6}>
                <strong>COMMODITY</strong>
              </Col>
              <Col span={18}>
                <AntdForm.Item name="commodity" initialValue="">
                  <Input disabled={showDataOnly} style={{ height: '40px', borderRadius: '8px' }} />
                </AntdForm.Item>
              </Col>
            </Row>
            <Row gutter={[32, 0]}>
              <Col span={6}>
                <strong>HS.CODE.</strong>
              </Col>
              <Col span={18}>
                <AntdForm.Item name="hsCode" initialValue="">
                  <Input disabled={showDataOnly} style={{ height: '40px', borderRadius: '8px' }} />
                </AntdForm.Item>
              </Col>
            </Row>
            <Row gutter={[32, 0]}>
              <Col span={6}>
                <strong>VOLUME</strong>
              </Col>
              <Col span={18}>
                <AntdForm.Item name="volume" initialValue="">
                  <Input disabled={showDataOnly} style={{ height: '40px', borderRadius: '8px' }} />
                </AntdForm.Item>
              </Col>
            </Row>
            <Row gutter={[32, 0]}>
              <Col span={6}>
                <strong>N.W.</strong>
              </Col>
              <Col span={18}>
                <AntdForm.Item name="nw" initialValue="">
                  <Input disabled={showDataOnly} style={{ height: '40px', borderRadius: '8px' }} />
                </AntdForm.Item>
              </Col>
            </Row>
            <Row gutter={[32, 0]}>
              <Col span={6}>
                <strong>PORT OF LOAD</strong>
              </Col>
              <Col span={18}>
                <AntdForm.Item name="portOfLoading" initialValue="">
                  <Select
                    disabled={showDataOnly}
                    placeholder="Select Port of Loading"
                    style={{ height: '40px', borderRadius: '8px' }}>
                    <Select.Option key={0} value="Laem Chabang, Thailand">Laem Chabang, Thailand</Select.Option>
                    <Select.Option key={1} value="Bangkok, Thailand">Bangkok, Thailand</Select.Option>
                  </Select>
                </AntdForm.Item>
              </Col>
            </Row>
            <Row gutter={[32, 0]}>
              <Col span={6}>
                <strong>DESTINATION</strong>
              </Col>
              <Col span={18}>
                <AntdForm.Item name="finalDestination" initialValue="">
                  <Select
                    disabled={showDataOnly}
                    placeholder="Select Country"
                    style={{ height: '40px', borderRadius: '8px' }}>
                    {((countries || []).map((o) => (
                      <Select.Option key={o.country} value={o.country}>{o.country}</Select.Option>
                    )))
                    }
                  </Select>
                </AntdForm.Item>
              </Col>
            </Row>
            <Row gutter={[32, 0]}>
              <Col span={6}>
                <strong>CY DATE</strong>
              </Col>
              <Col span={18}>
                <AntdForm.Item name="cyDate" initialValue="">
                  <DatePicker disabled={true} className="date-picker-input" style={{ width: '100%', height: '40px', borderRadius: '8px' }} />
                </AntdForm.Item>
              </Col>
            </Row>
            <Row gutter={[32, 0]}>
              <Col span={6}>
                <strong>RETURN DATE</strong>
              </Col>
              <Col span={18}>
                <AntdForm.Item name="rtnDate" initialValue="">
                  <DatePicker disabled={true} className="date-picker-input" style={{ width: '100%', height: '40px', borderRadius: '8px' }} />
                </AntdForm.Item>
              </Col>
            </Row>
            <Row gutter={[32, 0]}>
              <Col span={6}>
                <strong>ETD</strong>
              </Col>
              <Col span={18}>
                <AntdForm.Item name="etd" initialValue="">
                  <DatePicker disabled={true} className="date-picker-input" style={{ width: '100%', height: '40px', borderRadius: '8px' }} />
                </AntdForm.Item>
              </Col>
            </Row>
          </Col>
          <Col span={2}></Col>
        </Row>
      </AntdForm>
    </Modal>
  )
}

export default BookingRequestPdf

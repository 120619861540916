import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useAsync } from 'react-use'

import { Form as AntdForm, Button, Input, Skeleton, Card, Row, Col, Select, TimePicker, Divider, Upload } from 'antd'
import { InboxOutlined } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faPlus, faDownload } from '@fortawesome/free-solid-svg-icons'

import _ from 'lodash'

import { DatePicker } from '../../../components/DateTime'

import { useAuth } from '../../../contexts/AuthContextProvider'
import { usePurchaseOrderContext } from '../../../contexts/PurchaseOrderContextProvider'
import { dbTodayjs, dayjsToDB } from '../../../utils/tools'
import ProductTable from '../Components/ProductTable'
import ProductModal from '../Components/ProductModal'
import BookingRequestPdf from '../Components/BookingRequestPdf'
import BookingConfirmedPdf from '../Components/BookingConfirmedPdf'

const { Dragger } = Upload

const POForm = () => {
  const navigate = useNavigate()
  const { poId, revId } = useParams()
  const location = useLocation()
  const { storage, db, role, companyId, companySelected } = useAuth()
  const { productId, productData, productAction } = usePurchaseOrderContext()

  const [product, setproduct] = useState()
  const [products, setproducts] = useState([])
  const [productVisible, setProductVisible] = useState(false)
  const [bookingRequestVisible, setBookingRequestVisible] = useState(false)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [countries, setCountries] = useState()
  const [country, setCountry] = useState()
  const [destination, setDestination] = useState()
  const [files, setFiles] = useState([])

  const ref = db.collection('po')
  const [poForm] = AntdForm.useForm()

  const loadingType = useAsync(async () => {
    const snap = await db.collection('loadingType').where('status', '==', 'Active').get()
    return snap.docs.map(doc => ({
      ...doc.data(),
      id: doc.id
    }))
  })

  const container = useAsync(async () => {
    const snap = await db.collection('container').where('status', '==', 'Active').get()
    return snap.docs.map(doc => ({
      ...doc.data(),
      id: doc.id
    }))
  })

  const consignee = useAsync(async () => {
    const snap = await db.collection('consignee').where('status', '==', 'Active').get()
    return snap.docs.map(doc => ({
      ...doc.data(),
      id: doc.id
    }))
  })

  const productList = useAsync(async () => {
    const snap = await db.collection('products').where('cid', '==', companyId).get()
    return snap.docs.map(doc => ({
      ...doc.data(),
      id: doc.id
    }))
  })

  const { value } = useAsync(async () => {
    const countryPortSnap = await db.collection('countryPort').orderBy('country').get()
    const countryPort = countryPortSnap.docs.map(doc => ({
      ...doc.data(),
      id: doc.id
    }))
    setCountries(countryPort)

    if (!poId) {
      const year = (new Date()).getFullYear().toString()
      const snapD = await db.collection('po').where('cid', '==', companyId).orderBy('createdAt', 'desc').limit(1).get()
      const [d] = snapD.docs.map((doc) => doc.data())
      const n = (!d) ? 0 : d.invNo.toString().trim().substr(-3)
      const y = (!d) ? year : d.invNo.toString().trim().slice(0, 4)
      const count = (isNaN(n) || (y !== year)) ? 1 : parseInt(n) + 1
      const invNo = `${year}-${companySelected.abbr}-${(count).toString().padStart(3, '0')}`
      return {
        invNo: invNo
      }
    }
    const snap = (!revId) ? await db.collection('po').doc(poId).get() : await db.collection('poHistory').doc(revId).get()
    const data = snap.data()
    const products = (productId && productId === poId) ? productData : data.products
    setproducts([...products.map((o) => ({
      ...o,
      container: data.container,
      forwarder: data.forwarder,
      remark: data.remark,
      key: o.id
    }))])
    setFiles([
      ...(data.files || [])
    ])
    const countryObj = (countryPort || []).find((o) => o.country === data.country)
    if (countryObj) {
      setCountry({
        ...countryObj,
        ports: (countryObj.ports || []).filter((o) => o.status === 'Active')
      })
    }
    const destinationObj = (countryPort || []).find((o) => o.country === data.finalDestination)
    if (destinationObj) {
      setDestination({
        ...destinationObj,
        ports: (destinationObj.ports || []).filter((o) => o.status === 'Active')
      })
    }
    return {
      ...data,
      id: snap.id,
      deliveryDate: dbTodayjs(data.deliveryDate),
      rtnDate: dbTodayjs(data.rtnDate),
      cyDate: dbTodayjs(data.cyDate),
      receivedDate: dbTodayjs(data.receivedDate),
      invoiceDate: dbTodayjs(data.invoiceDate),
      packingDate: dbTodayjs(data.packingDate),
      etd: dbTodayjs(data.etd),
      date: dbTodayjs(data.date),
      eta: dbTodayjs(data.eta),
      closingDate: dbTodayjs(data.closingDate),
      closingTime: dbTodayjs(data.closingTime)
    }
  }, [poId, companySelected, companyId])

  const mode = !poId ? 'Create' : (location.pathname.indexOf('edit') !== -1) ? 'Edit' : (location.pathname.indexOf('confirm') !== -1) ? 'Confirm' : 'View'

  const uploadShow = (value && value.status && (mode !== 'Confirm') && (
    ['Loaded', 'Shipped'].indexOf(value.status) !== -1
  ))
  const infoAction = (
    !revId &&
    (['Planner', 'Customer'].indexOf(role) === -1) && (
      (mode === 'View') &&
      (value && value.status && ['Loaded', 'Shipped'].indexOf(value.status) === -1)
    )
  )
  const infoDisabled = (
    (['View', 'Confirm'].indexOf(mode) !== -1)
  )
  const productDisabled = (
    (['View'].indexOf(mode) !== -1)
  )
  const rtnShow = (value && value.status && (mode !== 'Confirm') && (
    (['Waiting for Planner'].indexOf(value.status) === -1) ||
    (['Waiting for Planner'].indexOf(value.status) !== -1 && (['Planner', 'Financial', 'Purchasing'].indexOf(role) !== -1))
  ))
  const rtnDisabled = (
    revId ||
    (['Planner', 'Financial', 'Purchasing'].indexOf(role) === -1) ||
    ((['Planner', 'Financial', 'Purchasing'].indexOf(role) !== -1) && value && value.status && (['Waiting for Planner', 'Booking Request', 'Waiting for Booking Confirm', 'Booking Confirmed', 'Warning', 'Problem'].indexOf(value.status) === -1))
  )
  const requestShow = (value && value.status && (mode !== 'Confirm') && (
    (['Waiting for Planner', 'Booking Request'].indexOf(value.status) === -1) ||
    (['Booking Request'].indexOf(value.status) !== -1 && ['Financial', 'Purchasing'].indexOf(role) !== -1)
  ))
  const requestDisabled = (
    revId ||
    (role === 'Customer') ||
    (['Planner'].indexOf(role) !== -1) ||
    (value && value.status && (['Loaded', 'Shipped'].indexOf(value.status) !== -1))
  )
  const confirmedShow = (value && value.status && (mode !== 'Confirm') && (
    (['Waiting for Planner', 'Booking Request', 'Waiting for Booking Confirm'].indexOf(value.status) === -1) ||
    (['Waiting for Booking Confirm'].indexOf(value.status) !== -1 && ['Financial', 'Purchasing'].indexOf(role) !== -1)
  ))
  const confirmedDisabled = (
    revId ||
    (['Planner', 'Customer'].indexOf(role) !== -1) ||
    ((['Admin'].indexOf(role) === -1) && (value && value.status && (['Loaded', 'Shipped'].indexOf(value.status) !== -1)))
  )
  const confirmedAdminDisabled = (
    revId ||
    (['Planner', 'Customer'].indexOf(role) !== -1) ||
    (value && value.status && (['Loaded', 'Shipped'].indexOf(value.status) !== -1))
  )
  const historyDisabled = (
    revId ||
    (['Customer'].indexOf(role) !== -1)
  )
  const loadedShow = (value && value.status &&
    mode !== 'Edit' &&
    ['Financial', 'Purchasing', 'Admin'].indexOf(role) !== -1 &&
    ['Booking Confirmed', 'Warning'].indexOf(value.status) !== -1 &&
    !!value?.bookingRefNo
  )

  const isValidate = (
    role !== 'Planner'
  )

  const handleSubmit = async (formData) => {
    setLoadingSubmit(true)
    try {
      formData = {
        ...formData,
        products: products,
        deliveryDate: dayjsToDB(formData.deliveryDate),
        rtnDate: dayjsToDB(formData.rtnDate),
        cyDate: dayjsToDB(formData.cyDate),
        receivedDate: dayjsToDB(formData.receivedDate),
        invoiceDate: dayjsToDB(formData.invoiceDate),
        packingDate: dayjsToDB(formData.packingDate),
        etd: dayjsToDB(formData.etd),
        date: dayjsToDB(formData.date),
        eta: dayjsToDB(formData.eta),
        closingDate: dayjsToDB(formData.closingDate),
        closingTime: dayjsToDB(formData.closingTime)
      }
      const data = _.pickBy({
        ...formData,
        products: formData.products.map((o) => _.pickBy(o)),
        cid: companyId
      })
      let doc = ref.doc()
      if (poId) {
        doc = ref.doc(poId)
      }
      await doc.set(data, {
        merge: true
      })
      setLoadingSubmit(false)
      if (['Edit'].indexOf(mode) !== -1) {
        navigate(`/admin/purchase-order/${poId}`)
      } else {
        navigate('/admin/purchase-order')
      }
      return Promise.resolve()
    } catch (error) {
      setLoadingSubmit(false)
      return Promise.reject(error)
    }
  }

  const submitWithoutValidate = async (cyDate, etd) => {
    setLoadingSubmit(true)
    try {
      let doc = ref.doc()
      if (poId) {
        doc = ref.doc(poId)
      }
      await doc.set({
        cyDate: dayjsToDB(cyDate),
        etd: dayjsToDB(etd)
      }, {
        merge: true
      })
      setLoadingSubmit(false)
      navigate('/admin/purchase-order')
      return Promise.resolve()
    } catch (error) {
      setLoadingSubmit(false)
      return Promise.reject(error)
    }
  }

  const handleAddProduct = () => {
    setproduct(null)
    setProductVisible(true)
  }

  const handleCancel = () => {
    setProductVisible(false)
    setBookingRequestVisible(false)
  }

  const editProduct = (data) => {
    setproduct(data)
    setProductVisible(true)
  }

  const deleteProduct = (data) => {
    setproducts([
      ...products.filter((o) => o.itemCode !== data.itemCode)
    ])
  }

  const bookingRequestModal = () => {
    setBookingRequestVisible(true)
  }

  const handleConfirm = (id, status) => {
    productAction.setProduct(id, status)
    productAction.updateProduct(products)
    navigate(`/admin/purchase-order/${poId}/load`)
  }

  const onCountryChange = (value) => {
    const data = (countries || []).find((o) => o.country === value)
    if (!data) {
      setCountry()
    }
    setCountry({
      ...data,
      ports: (data.ports || []).filter((o) => o.status === 'Active')
    })
    poForm.setFieldsValue({
      ...poForm.getFieldsValue(),
      port: ''
    })
  }

  const onDestinationChange = (value) => {
    const data = (countries || []).find((o) => o.country === value)
    if (!data) {
      setDestination()
    }
    setDestination({
      ...data,
      ports: (data.ports || []).filter((o) => o.status === 'Active')
    })
    poForm.setFieldsValue({
      ...poForm.getFieldsValue(),
      portOfDischarge: ''
    })
  }

  const customRequestFile = async ({ onError, onSuccess, file }) => {
    try {
      const storageRef = storage.ref('upload').child(`${file.uid}-${file.name}`)
      const task = await storageRef.put(file, {
        contentType: file.type
      })

      const data = {
        uid: file.uid,
        name: file.name,
        type: file.type,
        lastModified: file.lastModified,
        url: `https://firebasestorage.googleapis.com/v0/b/${task.metadata.bucket}/o/${encodeURIComponent(task.metadata.fullPath)}?alt=media`
      }

      const snap = await ref.doc(poId).get()
      const prevData = snap.data()
      await ref.doc(poId).set({
        files: [...(prevData.files || []), data]
      }, {
        merge: true
      })

      setFiles(prevFiles => {
        return [...prevFiles, data]
      })
      onSuccess(null, task)
    } catch (e) {
      onError(e)
    }
  }

  const onRemoveFile = async (file) => {
    try {
      const filterFiles = files.filter((o) => o.uid !== file.uid)
      const storageRef = storage.ref('upload').child(`${file.uid}-${file.name}`)
      await storageRef.delete()

      await ref.doc(poId).set({
        files: filterFiles
      }, {
        merge: true
      })

      setFiles([...filterFiles])
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  if (!value) return <Skeleton />
  return (
    <AntdForm.Provider
      onFormFinish={(name, { values, forms }) => {
        if (name === 'productForm') {
          const p = products.find((o) => o.itemCode === values.itemCode)
          if (p) {
            setproducts([
              ...products.reduce((acc, o, i) => {
                return (o.itemCode === values.itemCode) ? [...acc, {
                  ...values,
                  key: i
                }
                ] : [...acc, o]
              }, [])
            ])
          } else {
            setproducts([
              ...products,
              { ...values, key: products.length - 1 }
            ])
          }
          setProductVisible(false)
        }
        if (name === 'bookingRequestForm') {
          const destinationObj = (countries || []).find((o) => o.country === values.finalDestination)
          if (destinationObj) {
            setDestination({
              ...destinationObj,
              ports: (destinationObj.ports || []).filter((o) => o.status === 'Active')
            })
          }
          forms.poForm.setFieldsValue({
            ...forms.poForm.getFieldsValue(),
            rtnDate: values.rtnDate,
            cyDate: values.cyDate,
            etd: values.etd,
            volume: values.volume,
            portOfLoading: values.portOfLoading,
            shipperName: values.shipperName,
            commodity: values.commodity,
            finalDestination: values.finalDestination,
            portOfDischarge: values.portOfDischarge
          })
        }
      }}
      onFormChange={(formName, { changedFields, forms }) => {
        if (formName === 'poForm' && changedFields.length) {
          forms.bookingRequestForm.setFields(changedFields)
          forms.poForm.setFields(changedFields)
        }
      }}>
      <AntdForm
        layout="vertical"
        initialValues={value}
        form={poForm}
        name="poForm"
        onFinish={handleSubmit}
      >
        {(uploadShow) && (
          <>
            <Card
              title='Upload'
              headStyle={{ fontWeight: 'bold', fontSize: '20px', lineHeight: '23px', backgroundColor: '#CFE2FF', color: '#154D9F' }}
              bodyStyle={{ boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)', borderRadius: '0 0 10px 10px' }}>
              <Row justify="center">
                <Col span={18}>
                  <AntdForm.Item>
                    <Dragger
                      disabled={role === 'Customer'}
                      name='files'
                      action=""
                      fileList={files}
                      listType="picture"
                      multiple={true}
                      className="upload-list-inline"
                      customRequest={customRequestFile}
                      onRemove={onRemoveFile}
                      progress={{
                        strokeColor: {
                          '0%': '#108ee9',
                          '100%': '#87d068',
                        },
                        strokeWidth: 3,
                        format: percent => `${parseFloat(percent.toFixed(2))}%`
                      }}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">Click or drag file to this area to upload</p>
                      <p className="ant-upload-hint">
                        Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                        band files
                      </p>
                    </Dragger>
                  </AntdForm.Item>
                </Col>
              </Row>
            </Card>
            <Divider style={{ margin: '8px 0', borderTop: 'none' }} />
          </>
        )}
        <Card
          title={(mode !== 'Confirm') ? `${mode} Purchase Order` : 'Purchase Order'}
          headStyle={{ fontWeight: 'bold', fontSize: '20px', lineHeight: '23px', backgroundColor: '#CFE2FF', color: '#154D9F' }}
          bodyStyle={{ boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)', borderRadius: '0 0 10px 10px' }}>
          <Row>
            <Col span={3}></Col>
            <Col span={18}>
              <Row gutter={[0, 24]}>
                <div className="content-title">Purchase Order Information</div>
              </Row>
              <Row gutter={[32, 0]}>
                <Col span={12}>
                  <AntdForm.Item name="invNo" label="INV No.">
                    <Input disabled={infoDisabled} />
                  </AntdForm.Item>
                </Col>
                <Col span={12}>
                  <AntdForm.Item name="poNo" label="PO No." rules={[{ required: isValidate, message: 'Please input INV No.!' }]}>
                    <Input disabled={infoDisabled} />
                  </AntdForm.Item>
                </Col>
              </Row>
              <Row gutter={[32, 0]}>
                <Col span={12}>
                  <AntdForm.Item name="consigneeId" label="Consignee" rules={[{ required: isValidate, message: 'Please select Consignee!' }]}>
                    <Select
                      disabled={infoDisabled}
                      placeholder="Select Consignee">
                      {((consignee.value || []).map((o) => (
                        <Select.Option key={o.id} value={o.id}>{o.consigName}</Select.Option>
                      )))
                      }
                    </Select>
                  </AntdForm.Item>
                </Col>
                <Col span={12}>
                  <AntdForm.Item name="deliveryDate" label="Delivery Date (In PO.)">
                    <DatePicker
                      disabled={infoDisabled}
                      className="date-picker-input" />
                  </AntdForm.Item>
                </Col>
              </Row>
              <Row gutter={[32, 0]}>
                <Col span={12}>
                  <AntdForm.Item name="country" label="Country" rules={[{ required: isValidate, message: 'Please select Country!' }]}>
                    <Select
                      disabled={infoDisabled}
                      placeholder="Select Country"
                      onChange={onCountryChange}
                      showSearch
                      optionFilterProp="value"
                      filterOption={(input, option) => {
                        return (option.value || '').toLowerCase().indexOf(input.toLowerCase()) !== -1
                      }}>
                      {((countries || []).map((o) => (
                        <Select.Option key={o.country} value={o.country}>{o.country}</Select.Option>
                      )))
                      }
                    </Select>
                  </AntdForm.Item>
                </Col>
                <Col span={12}>
                  <AntdForm.Item name="port" label="Port" rules={[{ required: isValidate, message: 'Please input Port!' }]}>
                    <Select
                      disabled={infoDisabled}
                      placeholder="Select Port"
                      showSearch
                      optionFilterProp="value"
                      filterOption={(input, option) => {
                        return (option.value || '').toLowerCase().indexOf(input.toLowerCase()) !== -1
                      }}>
                      {((country?.ports ?? []).map((o) => (
                        <Select.Option key={o.name} value={o.name}>{o.name}</Select.Option>
                      )))
                      }
                    </Select>
                  </AntdForm.Item>
                </Col>
              </Row>
              <Row gutter={[32, 0]}>
                <Col span={12}>
                  <AntdForm.Item name="loadingType" label="Loading Type" rules={[{ required: isValidate, message: 'Please select Loading Type!' }]}>
                    <Select
                      disabled={infoDisabled}
                      placeholder="Select Loading Type">
                      {((loadingType.value || []).map((o) => (
                        <Select.Option key={o.id} value={o.type}>{o.type}</Select.Option>
                      )))
                      }
                    </Select>
                  </AntdForm.Item>
                </Col>
                <Col span={12}>
                  <AntdForm.Item name="container" label="Container">
                    <Select
                      disabled={infoDisabled}
                      placeholder="Select Container">
                      {((container.value || []).map((o) => (
                        <Select.Option key={o.id} value={o.type}>{o.type}</Select.Option>
                      )))
                      }
                    </Select>
                  </AntdForm.Item>
                </Col>
              </Row>
              <Row gutter={[32, 0]}>
                <Col span={12}>
                  <AntdForm.Item name="forwarder" label="Forwarder">
                    <Input disabled={infoDisabled} />
                  </AntdForm.Item>
                </Col>
                <Col span={12}>
                  <AntdForm.Item name="receivedDate" label="Received Date">
                    <DatePicker
                      disabled={infoDisabled}
                      className="date-picker-input" />
                  </AntdForm.Item>
                </Col>
              </Row>
              <Row gutter={[32, 0]}>
                <Col span={12}>
                  <AntdForm.Item name="approvalName" label="Approval Name">
                    <Input disabled={infoDisabled} />
                  </AntdForm.Item>
                </Col>
                <Col span={12}>
                  <AntdForm.Item name="approvalPosition" label="Approval Position">
                    <Input disabled={infoDisabled} />
                  </AntdForm.Item>
                </Col>
              </Row>
              <Row gutter={[32, 0]}>
                <Col span={12}>
                  <AntdForm.Item name="invoiceDate" label="Invoice Date">
                    <DatePicker
                      disabled={infoDisabled}
                      className="date-picker-input" />
                  </AntdForm.Item>
                </Col>
                <Col span={12}>
                  <AntdForm.Item name="packingDate" label="Packing List Date">
                    <DatePicker
                      disabled={infoDisabled}
                      className="date-picker-input" />
                  </AntdForm.Item>
                </Col>
              </Row>
              <Row gutter={[32, 0]}>
                <Col span={24}>
                  <AntdForm.Item name="remark" label="Remarks">
                    <Input.TextArea autoSize={{ minRows: 2 }} disabled={infoDisabled} />
                  </AntdForm.Item>
                </Col>
              </Row>
              <Row justify="space-between" gutter={[0, 24]}>
                <Col flex="auto">
                  <span className="content-title">Product Information</span>
                </Col>
                <Col flex="100px">
                  <Button type="primary" size="middle" icon={<FontAwesomeIcon icon={faPlus} className="fa-icon-button" />} onClick={handleAddProduct} disabled={productDisabled}>Add Product</Button>
                </Col>
              </Row>
            </Col>
            <Col span={3}></Col>
          </Row>
          <Row>
            <Col span={24}>
              <ProductTable data={products} editProduct={editProduct} deleteProduct={deleteProduct} disabled={productDisabled} />
            </Col>
          </Row>
          {((products || []).length) ? (
            <Row justify="center">
              {(['Create', 'Edit'].indexOf(mode) !== -1) && (
                <Button style={{ margin: '20px 10px 0', width: '300px' }} type="primary" size="large" htmlType="submit" loading={loadingSubmit}>{mode}</Button>
              )}
              {(infoAction) && (
                <Button style={{ margin: '20px 10px 0', width: '300px', backgroundColor: '#F6C001', color: '#FFF' }} type="text" size="large" onClick={() => navigate(`/admin/purchase-order/${poId}/edit`)}>Edit</Button>
              )}
              {(loadedShow || !(['Loaded', 'Shipped'].indexOf(value.status) === -1 && (value?.products ?? []).every((o) => o?.confirmBAG) && (value?.products ?? []).every((o) => o?.confirmCTN))) && (
                <Button style={{ margin: '20px 10px 0', width: '300px', backgroundColor: '#67C23A', color: '#FFF' }} type="text" size="large" onClick={() => handleConfirm(poId, value.status)}>Confirm Product</Button>
              )}
              {(loadedShow) && (
                <BookingConfirmedPdf record={value} bottomType='text'></BookingConfirmedPdf>
              )}
              {(!revId && !historyDisabled && ['Create'].indexOf(mode) === -1) && (
                <Button style={{ margin: '20px 10px 0', backgroundColor: '#B4B4B4', color: '#FFF' }} type="text" size="large" icon={<FontAwesomeIcon icon={faClock} className="fa-history-icon" />} onClick={() => navigate(`/admin/purchase-order/${poId}/history`)}></Button>
              )}
              {(revId) && (
                <Button style={{ margin: '20px 10px 0', width: '300px', backgroundColor: '#154D9F', color: '#FFF' }} type="text" size="large" onClick={() => navigate(`/admin/purchase-order/${poId}/history`)}>Back</Button>
              )}
            </Row>
          ) : null}
        </Card>
        {(rtnShow) && (
          <>
            <Divider style={{ margin: '8px 0', borderTop: 'none' }} />
            <Card
              title={'Planning Information'}
              headStyle={{ fontWeight: 'bold', fontSize: '20px', lineHeight: '23px', backgroundColor: '#CFE2FF', color: '#154D9F' }}
              bodyStyle={{ boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)', borderRadius: '0 0 10px 10px' }}>
              <Row justify="center">
                <Col span={8}>
                  <AntdForm.Item name="rtnDate" label="RTN Date">
                    <DatePicker
                      className="date-picker-input"
                      disabled={rtnDisabled} />
                  </AntdForm.Item>
                </Col>
              </Row>
              <Row justify="center">
                {(!rtnDisabled) && (
                  <Button style={{ margin: '20px 10px 0', width: '300px' }} type="primary" size="large" htmlType="submit" loading={loadingSubmit}>Submit</Button>
                )}
                {(!historyDisabled) && (
                  <Button style={{ margin: '20px 10px 0', backgroundColor: '#B4B4B4', color: '#FFF' }} type="text" size="large" icon={<FontAwesomeIcon icon={faClock} className="fa-history-icon" />} onClick={() => navigate(`/admin/purchase-order/${poId}/history`)}></Button>
                )}
              </Row>
            </Card>
          </>
        )}
        {(requestShow) && (
          <>
            <Divider style={{ margin: '8px 0', borderTop: 'none' }} />
            <Card
              title={'Shipping Information'}
              headStyle={{ fontWeight: 'bold', fontSize: '20px', lineHeight: '23px', backgroundColor: '#CFE2FF', color: '#154D9F' }}
              bodyStyle={{ boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)', borderRadius: '0 0 10px 10px' }}>
              <Row>
                <Col span={3}></Col>
                <Col span={18}>
                  <Row justify="space-between" gutter={[0, 24]}>
                    <Col flex="auto">
                      <span className="content-title">Booking Request</span>
                    </Col>
                    <Col flex="100px">
                      <Button type="primary" size="middle" icon={<FontAwesomeIcon icon={faDownload} className="fa-icon-button" />} onClick={bookingRequestModal} disabled={requestDisabled}>Export Booking Request</Button>
                    </Col>
                  </Row>
                  <Row gutter={[32, 0]}>
                    <Col span={12}>
                      <AntdForm.Item name="cyDate" label="CY Date">
                        <DatePicker className="date-picker-input" disabled={requestDisabled} />
                      </AntdForm.Item>
                    </Col>
                    <Col span={12}>
                      <AntdForm.Item name="etd" label="ETD">
                        <DatePicker className="date-picker-input" disabled={requestDisabled} />
                      </AntdForm.Item>
                    </Col>
                  </Row>
                  {(confirmedShow) && (
                    <>
                      {(!requestDisabled && !confirmedDisabled) && (
                        <Row justify="center">
                          <Button
                            type="primary"
                            size="large"
                            style={{ margin: '10px 0 20px 0', width: '300px' }}
                            loading={loadingSubmit}
                            onClick={() => {
                              const formData = poForm.getFieldsValue()
                              submitWithoutValidate(formData.cyDate, formData.etd)
                            }}
                          >
                            Submit for Booking Request
                          </Button>
                        </Row>
                      )}
                      <Row justify="space-between" gutter={[0, 24]}>
                        <Col flex="auto">
                          <span className="content-title">Booking Confirmed</span>
                        </Col>
                        <Col flex="100px"></Col>
                      </Row>
                      <Row gutter={[32, 0]}>
                        <Col span={12}>
                          <AntdForm.Item name="date" label="Date">
                            <DatePicker className="date-picker-input" disabled={confirmedDisabled} />
                          </AntdForm.Item>
                        </Col>
                        <Col span={12}>
                          <AntdForm.Item name="eta" label="ETA">
                            <DatePicker className="date-picker-input" disabled={confirmedDisabled} />
                          </AntdForm.Item>
                        </Col>
                      </Row>
                      <Row gutter={[32, 0]}>
                        <Col span={12}>
                          <AntdForm.Item name="shipperName" label="Shipper Name">
                            <Input disabled={confirmedDisabled} />
                          </AntdForm.Item>
                        </Col>
                        <Col span={12}>
                          <AntdForm.Item name="vesselName" label="Vessel Name">
                            <Input disabled={confirmedDisabled} />
                          </AntdForm.Item>
                        </Col>
                      </Row>
                      <Row gutter={[32, 0]}>
                        <Col span={12}>
                          <AntdForm.Item name="motherVesselName" label="Mother Vessel Name">
                            <Input disabled={confirmedDisabled} />
                          </AntdForm.Item>
                        </Col>
                        <Col span={12}>
                          <AntdForm.Item name="portOfLoading" label="Port of Loading">
                            <Select
                              disabled={confirmedDisabled}
                              placeholder="Select Port of Loading">
                              <Select.Option key={0} value="Laem Chabang, Thailand">Laem Chabang, Thailand</Select.Option>
                              <Select.Option key={1} value="Bangkok, Thailand">Bangkok, Thailand</Select.Option>
                            </Select>
                          </AntdForm.Item>
                        </Col>
                      </Row>
                      <Row gutter={[32, 0]}>
                        <Col span={12}>
                          <AntdForm.Item name="transhipmentPort" label="Transhipment Port">
                            <Input disabled={confirmedDisabled} />
                          </AntdForm.Item>
                        </Col>
                        <Col span={12}>
                          <AntdForm.Item name="portOfDischarge" label="Port of Discharge">
                            <Select
                              disabled={confirmedDisabled}
                              placeholder="Select Port"
                              showSearch
                              optionFilterProp="value"
                              filterOption={(input, option) => {
                                return (option.value || '').toLowerCase().indexOf(input.toLowerCase()) !== -1
                              }}>
                              {((destination?.ports ?? []).map((o) => (
                                <Select.Option key={o.name} value={o.name}>{o.name}</Select.Option>
                              )))
                              }
                            </Select>
                          </AntdForm.Item>
                        </Col>
                      </Row>
                      <Row gutter={[32, 0]}>
                        <Col span={12}>
                          <AntdForm.Item name="finalDestination" label="Final Destination">
                            <Select
                              disabled={confirmedDisabled}
                              placeholder="Select Final Destination"
                              onChange={onDestinationChange}
                              showSearch
                              optionFilterProp="value"
                              filterOption={(input, option) => {
                                return (option.value || '').toLowerCase().indexOf(input.toLowerCase()) !== -1
                              }}>
                              {((countries || []).map((o) => (
                                <Select.Option key={o.country} value={o.country}>{o.country}</Select.Option>
                              )))
                              }
                            </Select>
                          </AntdForm.Item>
                        </Col>
                        <Col span={12}>
                          <AntdForm.Item name="commodity" label="Commodity">
                            <Input disabled={true} />
                          </AntdForm.Item>
                        </Col>
                      </Row>
                      <Row gutter={[32, 0]}>
                        <Col span={12}>
                          <AntdForm.Item name="bookingRefNo" label="Booking Ref No." rules={[{ required: isValidate, message: 'Please input Booking Ref No.!' }]}>
                            <Input disabled={confirmedAdminDisabled} />
                          </AntdForm.Item>
                        </Col>
                        <Col span={12}>
                          <AntdForm.Item name="volume" label="Volume">
                            <Input disabled={confirmedDisabled} />
                          </AntdForm.Item>
                        </Col>
                      </Row>
                      <Row gutter={[32, 0]}>
                        <Col span={12}>
                          <AntdForm.Item name="closingDate" label="Closing Date">
                            <DatePicker className="date-picker-input" disabled={confirmedDisabled} />
                          </AntdForm.Item>
                        </Col>
                        <Col span={12}>
                          <AntdForm.Item name="closingTime" label="Closing Time">
                            <TimePicker className="date-picker-input" format='HH:mm' minuteStep={5} disabled={confirmedDisabled} />
                          </AntdForm.Item>
                        </Col>
                      </Row>
                      <Row gutter={[32, 0]}>
                        <Col span={12}>
                          <AntdForm.Item name="containerNo" label="Container No.">
                            <Input disabled={confirmedDisabled} />
                          </AntdForm.Item>
                        </Col>
                        <Col span={12}>
                          <AntdForm.Item name="sealsNo" label="Seals No.">
                            <Input disabled={confirmedDisabled} />
                          </AntdForm.Item>
                        </Col>
                      </Row>
                      <Row gutter={[32, 0]}>
                        <Col span={12}>
                          <AntdForm.Item name="agent" label="Agent">
                            <Input disabled={confirmedDisabled} />
                          </AntdForm.Item>
                        </Col>
                        <Col span={12}>
                          <AntdForm.Item name="paperlessCode" label="Paperless Code">
                            <Input disabled={confirmedDisabled} />
                          </AntdForm.Item>
                        </Col>
                      </Row>
                      <Row gutter={[32, 0]}>
                        <Col span={12}>
                          <AntdForm.Item name="hbl" label="HBL / BL No.">
                            <Input disabled={confirmedDisabled} />
                          </AntdForm.Item>
                        </Col>
                        <Col span={12}>
                          <AntdForm.Item name="siCutOff" label="SI Cut-Off">
                            <Input disabled={confirmedDisabled} />
                          </AntdForm.Item>
                        </Col>
                      </Row>
                      <Row gutter={[32, 0]}>
                        <Col span={12}>
                          <AntdForm.Item name="blStatus" label="BL Status">
                            <Input disabled={confirmedDisabled} />
                          </AntdForm.Item>
                        </Col>
                        <Col span={12}></Col>
                      </Row>
                    </>
                  )}
                  <Row justify="center">
                    {(!requestDisabled && !confirmedDisabled) && (
                      <Button style={{ margin: '20px 10px 0', width: '300px' }} type="primary" size="large" htmlType="submit" loading={loadingSubmit}>Submit</Button>
                    )}
                    {(confirmedAdminDisabled) && (
                      <Button style={{ margin: '20px 10px 0', width: '300px' }} type="primary" size="large" htmlType="submit" loading={loadingSubmit}>Submit</Button>
                    )}
                    {(!revId) ? (
                      <>
                        {(!historyDisabled) && (
                          <Button style={{ margin: '20px 10px 0', backgroundColor: '#B4B4B4', color: '#FFF' }} type="text" size="large" icon={<FontAwesomeIcon icon={faClock} className="fa-history-icon" />} onClick={() => navigate(`/admin/purchase-order/${poId}/history`)}></Button>
                        )}
                      </>
                    ) : (
                      <Button style={{ margin: '20px 10px 0', width: '300px', backgroundColor: '#154D9F', color: '#FFF' }} type="text" size="large" onClick={() => navigate(`/admin/purchase-order/${poId}/history`)}>Back</Button>
                    )}
                  </Row>
                </Col>
                <Col span={3}></Col>
              </Row>
            </Card>
          </>
        )}
      </AntdForm>
      <ProductModal data={product} visible={productVisible} productList={productList.value} onCancel={handleCancel} />
      <BookingRequestPdf poId={poId} data={poForm.getFieldsValue()} products={products} consignee={consignee.value} countries={countries} visible={bookingRequestVisible} disabled={confirmedDisabled} onCancel={handleCancel} />
    </AntdForm.Provider>
  )
}

export default POForm

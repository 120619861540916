import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAsync } from 'react-use'

import { Button, Card, Table, Form, Row, Col } from 'antd'
import { FormOutlined, PlusOutlined } from '@ant-design/icons'

import { useAuth } from '../../../contexts/AuthContextProvider'

const List = () => {
  const navigate = useNavigate()
  const { db, functions } = useAuth()

  const [btnLoading, setBtnLoading] = useState(false)
  const [count, setCount] = useState(0)

  const { value } = useAsync(async () => {
    const snap = await db.collection('company').get()
    return snap.docs.map(doc => {
      return {
        ...doc.data(),
        id: doc.id
      }
    })
  }, [count])

  const columns = [
    {
      title: 'Company Name',
      dataIndex: 'name',
      width: 200
    },
    {
      title: 'Address',
      dataIndex: 'address',
      width: 600
    },
    {
      title: 'Bank Account Name',
      dataIndex: 'bankaccount',
      width: 200
    },
    {
      title: 'Bank Account No.',
      dataIndex: 'banknumber',
      width: 150
    },
    {
      title: 'Operation',
      dataIndex: 'operation',
      width: 200,
      render: (_, row) =>
        <div style={{ textAlign: 'center' }}>
          <Button style={{ backgroundColor: '#F6C001', color: '#FFFFFF', width: '32px', height: '30px', borderRadius: '4px' }} size="small" onClick={() => navigate(`/admin/master-data/company/view/${row.id}`)}><FormOutlined /></Button>
        </div>
    }
  ]

  const seedData = async () => {
    try {
      setBtnLoading(true)
      const addInitialData = functions.httpsCallable('httpSeedData-seedData')
      await addInitialData()
      setBtnLoading(false)
      setCount(count + 1)
    } catch (error) {
      console.log(error)
      setBtnLoading(false)
    }
  }

  return (
    <Card
      title="Company Information"
      headStyle={{ fontWeight: 'bold', fontSize: '20px', lineHeight: '23px', backgroundColor: '#CFE2FF', color: '#154D9F' }}
      bodyStyle={{ boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)', borderRadius: '0 0 10px 10px' }}
    >
      <Form>
        <Row style={{ textAlign: 'right' }}>
          <Col span={12} style={{ textAlign: 'right' }}>
          </Col>
          <Col span={12}>
            <Form.Item>
              {(value?.length) ? (
                <Button htmlType="submit" onClick={() => navigate('/admin/master-data/company/create')} loading={btnLoading} style={{ borderRadius: '8px', marginLeft: '20px', backgroundColor: '#154D9F', color: 'white' }}><PlusOutlined />Create Company</Button>
              ) : (
                <Button htmlType="submit" onClick={() => seedData()} loading={btnLoading} style={{ borderRadius: '8px', marginLeft: '20px', backgroundColor: '#154D9F', color: 'white' }}><PlusOutlined />Seed Company</Button>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Table
        bordered
        rowKey="id"
        style={{ overflowX: 'auto' }}
        columns={columns}
        dataSource={value}
      />
    </Card>
  )
}

export default List

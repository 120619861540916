import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAsync } from 'react-use'

import { Form as AntdForm, Card, Col, Row, Input, Button, Skeleton, Select } from 'antd'
import { PlusOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'

// import _ from 'lodash'

import { useAuth } from '../../../contexts/AuthContextProvider'
import countryJSON from 'country-region-data/data.json'

const Form = () => {
  const navigate = useNavigate()
  const { db } = useAuth()
  const { countryPort } = useParams()
  const [btnLoading, setBtnLoading] = useState(false)
  const { value, loading } = useAsync(async () => {
    if (!countryPort) {
      return {
        ports: [
          {
            name: ''
          }
        ]
      }
    }
    const ref = await db.collection('countryPort').doc(countryPort).get()
    return {
      ...ref.data(),
      id: ref.id
    }
  }, [countryPort])

  const mode = countryPort ? 'Submit' : 'Create'

  const handleSubmit = async (formData) => {
    try {
      setBtnLoading(true)
      const ref = db.collection('countryPort')
      if (!countryPort) {
        const snap = await ref.get()
        const countryList = snap.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id
        }))
        const existingData = countryList.find((o) => o.country === formData.country)
        if (existingData) {
          const portNameList = formData.ports.map((o) => o.name.toLowerCase())
          const ports = existingData.ports.filter((o) => portNameList.indexOf(o.name.toLowerCase()) === -1)
          await ref.doc(existingData.id).set({
            ports: [
              ...ports,
              ...formData.ports
            ]
          }, {
            merge: true
          })
        } else {
          await ref.doc().set(formData, {
            merge: true
          })
        }
      } else {
        await ref.doc(countryPort).set(formData, {
          merge: true
        })
      }
      setBtnLoading(false)
      navigate('/admin/master-data/country-port/list')
      return Promise.resolve()
    } catch (error) {
      setBtnLoading(false)
      return Promise.reject(error)
    }
  }
  if (loading) return <Skeleton></Skeleton>

  return (
    <AntdForm
      layout="vertical"
      onFinish={handleSubmit}
      initialValues={value}
    >
      <Card
        title="Country & Port"
        headStyle={{ fontWeight: 'bold', fontSize: '20px', lineHeight: '23px', backgroundColor: '#CFE2FF', color: '#154D9F' }}
        bodyStyle={{ boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)', borderRadius: '0 0 10px 10px' }}
      >
        <Row>
          <Col span={8}></Col>
          <Col span={14}>
            <Row gutter={[32, 0]}>
              <Col span={14}>
                <AntdForm.Item
                  name='country'
                  label='Country'
                  rules={[{ required: true, message: 'Country cannot be blank' }]}
                >
                  <Select
                    showSearch
                    placeholder="Select Country"
                    optionFilterProp="value"
                    filterOption={(input, option) => {
                      return (option.value || '').toLowerCase().indexOf(input.toLowerCase()) !== -1
                    }}>
                    {((countryJSON || []).map((o) => (
                      <Select.Option key={o.countryName} value={o.countryName}>{o.countryName}</Select.Option>
                    )))
                    }
                  </Select>
                </AntdForm.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={5}></Col>
          <Col span={17}>
            <AntdForm.List name="ports">
              {(fields, { add, remove }) => (
                <div>
                  {fields.map((field, i) => (
                    <div key={i}>
                      <Row gutter={[32, 0]}   >
                        <Col span={10} >
                          <AntdForm.Item
                            {...field}
                            name={[field.name, 'name']}
                            label="Port"
                            fieldKey={[field.fieldKey, 'name']}
                            rules={[{ required: true, message: 'Port cannot be blank' }]}
                          >
                            <Input key={i} />
                          </AntdForm.Item>
                        </Col>
                        <Col span={10}>
                          <AntdForm.Item
                            label="Status"
                            key={i}
                            fieldKey={[field.fieldKey, 'status']}
                            name={[field.name, 'status']}
                            initialValue={mode === 'Create' ? 'Active' : value.status}
                          >
                            <Select key={i} style={{ height: '40px', borderRadius: '8px' }}>
                              <Select.Option value="Active">Active</Select.Option>
                              {countryPort &&
                                <Select.Option value="InActive">InActive</Select.Option>
                              }
                            </Select>
                          </AntdForm.Item>
                        </Col>
                        {
                          ((value && value.ports.length && i > (value.ports.length - 1)) || mode === 'Create') &&
                          <Col span={4}>
                            <Button key={i} style={{ marginTop: '40px', backgroundColor: '#EA3939', color: '#FFF', width: '42px', height: '40px', borderRadius: '4px' }} onClick={() => remove(field.name)} icon={<FontAwesomeIcon icon={faTrashAlt} />}></Button>
                          </Col>
                        }
                      </Row>
                    </div>
                  ))}
                  <Row justify="center" style={{ marginTop: '20px' }}>
                    <AntdForm.Item >
                      <Button style={{ marginRight: '180px', width: '150px', borderRadius: '8px' }} type="primary" onClick={() => add()}><PlusOutlined />Add Port</Button>
                    </AntdForm.Item>
                  </Row>
                  <Row justify="center" style={{ marginTop: '20px' }}>
                    <AntdForm.Item>
                      <Button style={{ marginRight: '180px', height: '40px', width: '300px', borderRadius: '8px' }} type="primary" loading={btnLoading} htmlType="submit">{mode}</Button>
                    </AntdForm.Item>
                  </Row>
                </div>
              )}
            </AntdForm.List>
          </Col>
          <Col span={2}></Col>
        </Row>
      </Card>
    </AntdForm>
  )
}

export default Form

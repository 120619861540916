import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

import { Skeleton } from 'antd'

import { useAuth } from '../contexts/AuthContextProvider'

const PrivateRoute = ({ children, roles }) => {
  const navigate = useNavigate()
  const { role } = useAuth()

  let redirect = false

  useEffect(() => {
    if (redirect) {
      navigate('/403')
    }
    return () => { }
  }, [redirect, navigate])

  if (roles.indexOf(role) === -1) {
    redirect = true
    return <Skeleton />
  }
  return (children) ? children : <Outlet />
}

export default PrivateRoute
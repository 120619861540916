import React from 'react'
import { Outlet } from 'react-router-dom'

import './MasterDataPage.less'

const MasterDataPage = () => {
  return (
    <div className='master-data'>
      <Outlet />
    </div>
  )
}

export default MasterDataPage

import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAsync } from 'react-use'

import { Button, Skeleton, Card, Form, Row, Col, Input, Select } from 'antd'

import _ from 'lodash'

import { useAuth } from '../../contexts/AuthContextProvider'

const Detail = () => {
  const { TextArea } = Input
  const navigate = useNavigate()
  const { db, role } = useAuth()
  const { productId } = useParams()
  // const [selectData, setSelect] = useState('')
  const [containerType, setContainerType] = useState('')
  const { value, loading } = useAsync(async () => {
    // const dataSelect = await db.collection('container').get()
    // const dataContainer = dataSelect.docs.map(item => {
    //   return {
    //     id: item.id,
    //     ...item.data()
    //   }
    // })
    // setSelect(dataContainer)
    const snap = await db.collection('products').doc(productId).get()
    setContainerType(_.get(snap.data(), 'containerType'))
    return {
      ...snap.data(),
      id: snap.id
    }
  })
  if (loading) {
    return <Skeleton />
  }
  const handleEditProduct = () => {
    navigate(`/admin/product/${productId}/edit`)
  }
  return (
    <Form
      layout="vertical"
      // onFinish={handleSubmit}
      initialValues={value}
    >
      <Card
        title="Product Detail"
        headStyle={{ fontWeight: 'bold', fontSize: '20px', lineHeight: '23px', backgroundColor: '#CFE2FF', color: '#154D9F' }}
        bodyStyle={{ boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)', borderRadius: '0 0 10px 10px' }}>

        <Row>
          <Col span={3}></Col>
          <Col span={18} >
            <Row gutter={[0, 24]}>
              <div className="content-title">Product Information</div>
            </Row>
            <Row gutter={[32, 0]}>
              <Col span={12}>
                <Form.Item name="itemCode" label="Item Code">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="productRef" label="Product (Ref. Customer PO)">
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[32, 0]}>
              <Col span={12}>
                <Form.Item name="productNameEN" label="Name of Product (EN)">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="productNameTH" label="Name of Product (TH)">
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[32, 0]}>
              <Col span={12}>
                <Form.Item name="weight" label="Weight per Bag (Gram)">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="bagPerCarton" label="Bag per Carton">
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[32, 0]}>
              <Col span={12}>
                <Form.Item name="quantityCTN" label="QTY per Container (CTN)">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="quantityBAG" label="QTY per Container (BAG)">
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[32, 0]}>
              <Col span={12}>
                <Form.Item name="price" label="Price Each (USD)">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="hsCode" label="H.S. Code">
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[32, 0]}>
              <Col span={12}>
                <Form.Item name="marks" label="Marks" >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
              </Col>
            </Row>
            <Row gutter={[32, 0]}>
              <Col span={24}>
                <Form.Item name="remarks" label="Remarks">
                  <TextArea disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[0, 24]}>
              <div className="content-title">Container Loading Details</div>
            </Row>
            <Row gutter={[32, 0]} >
              <Col span={12}>
                <Form.Item name="containerType" label="Type" >
                  <Select placeholder="Select Type" disabled>
                    <Select.Option value="dryContainer">DRY CONTAINER</Select.Option>
                    <Select.Option value="reeferContainer" >REEFER CONTAINER</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            {
              containerType === 'dryContainer' &&
              <div>
                <Row gutter={[32, 0]}>
                  <Col span={12}>
                    <Form.Item name="dryOdCartonCm" label="OD Cartons Size(LxWxH)" >
                      <Input addonAfter="CM" disabled />
                    </Form.Item>
                    <Form.Item name="dryOdCartonInch"  >
                      <Input addonAfter="INCH" disabled />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="dryPalletSize" label="Pallet Size" >
                      <Input addonAfter="mm." disabled />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[32, 0]}>
                  <Col span={12}>
                    <Form.Item name="dry20Dc" label="20'Dc (On Floor)" >
                      <Input addonAfter="CTNS" disabled />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="dry40Hc" label="40'HC (On Floor)" >
                      <Input addonAfter="CTNS" disabled />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[32, 0]}>
                  <Col span={12}>
                    <Form.Item name="dry20DcTallPallet" label="20'Dc (Tall Pallet)">
                      <Input disabled />
                    </Form.Item>
                    <Form.Item name="dry20DcTallPaletCtn" label="" >
                      <Input addonAfter="CTNS" disabled />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="dry40HcTallPallet" label="40'HC (Tall Pallet)" >
                      <Input disabled />
                    </Form.Item>
                    <Form.Item name="dry40HcTallPalletCtn" label="" >
                      <Input addonAfter="CTNS" disabled />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[32, 0]}>
                  <Col span={12}>
                    <Form.Item name="dry20DcDbPallet" label="20'Dc (Double Stack Pallet)" >
                      <Input disabled />
                    </Form.Item>
                    <Form.Item name="dry20DcDbPalletCtn" label="" >
                      <Input addonAfter="CTNS" disabled />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="dry40HcDbPallet" label="40'HC (Double Stack Pallet)">
                      <Input disabled />
                    </Form.Item>
                    <Form.Item name="dry40HcDbPalletCtn" label="">
                      <Input addonAfter="CTNS" disabled />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            }
            {
              containerType === 'reeferContainer' &&
              <div>
                <Row gutter={[32, 0]}>
                  <Col span={12}>
                    <Form.Item name="reeferOdCartonCm" label="OD Cartons Size(LxWxH)" >
                      <Input disabled addonAfter="CM" />
                    </Form.Item>
                    <Form.Item name="reeferOdCartonInch" >
                      <Input disabled addonAfter="INCH" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="reeferPalletSize" label="Pallet Size" >
                      <Input disabled addonAfter="mm." />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[32, 0]}>
                  <Col span={12}>
                    <Form.Item name="reefer20Rf" label="20'RF (On Floor)" >
                      <Input disabled addonAfter="CTNS" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="reefer40HcRf" label="40'HC RF (On Floor)" >
                      <Input disabled addonAfter="CTNS" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[32, 0]}>
                  <Col span={12}>
                    <Form.Item name="reefer20DcTallPallet" label="20'RF (Tall Pallet)" >
                      <Input disabled />
                    </Form.Item>
                    <Form.Item name="reefer20DcTallPaletCtn" label="" >
                      <Input disabled addonAfter="CTNS" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="reefer40HcRfTallPallet" label="40'HC RF (Tall Pallet)">
                      <Input disabled />
                    </Form.Item>
                    <Form.Item name="reefer40HcRfTallPalletCtn" label="">
                      <Input addonAfter="CTNS" disabled />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[32, 0]}>
                  <Col span={12}>
                    <Form.Item name="reefer20RfDbPallet" label="20'RF (Double Stack Pallet)" >
                      <Input disabled />
                    </Form.Item>
                    <Form.Item name="reefer20RfDbPalletCtn" label="" >
                      <Input addonAfter="CTNS" disabled />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="reefer40HcRfDbPallet" label="40'HC RF (Double Stack Pallet)" >
                      <Input disabled />
                    </Form.Item>
                    <Form.Item name="reefer40HcRfDbPalletCtn" label="" >
                      <Input addonAfter="CTNS" disabled />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            }
            <Row>
              <Col span={24} style={{ textAlign: 'center' }}>
                {
                  ['Admin', 'Financial', 'Purchasing', 'Planner'].indexOf(role) !== -1 && <Button onClick={handleEditProduct} style={{ height: 40, backgroundColor: '#F6C001', width: 300, borderRadius: '8px' }} htmlType="submit">Edit</Button>
                }
              </Col>
            </Row>
          </Col>
          <Col span={3}></Col>
        </Row>
      </Card>
    </Form>
  )
}

export default Detail

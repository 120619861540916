import React from 'react'
import { Link, Outlet } from 'react-router-dom'

import { Card, Col, Layout, Row, Image } from 'antd'

import ImgBg from '../../assets/images/bg.jpg'
import Logo from '../../assets/images/logo.jpg'

import './AuthLayout.less'

const AuthLayout = () => {
  return (
    <Layout style={{ backgroundImage: `url(${ImgBg})` }} className="AuthLayout">
      {/* <Layout.Header>
        <Typography.Title style={{color: 'white'}}>Craft Foods</Typography.Title>
      </Layout.Header> */}
      <Layout.Content>
        <Row>
          <Col xs={{ span: 6 }} sm={{ span: 6 }} md={{ span: 8 }} lg={{ span: 9 }}></Col>
          <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }}>
            <Card style={{ marginTop: '50%', borderRadius: '10px', backgroundColor: 'white' }}>
              <Link to="/login">
                <Image src={`${Logo}`} className="logo" preview={false}></Image>
              </Link>
              <Outlet />
            </Card>
          </Col>
          <Col xs={{ span: 6 }} sm={{ span: 6 }} md={{ span: 8 }} lg={{ span: 9 }}></Col>
        </Row>
      </Layout.Content>
    </Layout>
  )
}

export default AuthLayout

import dayjs from 'dayjs'

const dayjsToDB = (date) => {
  return (!date) ? null : (!date?.seconds) ? date.$d || date._d : new Date(date.seconds * 1000)
}

const dbTodayjs = (date) => {
  return (!date) ? null : (date?.seconds) ? dayjs(date.toDate()) : dayjs(date)
}

const pickBy = (obj) => {
  return Object.keys(obj).filter((k) => obj[k] != null).reduce((a, k) => ({ ...a, [k]: obj[k] }), {})
}

const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const isNumber = (value) => {
  return typeof value === 'number' && !Number.isNaN(value)
}

export {
  pickBy,
  dayjsToDB,
  dbTodayjs,
  sleep,
  isNumber
}
import React from 'react'

import { Button, Table, Space } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'

const ProductTable = ({ data, editProduct, deleteProduct, disabled }) => {
  const columns = [
    {
      title: 'Item Code',
      key: 'itemCode',
      dataIndex: 'itemCode',
      width: 100,
      sorter: {
        compare: (a, b) => a.itemCode.localeCompare(b.itemCode),
        multiple: 1
      }
    },
    {
      title: 'Item',
      key: 'productRef',
      dataIndex: 'productRef',
      width: 150,
      sorter: {
        compare: (a, b) => a.productRef.localeCompare(b.productRef),
        multiple: 1
      }
    },
    {
      title: 'Quantity (CTN)',
      key: 'quantityCTN',
      dataIndex: 'quantityCTN',
      width: 100,
      render: text => (
        <>
          {(text && !isNaN(text)) ? new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(+text) : ''}
        </>
      ),
      sorter: {
        compare: (a, b) => a.quantityCTN - b.quantityCTN,
        multiple: 1
      }
    },
    {
      title: 'Confirm Quantity (CTN)',
      key: 'confirmCTN',
      dataIndex: 'confirmCTN',
      width: 100,
      render: text => (
        <>
          {(text && !isNaN(text)) ? new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(+text) : ''}
        </>
      ),
      sorter: {
        compare: (a, b) => a.confirmCTN - b.confirmCTN,
        multiple: 1
      }
    },
    {
      title: 'Quantity (BAG)',
      key: 'quantityBAG',
      dataIndex: 'quantityBAG',
      width: 100,
      render: text => (
        <>
          {(text && !isNaN(text)) ? new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(+text) : ''}
        </>
      ),
      sorter: {
        compare: (a, b) => a.quantityBAG - b.quantityBAG,
        multiple: 1
      }
    },
    {
      title: 'Confirm Quantity (BAG)',
      key: 'confirmBAG',
      dataIndex: 'confirmBAG',
      width: 100,
      render: text => (
        <>
          {(text && !isNaN(text)) ? new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(+text) : ''}
        </>
      ),
      sorter: {
        compare: (a, b) => a.confirmBAG - b.confirmBAG,
        multiple: 1
      }
    },
    {
      title: 'Weight per Bag (Gram)',
      key: 'weight',
      dataIndex: 'weight',
      width: 100,
      render: text => (
        <>
          {(text && !isNaN(text)) ? new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(+text) : ''}
        </>
      ),
      sorter: {
        compare: (a, b) => a.weight - b.weight,
        multiple: 1
      }
    },
    {
      title: 'Bag per Carton',
      key: 'bagPerCarton',
      dataIndex: 'bagPerCarton',
      width: 100,
      render: text => (
        <>
          {(text && !isNaN(text)) ? new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(+text) : ''}
        </>
      ),
      sorter: {
        compare: (a, b) => a.bagPerCarton - b.bagPerCarton,
        multiple: 1
      }
    },
    {
      title: 'Price Each (USD)',
      key: 'price',
      dataIndex: 'price',
      width: 100,
      render: text => (
        <>
          {(text && !isNaN(text)) ? new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(+text) : ''}
        </>
      ),
      sorter: {
        compare: (a, b) => a.price - b.price,
        multiple: 1
      }
    },
    {
      title: 'Amount (USD)',
      key: 'amount',
      dataIndex: 'amount',
      width: 100,
      render: text => (
        <>
          {(text && !isNaN(text)) ? new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(+text) : ''}
        </>
      ),
      sorter: {
        compare: (a, b) => a.amount - b.amount,
        multiple: 1
      }
    },
    {
      title: 'Wt.(KG)/CTN',
      key: 'weightPerCTN',
      dataIndex: 'weightPerCTN',
      width: 100,
      render: text => (
        <>
          {(text && !isNaN(text)) ? new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(+text) : ''}
        </>
      ),
      sorter: {
        compare: (a, b) => a.weightPerCTN - b.weightPerCTN,
        multiple: 1
      }
    },
    {
      title: 'Packaging Width (CM)',
      key: 'packagingWidth',
      dataIndex: 'packagingWidth',
      width: 100,
      render: text => (
        <>
          {(text && !isNaN(text)) ? new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(+text) : ''}
        </>
      ),
      sorter: {
        compare: (a, b) => a.boxWidth - b.boxWidth,
        multiple: 1
      }
    },
    {
      title: 'Packaging Height (CM)',
      key: 'packagingHeight',
      dataIndex: 'packagingHeight',
      width: 100,
      render: text => (
        <>
          {(text && !isNaN(text)) ? new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(+text) : ''}
        </>
      ),
      sorter: {
        compare: (a, b) => a.boxHeight - b.boxHeight,
        multiple: 1
      }
    },
    {
      title: 'Packaging Length (CM)',
      key: 'packagingLength',
      dataIndex: 'packagingLength',
      width: 100,
      render: text => (
        <>
          {(text && !isNaN(text)) ? new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(+text) : ''}
        </>
      ),
      sorter: {
        compare: (a, b) => a.boxLength - b.boxLength,
        multiple: 1
      }
    },
    {
      title: 'Container',
      key: 'container',
      dataIndex: 'container',
      width: 100,
      sorter: {
        compare: (a, b) => a.container.localeCompare(b.container),
        multiple: 1
      }
    },
    {
      title: 'Forwarder',
      key: 'forwarder',
      dataIndex: 'forwarder',
      width: 100,
      sorter: {
        compare: (a, b) => a.forwarder.localeCompare(b.forwarder),
        multiple: 1
      }
    },
    {
      title: 'Remarks',
      key: 'remark',
      dataIndex: 'remark',
      width: 150,
      sorter: {
        compare: (a, b) => a.remark.localeCompare(b.remark),
        multiple: 1
      }
    },
    {
      title: 'Operation',
      key: 'operation',
      fixed: 'right',
      width: 150,
      render: (record) => (
        <Space>
          {(!disabled) && (
            <>
              <Button type="text" size="middle" style={{ backgroundColor: '#F6C001', color: '#FFF' }} icon={<FontAwesomeIcon icon={faEdit} />} onClick={() => editProduct(record)}></Button>
              <Button type="text" size="middle" style={{ backgroundColor: '#EA3939', color: '#FFF' }} icon={<FontAwesomeIcon icon={faTrashAlt} />} onClick={() => deleteProduct(record)}></Button>
            </>
          )}
        </Space>
      )
    }
  ]
  return (
    <Table
      key='id'
      columns={columns}
      dataSource={data}
      style={{ overflowX: 'auto' }}
    />
  )
}

export default ProductTable

import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAsync } from 'react-use'

import { Form as AntdForm, Card, Col, Row, Input, Button, Upload, Modal, Skeleton } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import { useAuth } from '../../../contexts/AuthContextProvider'

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

const Form = () => {
  const navigate = useNavigate()
  const { firebaseApp, db, storage } = useAuth()
  const { companyId } = useParams()
  const [btnLoading, setBtnLoading] = useState(false)
  const [files, setFiles] = useState([])
  const [url, setUrl] = useState()
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')

  const mode = companyId ? 'Submit' : 'Create'

  const { value } = useAsync(async () => {
    if (!companyId) {
      return {}
    }
    const snap = await db.collection('company').doc(companyId).get()
    const data = snap.data()
    setUrl((data?.logo?.length && data?.logo[0]?.url) || '')
    setFiles(data?.logo ?? [])
    return {
      ...data,
    }
  }, [companyId])

  const handleSubmit = async (formData) => {
    try {
      setBtnLoading(true)
      const data = {
        ...formData,
        date: firebaseApp.firestore.FieldValue.serverTimestamp(),
        logo: [{
          uid: files[0].uid,
          name: files[0].name,
          type: files[0].type,
          lastModified: files[0].lastModified,
          url: url
        }]
      }

      let doc = db.collection('company').doc()
      if (companyId) {
        doc = db.collection('company').doc(companyId)
      }
      await doc.set(data, {
        merge: true
      })
      setBtnLoading(false)
      navigate('/admin/master-data/company/list')
      return Promise.resolve()
    } catch (error) {
      setBtnLoading(false)
      return Promise.reject(error)
    }
  }

  const customRequestFile = async ({ onError, onSuccess, file }) => {
    try {
      const storageRef = storage.ref('logo').child(`${file.uid}-${file.name}`)
      await storageRef.put(file, {
        contentType: file.type
      })
      let uri = await storageRef.getDownloadURL()
      uri = uri.split('&token')[0]

      setUrl(uri)
      onSuccess(null, uri)
    } catch (e) {
      onError(e)
    }
  }

  const handleChange = ({ fileList: newFileList }) => setFiles(newFileList)

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewImage(file.url || file.preview)
    setPreviewOpen(true)
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1))
  }

  const handleCancel = () => setPreviewOpen(false)

  if (!value) return <Skeleton />
  return (
    <AntdForm
      layout="vertical"
      initialValues={value}
      onFinish={handleSubmit}
    >
      <Card
        title="Company Information"
        headStyle={{ fontWeight: 'bold', fontSize: '20px', lineHeight: '23px', backgroundColor: '#CFE2FF', color: '#154D9F' }}
        bodyStyle={{ boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)', borderRadius: '0 0 10px 10px' }}
      >
        <Row>
          <Col span={3}></Col>
          <Col span={18}>
            <Row gutter={[0, 24]}>
              <div className="content-title">Company Information</div>
            </Row>
            <Row gutter={[32, 0]}>
              <Col span={12}>
                <AntdForm.Item name="name" label="Company Name" rules={[{ required: true, message: 'Please input Company Name!' }]}>
                  <Input />
                </AntdForm.Item>
              </Col>
              <Col span={12}>
                <AntdForm.Item name="abbr" label="Company Abbreviation" rules={[{ required: true, message: 'Please input Company Abbreviation!' }]}>
                  <Input />
                </AntdForm.Item>
              </Col>
            </Row>
            <Row gutter={[32, 0]}>
              <Col span={12}>
                <AntdForm.Item name="address" label="Company Address" rules={[{ required: true, message: 'Please input Company Address!' }]}>
                  <Input.TextArea autoSize={true} />
                </AntdForm.Item>
              </Col>
              <Col span={12}>
                <AntdForm.Item name="email" label="Company Email" rules={[{ type: 'email', message: 'The input is not valid Email!' }, { required: true, message: 'Please input your Email!' }]}>
                  <Input />
                </AntdForm.Item>
              </Col>
            </Row>
            <Row gutter={[32, 0]}>
              <Col span={12}>
                <AntdForm.Item name="logo" label="Company Logo" rules={[{ required: true, message: 'Please input Company Logo!' }]}>
                  <Upload
                    accept='.webp, .png, .jpg, .jpeg'
                    listType="picture-card"
                    fileList={files}
                    onPreview={handlePreview}
                    onChange={handleChange}
                    onRemove={() => setFiles([])}
                    customRequest={customRequestFile}
                  >
                    {files.length ? null : (
                      <button style={{ border: 0, background: 'none', }} type="button" >
                        <PlusOutlined />
                        <div style={{ marginTop: 8, }}>Upload</div>
                      </button>
                    )}
                  </Upload>
                </AntdForm.Item>
              </Col>
              <Col span={12}></Col>
            </Row>
            <Row gutter={[0, 24]}>
              <div className="content-title">Bamking Information</div>
            </Row>
            <Row gutter={[32, 0]}>
              <Col span={12}>
                <AntdForm.Item name="bankname" label="Bank" rules={[{ required: true, message: 'Please input Bank!' }]}>
                  <Input />
                </AntdForm.Item>
              </Col>
              <Col span={12}>
                <AntdForm.Item name="bankbranch" label="Branch" rules={[{ required: true, message: 'Please input Branch!' }]}>
                  <Input />
                </AntdForm.Item>
              </Col>
            </Row>
            <Row gutter={[32, 0]}>
              <Col span={12}>
                <AntdForm.Item name="bankaddress" label="Address" rules={[{ required: true, message: 'Please input Address!' }]}>
                  <Input.TextArea autoSize={true} />
                </AntdForm.Item>
              </Col>
              <Col span={12}>
                <AntdForm.Item name="bankaccount" label="Account Name" rules={[{ required: true, message: 'Please input Account Name!' }]}>
                  <Input />
                </AntdForm.Item>
              </Col>
            </Row>
            <Row gutter={[32, 0]}>
              <Col span={12}>
                <AntdForm.Item name="banknumber" label="Account Number" rules={[{ required: true, message: 'Please input Account Number!' }]}>
                  <Input />
                </AntdForm.Item>
              </Col>
              <Col span={12}>
                <AntdForm.Item name="bankswift" label="SWIFT NO." rules={[{ required: true, message: 'Please input SWIFT NO.!' }]}>
                  <Input />
                </AntdForm.Item>
              </Col>
            </Row>
            <Row justify="center" style={{ marginTop: '20px' }}>
              <Button style={{ height: '40px', width: '300px', borderRadius: '8px' }} type="primary" loading={btnLoading} htmlType="submit">{mode}</Button>
            </Row>
          </Col>
          <Col span={3}></Col>
        </Row>
      </Card>
      <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img alt="logo" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </AntdForm>
  )
}

export default Form

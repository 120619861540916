import React from 'react'
import { Outlet } from 'react-router-dom'

import './CreateUserPage.less'

const CreateUserPage = () => {
  return (
    <div className='user'>
      <Outlet />
    </div>
  )
}

export default CreateUserPage

import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAsync } from 'react-use'

import { Button, Select, Skeleton, Table, Pagination, Space, Card, Row, Col, Badge, Divider, Dropdown, Input } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faPlay, faTruckLoading, faTrashAlt, faPlus } from '@fortawesome/free-solid-svg-icons'

import _ from 'lodash'
import dayjs from 'dayjs'

import { useAuth } from '../../contexts/AuthContextProvider'
import StatusConfirmModal from './Components/StatusConfirmModal'
import BookingConfirmedPdf from './Components/BookingConfirmedPdf'
import ExportListPage from './Components/ExportListPage'

const statusText = (roleFilter, status) => {
  // return (roleFilter === 'purchasing') ? 
  //   status : 
  //   (['Waiting for Planner'].indexOf(status) !== -1) ? 
  //     'New' : 
  //     (['Booking Request', 'Waiting for Booking Confirm', 'Booking Confirmed', 'Warning', 'Problem'].indexOf(status) !== -1) 
  //       ? 'Processing' : 
  //       status
  if (roleFilter === 'purchasing') {
    return status
  }
  if (status === 'Waiting for Planner') {
    return 'New'
  }
  if (status === 'Booking Confirmed') {
    return 'Booking Confirmed'
  }
  if (['Booking Request', 'Waiting for Booking Confirm', 'Warning', 'Problem'].indexOf(status) !== -1) {
    return 'Processing'
  }
  return status
}

const statusColor = {
  purchasing: {
    'Waiting for Planner': '#F6C001',
    'Booking Request': '#EA3939',
    'Waiting for Booking Confirm': '#F6C001',
    'Booking Confirmed': '#F6C001',
    'Warning': '#EA3939',
    'Problem': '#EA3939',
    'Loaded': '#67C23A',
    'Shipped': '#67C23A',
    'Holded': '#212121',
    'Canceled': '#212121'
  },
  planner: {
    'Waiting for Planner': '#EA3939',
    'Booking Request': '#F6C001',
    'Waiting for Booking Confirm': '#F6C001',
    'Booking Confirmed': '#F6C001',
    'Warning': '#F6C001',
    'Problem': '#F6C001',
    'Loaded': '#67C23A',
    'Shipped': '#67C23A',
    'Holded': '#212121',
    'Canceled': '#212121'
  }
}

const filterStatus = {
  purchasing: {
    'Waiting for Planner': ['Waiting for Planner'],
    'Booking Request': ['Booking Request'],
    'Waiting for Booking Confirm': ['Waiting for Booking Confirm'],
    'Booking Confirmed': ['Booking Confirmed'],
    'Warning': ['Warning'],
    'Problem': ['Problem'],
    'HoldedClosed': ['Holded', 'Canceled'],
    'Loaded': ['Loaded'],
    'Shipped': ['Shipped']
    // processing: ['Waiting for Planner', 'Booking Request', 'Waiting for Booking Confirm', 'Booking Confirmed', 'Warning', 'Problem'],
    // holdedClosed: ['Holded', 'Canceled'],
    // loaded: ['Loaded'],
    // shipped: ['Shipped'],
  },
  planner: {
    'Waiting for Planner': ['Waiting for Planner'],
    'Booking Request': ['Booking Request'],
    'Waiting for Booking Confirm': ['Waiting for Booking Confirm'],
    'Booking Confirmed': ['Booking Confirmed'],
    'Warning': ['Warning'],
    'Problem': ['Problem'],
    'HoldedClosed': ['Holded', 'Canceled'],
    'Loaded': ['Loaded'],
    'Shipped': ['Shipped']
    // new: ['Waiting for Planner'],
    // processing: ['Booking Request', 'Waiting for Booking Confirm', 'Booking Confirmed', 'Warning', 'Problem'],
    // holdedClosed: ['Holded', 'Canceled'],
    // loaded: ['Loaded'],
    // shipped: ['Shipped']
  }
}

const statusIndex = {
  purchasing: {
    'Problem': 10,
    'Warning': 9,
    'Booking Request': 8,
    'Booking Confirmed': 7,
    'Waiting for Booking Confirm': 6,
    'Waiting for Planner': 5,
    'Loaded': 4,
    'Shipped': 3,
    'Holded': 2,
    'Canceled': 1
  },
  planner: {
    'Waiting for Planner': 10,
    'Booking Request': 9,
    'Waiting for Booking Confirm': 8,
    'Booking Confirmed': 7,
    'Problem': 6,
    'Warning': 5,
    'Loaded': 4,
    'Shipped': 3,
    'Holded': 2,
    'Canceled': 1
  }
}

// const statusToolTip = {
//   purchasing: {
//     'Waiting for Planner': 'รอ Planner อัพเดท RTN Date',
//     'Booking Request': 'คุณต้องทำการจองเรือ',
//     'Waiting for Booking Confirm': 'รอ Confirm จากเรือ เมื่อได้รับเอกสารยืนยันให้นำมากรอกในระบบ',
//     'Booking Confirmed': 'รอโหลดสินค้าลงเรือ ถ้าโหลดสินค้าแล้วให้กดปุ่มสีเขียวด้านหลัง เพื่อยืนยันจำนวนสินค้า',
//     'Warning': 'RTN Date ถูกเปลี่ยน โปรดเช็คว่าต้องเปลี่ยนเที่ยวเรือหรือไม่ หรือถ้าโหลดสินค้าแล้วให้กดปุ่มสีเขียวด้านหลัง เพื่อยืนยันจำนวนสินค้า',
//     'Problem': 'RTN Date ถูกเปลี่ยน ต้องทำการเปลี่ยนรอบเรือ',
//     'Loaded': 'สินค้าโหลดลงเรือเรียบร้อยแล้ว',
//     'Shipped': 'สินค้าถูกส่งออกจากท่าเรือแล้ว',
//     'Holded': '',
//     'Canceled': ''
//   },
//   planner: {
//     'Waiting for Planner': 'PO นี้กำลังรอ RTN Date จากคุณ',
//     'Booking Request': 'คุณสามารถเปลี่ยนแปลง RTN Date ใหม่ได้ถ้าคุณต้องการ',
//     'Waiting for Booking Confirm': 'คุณสามารถเปลี่ยนแปลง RTN Date ใหม่ได้ถ้าคุณต้องการ',
//     'Booking Confirmed': 'คุณสามารถเปลี่ยนแปลง RTN Date ใหม่ได้ถ้าคุณต้องการ',
//     'Warning': 'คุณสามารถเปลี่ยนแปลง RTN Date ใหม่ได้ถ้าคุณต้องการ',
//     'Problem': 'คุณสามารถเปลี่ยนแปลง RTN Date ใหม่ได้ถ้าคุณต้องการ',
//     'Loaded': 'สินค้าจาก PO นี้ได้ถูกโหลดลงเรือเรียบร้อยแล้ว',
//     'Shipped': '',
//     'Holded': '',
//     'Canceled': ''
//   }
// }

const piStatusObj = {
  deposit: { text: 'Deposit', color: '#FF0000' },
  deposited: { text: 'Deposit', color: '#67C23A' },
  balance: { text: 'Balance', color: '#FF0000' },
  balanced: { text: 'Balance', color: '#67C23A' }
}

const List = () => {
  const navigate = useNavigate()
  const { db, role, companyId } = useAuth()

  const [initValue, setInitValue] = useState([])
  const [value, setValue] = useState([])
  const [total, setTotal] = useState(0)
  const [filter, setFilter] = useState('All')
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(1000)
  const [holdedModalVisible, setHoldedModalVisible] = useState(false)
  const [activeModalVisible, setActiveModalVisible] = useState(false)
  const [canceledModalVisible, setCanceledModalVisible] = useState(false)
  const [actionId, setActionId] = useState()
  const [loading, setLoading] = useState(false)
  const [searchPo, setsearchPo] = useState()
  const ref = db.collection('po')

  const roleFilter = (role !== 'Planner') ? 'purchasing' : 'planner'

  useAsync(async () => {
    const snap = await db.collection('po').where('cid', '==', companyId).orderBy('createdAt', 'desc').limit(1000).get()
    const result = snap.docs.reduce((acc1, o, i1) => {
      const data = o.data()
      return [...acc1, {
        ..._.get(data, 'products[0]', {}),
        id: o.id,
        productId: _.get(data, 'products[0].id', ''),
        key: `${i1}0`,
        invNo: data.invNo,
        status: data.status,
        piStatus: data?.piStatus,
        port: data.port,
        receivedDate: data.receivedDate,
        deliveryDate: data.deliveryDate,
        consigneeId: data.consigneeId,
        finalDestination: data.finalDestination,
        portOfDischarge: data.portOfDischarge,
        portOfLoading: data.portOfLoading,
        transhipmentPort: data.transhipmentPort,
        poNo: data.poNo,
        vesselName: data.vesselName,
        volume: data.volume,
        motherVesselName: data.motherVesselName,
        containerNo: data.containerNo,
        products: data.products,
        country: data.country,
        hbl: data.hbl,
        blStatus: data.blStatus,
        cyDate: data.cyDate,
        rtnDate: data.rtnDate,
        etd: data.etd,
        eta: data.eta,
        bookingRefNo: data.bookingRefNo,
        loadingType: data.loadingType,
        closingDate: data.closingDate,
        closingTime: data.closingTime,
        index: data.createdAt.seconds * (statusIndex[roleFilter][data.status] || 1),
        children: ((data?.products ?? []).length < 2) ? null : data.products.reduce((acc2, p, i2) => {
          return (i2 > 0) ? [...acc2, {
            ...p,
            id: o.id,
            productId: p.id,
            key: `${i1}${i2}`,
            invNo: data.invNo,
            poNo: data.poNo,
            status: data.status,
            piStatus: data?.piStatus,
            port: data.port,
            receivedDate: data.receivedDate,
            deliveryDate: data.deliveryDate,
            country: data.country,
            hbl: data.hbl,
            blStatus: data.blStatus,
            cyDate: data.cyDate,
            rtnDate: data.rtnDate,
            loadingType: data.loadingType,
            closingDate: data.closingDate,
            closingTime: data.closingTime,
            etd: data.etd,
            eta: data.eta
          }] : [...acc2]
        }, [])
      }]
    }, [])

    const list = result.sort((x, y) => y.index - x.index)
    setInitValue(list)
  }, [db, roleFilter, companyId])

  // useEffect(() => {
  //   let snapShot = () => { }
  //   (async () => {
  //     snapShot = db.collection('po').orderBy('createdAt', 'desc').limit(1000).onSnapshot((snap) => {
  //       // snapShot = db.collection('po').orderBy('createdAt', 'desc').onSnapshot((snap) => {
  //       const result = snap.docs.reduce((acc1, o, i1) => {
  //         const data = o.data()
  //         return [...acc1, {
  //           ..._.get(data, 'products[0]', {}),
  //           id: o.id,
  //           productId: _.get(data, 'products[0].id', ''),
  //           key: `${i1}0`,
  //           invNo: data.invNo,
  //           status: data.status,
  //           port: data.port,
  //           consigneeId: data.consigneeId,
  //           finalDestination: data.finalDestination,
  //           portOfDischarge: data.portOfDischarge,
  //           portOfLoading: data.portOfLoading,
  //           transhipmentPort: data.transhipmentPort,
  //           poNo: data.poNo,
  //           vesselName: data.vesselName,
  //           volume: data.volume,
  //           motherVesselName: data.motherVesselName,
  //           containerNo: data.containerNo,
  //           products: data.products,
  //           country: data.country,
  //           hbl: data.hbl,
  //           blStatus: data.blStatus,
  //           cyDate: data.cyDate,
  //           rtnDate: data.rtnDate,
  //           etd: data.etd,
  //           eta: data.eta,
  //           bookingRefNo: data.bookingRefNo,
  //           loadingType: data.loadingType,
  //           closingDate: data.closingDate,
  //           closingTime: data.closingTime,
  //           index: data.createdAt.seconds * (statusIndex[roleFilter][data.status] || 1),
  //           children: _.get(data, 'products', []).reduce((acc2, p, i2) => {
  //             return (i2 > 0) ? [...acc2, {
  //               ...p,
  //               id: o.id,
  //               productId: p.id,
  //               key: `${i1}${i2}`,
  //               invNo: data.invNo,
  //               poNo: data.poNo,
  //               status: data.status,
  //               port: data.port,
  //               country: data.country,
  //               hbl: data.hbl,
  //               blStatus: data.blStatus,
  //               cyDate: data.cyDate,
  //               rtnDate: data.rtnDate,
  //               loadingType: data.loadingType,
  //               closingDate: data.closingDate,
  //               closingTime: data.closingTime,
  //               etd: data.etd,
  //               eta: data.eta
  //             }] : [...acc2]
  //           }, [])
  //         }]
  //       }, [])

  //       const list = result.sort((x, y) => y.index - x.index)
  //       setInitValue(list)
  //     }, err => {
  //       console.log(err)
  //     })
  //   })()
  //   return () => {
  //     snapShot()
  //   }
  // }, [db, roleFilter])

  useEffect(() => {
    const result = initValue.reduce((acc, o) => {
      return (
        (
          (filter === 'All') ||
          (filterStatus[roleFilter][filter].indexOf(o.status) !== -1)
        ) &&
        (
          (!searchPo) ||
          (((o?.poNo ?? '').toLowerCase()).indexOf(searchPo.toLowerCase()) !== -1) ||
          (((o?.invNo ?? '').toLowerCase()).indexOf(searchPo.toLowerCase()) !== -1)
        )
      ) ? [...acc, o] : acc
    }, [])
    const start = (currentPage - 1) * pageSize
    const end = start + pageSize
    const list = (pageSize > 0) ? result.filter((o, index) => index >= start && index < end) : result
    setValue(list)
    setTotal(result.length)
  }, [initValue, filter, currentPage, pageSize, searchPo, roleFilter])

  const handleSelect = (data) => {
    setFilter(data)
  }
  const handleSearchPO = (data) => {
    setsearchPo(data)
  }

  const onChange = (page, pageSize) => {
    const no = (page === 0) ? 1 : page
    setCurrentPage(no)
    setPageSize(pageSize)
  }

  const handleHolded = (data) => {
    setActionId(data)
    setHoldedModalVisible(true)
  }

  const handleActive = (data) => {
    setActionId(data)
    setActiveModalVisible(true)
  }

  const handleCanceled = (data) => {
    setActionId(data)
    setCanceledModalVisible(true)
  }

  const onHoldSubmit = async () => {
    setLoading(true)
    await ref.doc(actionId).set({
      prevStatus: 'Holded'
    }, {
      merge: true
    })
    setLoading(false)
    setHoldedModalVisible(false)
    setActionId(null)
  }

  const onActiveSubmit = async () => {
    setLoading(true)
    await ref.doc(actionId).set({
      prevStatus: null
    }, {
      merge: true
    })
    setLoading(false)
    setActiveModalVisible(false)
    setActionId(null)
  }

  const onCancelSubmit = async () => {
    setLoading(true)
    await ref.doc(actionId).set({
      prevStatus: 'Canceled'
    }, {
      merge: true
    })
    setLoading(false)
    setActionId(null)
    setCanceledModalVisible(false)
  }

  const onCancel = () => {
    setHoldedModalVisible(false)
    setActiveModalVisible(false)
    setCanceledModalVisible(false)
    setActionId(null)
  }

  const handleMenuClick = (e, id) => {
    if (e.key === 'hold') {
      handleHolded(id)
    } else if (e.key === 'cancel') {
      handleCanceled(id)
    }
  }

  const columns = [
    {
      title: 'Status',
      dataIndex: 'status',
      width: 120,
      fixed: 'left',
      roleFilter: ['purchasing', 'planner'],
      sorter: {
        compare: (a, b) => a.status.localeCompare(b.status),
        multiple: 6
      },
      render: status => (
        <span style={{ color: (statusColor[roleFilter][status] || '#212121') }}>{statusText(roleFilter, status)}</span>
        //   <Tooltip placement='top' title={statusToolTip[roleFilter][status] || ''} color={statusColor[roleFilter][status] || '#212121'} key={statusColor[roleFilter][status] || '#212121'} overlayStyle={{ visibility: (role === 'Customer') ? 'visible' : 'hidden' }}>
        //   <span style={{ color: (statusColor[roleFilter][status] || '#212121') }}>{statusText(roleFilter, status)}</span>
        // </Tooltip>
      )
    },
    {
      title: 'PI. Status',
      dataIndex: 'piStatus',
      width: 130,
      fixed: 'left',
      roleFilter: ['purchasing', 'planner'],
      sorter: {
        compare: (a, b) => a.piStatus.localeCompare(b.piStatus),
        multiple: 6
      },
      render: (data, _) => (
        (data === 'no') ? 'Waiting for PI.' : (
          <>
            <span style={{ color: (piStatusObj[data] && piStatusObj[data]['color']) || 'black' }}>&#x25cf;&#160;&#160;</span>{(piStatusObj[data] && piStatusObj[data]['text']) || 'Waiting for PI.'}
          </>
        )
      )
    },
    {
      title: 'Received Date',
      dataIndex: 'receivedDate',
      width: 100,
      fixed: 'left',
      roleFilter: ['purchasing', 'planner'],
      render: text => (
        <>
          {(text && text.seconds) ? dayjs(text.seconds * 1000).format('D-MMM') : null}
        </>
      ),
      sorter: {
        compare: (a, b) => (a.receivedDate && b.receivedDate) ? a.receivedDate.seconds - b.receivedDate.seconds : -9999999999,
        multiple: 1
      }
    },
    {
      title: 'INV No.',
      dataIndex: 'invNo',
      width: 170,
      fixed: 'left',
      ellipsis: true,
      roleFilter: ['purchasing', 'planner'],
      sorter: {
        compare: (a, b) => a.invNo.localeCompare(b.invNo),
        multiple: 5
      },
      // render: (text) => <span style={{wordBreak: 'break-word'}}>{text}</span>
    },
    {
      title: 'PO No.',
      dataIndex: 'poNo',
      width: 170,
      fixed: 'left',
      roleFilter: ['purchasing', 'planner'],
      sorter: {
        compare: (a, b) => a.poNo.localeCompare(b.poNo),
        multiple: 4
      }
    },
    {
      title: 'Item',
      dataIndex: 'productRef',
      width: 200,
      roleFilter: ['purchasing', 'planner'],
      sorter: {
        compare: (a, b) => a.productRef.localeCompare(b.productRef),
        multiple: 3
      }
    },
    {
      title: 'Quantity (BAG)',
      dataIndex: 'quantityBAG',
      width: 100,
      roleFilter: ['purchasing'],
      render: text => (
        <>
          {(text && !isNaN(text)) ? new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(+text) : ''}
        </>
      ),
      sorter: {
        compare: (a, b) => parseFloat(a.quantityBAG) - parseFloat(b.quantityBAG),
        multiple: 1
      }
    },
    {
      title: 'Quantity (CTN)',
      dataIndex: 'quantityCTN',
      width: 100,
      roleFilter: ['purchasing', 'planner'],
      render: text => (
        <>
          {(text && !isNaN(text)) ? new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(+text) : ''}
        </>
      ),
      sorter: {
        compare: (a, b) => parseFloat(a.quantityCTN) - parseFloat(b.quantityCTN),
        multiple: 1
      }
    },
    {
      title: 'Country',
      dataIndex: 'country',
      width: 100,
      roleFilter: ['purchasing', 'planner'],
      sorter: {
        compare: (a, b) => a.country.localeCompare(b.country),
        multiple: 1
      }
    },
    {
      title: 'Port',
      dataIndex: 'port',
      width: 150,
      roleFilter: ['purchasing', 'planner'],
      sorter: {
        compare: (a, b) => a.port.localeCompare(b.port),
        multiple: 1
      }
    },
    {
      title: 'Delevery Date',
      dataIndex: 'deliveryDate',
      width: 80,
      roleFilter: ['purchasing', 'planner'],
      render: text => (
        <>
          {(text && text.seconds) ? dayjs(text.seconds * 1000).format('D-MMM') : null}
        </>
      ),
      sorter: {
        compare: (a, b) => (a.deliveryDate && b.deliveryDate) ? a.deliveryDate.seconds - b.deliveryDate.seconds : -9999999999,
        multiple: 1
      }
    },
    {
      title: 'HBL',
      dataIndex: 'hbl',
      width: 100,
      roleFilter: ['purchasing'],
      sorter: {
        compare: (a, b) => a.hbl.localeCompare(b.hbl),
        multiple: 1
      }
    },
    // {
    //   title: 'BL Status',
    //   dataIndex: 'blStatus',
    //   width: 100,
    //   roleFilter: ['purchasing'],
    //   sorter: {
    //     compare: (a, b) => a.blStatus.localeCompare(b.blStatus),
    //     multiple: 1
    //   }
    // },
    {
      title: 'Weight per Bag (Gram)',
      dataIndex: 'weight',
      width: 100,
      roleFilter: ['planner'],
      render: text => (
        <>
          {(text && !isNaN(text)) ? new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(+text) : ''}
        </>
      ),
      sorter: {
        compare: (a, b) => parseFloat(a.weight) - parseFloat(b.weight),
        multiple: 1
      }
    },
    {
      title: 'Bag per Carton',
      dataIndex: 'bagPerCarton',
      width: 100,
      roleFilter: ['planner'],
      render: text => (
        <>
          {(text && !isNaN(text)) ? new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(+text) : ''}
        </>
      ),
      sorter: {
        compare: (a, b) => parseFloat(a.bagPerCarton) - parseFloat(b.bagPerCarton),
        multiple: 1
      }
    },
    {
      title: 'Loading Type',
      dataIndex: 'loadingType',
      width: 100,
      roleFilter: ['planner'],
      sorter: {
        compare: (a, b) => a.loadingType.localeCompare(b.loadingType),
        multiple: 1
      }
    },
    // {
    //   title: 'CY Date',
    //   dataIndex: 'cyDate',
    //   width: 80,
    //   roleFilter: ['purchasing', 'planner'],
    //   render: text => (
    //     <>
    //       {(text && text.seconds) ? dayjs(text.seconds * 1000).format('D-MMM') : null}
    //     </>
    //   ),
    //   sorter: {
    //     compare: (a, b) => (a.cyDate && b.cyDate) ? a.cyDate.seconds - b.cyDate.seconds : -9999999999,
    //     multiple: 1
    //   }
    // },
    {
      title: 'RTN Date',
      dataIndex: 'rtnDate',
      width: 80,
      roleFilter: ['purchasing', 'planner'],
      render: text => (
        <>
          {(text && text.seconds) ? dayjs(text.seconds * 1000).format('D-MMM') : null}
        </>
      ),
      sorter: {
        compare: (a, b) => (a.rtnDate && b.rtnDate) ? a.rtnDate.seconds - b.rtnDate.seconds : -9999999999,
        multiple: 1
      }
    },
    {
      title: 'Closing Date',
      dataIndex: 'closingDate',
      width: 80,
      roleFilter: ['planner'],
      render: text => (
        <>
          {(text && text.seconds) ? dayjs(text.seconds * 1000).format('D-MMM') : null}
        </>
      ),
      sorter: {
        compare: (a, b) => (a.closingDate && b.closingDate) ? a.closingDate.seconds - b.closingDate.seconds : -9999999999,
        multiple: 1
      }
    },
    {
      title: 'Closing Time',
      dataIndex: 'closingTime',
      width: 80,
      roleFilter: ['planner'],
      render: text => (
        <>
          {(text && text.seconds) ? dayjs(text.seconds * 1000).format('HH:mm') : null}
        </>
      ),
      sorter: {
        compare: (a, b) => (a.closingTime && b.closingTime) ? a.closingTime.seconds - b.closingTime.seconds : -9999999999,
        multiple: 1
      }
    },
    {
      title: 'ETD',
      dataIndex: 'etd',
      width: 80,
      roleFilter: ['purchasing', 'planner'],
      render: text => (
        <>
          {(text && text.seconds) ? dayjs(text.seconds * 1000).format('D-MMM') : null}
        </>
      ),
      sorter: {
        compare: (a, b) => (a.etd && b.etd) ? a.etd.seconds - b.etd.seconds : -9999999999,
        multiple: 1
      }
    },
    {
      title: 'ETA',
      dataIndex: 'eta',
      width: 80,
      roleFilter: ['purchasing', 'planner'],
      render: text => (
        <>
          {(text && text.seconds) ? dayjs(text.seconds * 1000).format('D-MMM') : null}
        </>
      ),
      sorter: {
        compare: (a, b) => (a.eta && b.eta) ? a.eta.seconds - b.eta.seconds : -9999999999,
        multiple: 1
      }
    },
    {
      title: 'Operation',
      dataIndex: 'operation',
      key: 'operation',
      fixed: 'right',
      width: (roleFilter === 'purchasing') ? 200 : 100,
      roleFilter: ['purchasing', 'planner'],
      render: (text, record) => (
        <Space size="middle">
          {(['Waiting for Planner', 'Booking Request', 'Waiting for Booking Confirm', 'Booking Confirmed', 'Warning', 'Problem', 'Loaded', 'Shipped'].indexOf(record.status) !== -1) && (
            <Badge
              count={(
                (['Booking Request', 'Problem'].indexOf(record.status) !== -1 && ['Financial', 'Purchasing'].indexOf(role) !== -1) ||
                (['Waiting for Planner'].indexOf(record.status) !== -1 && role === 'Planner')
              ) ? '!' : ''}
            >
              <Button type="text" size="middle" style={{ backgroundColor: '#154D9F', color: '#FFF' }} icon={<FontAwesomeIcon icon={faSearch} />} onClick={() => navigate(`/admin/purchase-order/${record.id}`)}></Button>
            </Badge>
          )}
          {(['Loaded', 'Shipped'].indexOf(record.status) !== -1 && ['Planner'].indexOf(role) === -1) && (
            <BookingConfirmedPdf record={record}></BookingConfirmedPdf>
          )}
          {(['Financial', 'Purchasing', 'Admin'].indexOf(role) !== -1 && ['Booking Confirmed', 'Warning'].indexOf(record.status) !== -1 && record.bookingRefNo) && (
            <Button type="text" size="middle" style={{ backgroundColor: '#67C23A', color: '#FFF' }} icon={<FontAwesomeIcon icon={faTruckLoading} />} onClick={() => navigate(`/admin/purchase-order/${record.id}/confirm`)}></Button>
          )}
          {(['Waiting for Planner', 'Booking Request', 'Waiting for Booking Confirm', 'Booking Confirmed', 'Warning', 'Problem', 'Loaded', 'Shipped'].indexOf(record.status) !== -1 && ['Planner', 'Customer'].indexOf(role) === -1) && (
            <Dropdown
              placement="bottom"
              arrow
              menu={{
                items: [
                  { key: 'hold', label: 'Hold' },
                  { key: 'cancel', label: 'Cancel' }
                ],
                onClick: (e) => handleMenuClick(e, record.id)
              }}>
              <Button type="text" size="middle" style={{ backgroundColor: '#EA3939', color: '#FFF' }} icon={<FontAwesomeIcon icon={faTrashAlt} />}></Button>
            </Dropdown>
          )}
          {(['Holded'].indexOf(record.status) !== -1) && (
            <Button type="text" size="middle" style={{ backgroundColor: '#67C23A', color: '#FFF' }} icon={<FontAwesomeIcon icon={faPlay} />} onClick={() => { handleActive(record.id) }}></Button>
          )}
        </Space>
      )
    }
  ]

  // const exportTable = async () => {
  //   const options = {
  //     fieldSeparator: ',',
  //     quoteStrings: '"',
  //     decimalSeparator: '.',
  //     showLabels: true,
  //     showTitle: false,
  //     useTextFile: false,
  //     useBom: true,
  //     useKeysAsHeaders: true,
  //     filename: `Purchase Order List_${dayjs().format('YYYY-MM-DD')}`
  //   }
  //   try {
  //     setBtnLoading(true)
  //     const result = await axios({
  //       method: 'post',
  //       url: `${config.apiPath}/poApi-poList`,
  //       headers: {
  //         'Content-Type': 'application/json'
  //       },
  //       data: {
  //         role: role
  //       }
  //     })
  //     const data = result.data.data.map((o) => {
  //       return (roleFilter === 'purchasing') ? {
  //         'Status': statusText(roleFilter, o.status),
  //         'INV No.': o.invNo || '',
  //         'Item': o.productRef || '',
  //         'Quantity (BAG)': o.quantityBAG || '',
  //         'Quantity (CTN)': o.quantityCTN || '',
  //         'Country': o.country || '',
  //         'Port': o.port || '',
  //         'HBL': o.hbl || '',
  //         'BL Status': o.blStatus || '',
  //         'CY Date': (o.cyDate) ? dayjs(o.cyDate._seconds * 1000).format('YYYY/MM/DD') : '',
  //         'RTN Date': (o.rtnDate) ? dayjs(o.rtnDate._seconds * 1000).format('YYYY/MM/DD') : '',
  //         'ETD': (o.etd) ? dayjs(o.etd._seconds * 1000).format('YYYY/MM/DD') : '',
  //         'ETA': (o.eta) ? dayjs(o.eta._seconds * 1000).format('YYYY/MM/DD') : ''
  //       } : {
  //         'Status': statusText(roleFilter, o.status),
  //         'INV No.': o.invNo || '',
  //         'PO No.': o.poNo || '',
  //         'Item': o.productRef || '',
  //         'Quantity (CTN)': o.quantityCTN || '',
  //         'Country': o.country || '',
  //         'Port': o.port || '',
  //         'Weight per Bag (Gram)': o.weight || '',
  //         'Bag per Carton': o.bagPerCarton || '',
  //         'Loading Type': o.loadingType || '',
  //         'CY Date': (o.cyDate) ? dayjs(o.cyDate._seconds * 1000).format('YYYY/MM/DD') : '',
  //         'RTN Date': (o.rtnDate) ? dayjs(o.rtnDate._seconds * 1000).format('YYYY/MM/DD') : '',
  //         'Closing Date': (o.closingDate) ? dayjs(o.closingDate._seconds * 1000).format('YYYY/MM/DD') : '',
  //         'Closing Time': (o.closingTime) ? dayjs(o.closingTime._seconds * 1000).format('HH:mm') : '',
  //         'ETD': (o.etd) ? dayjs(o.etd._seconds * 1000).format('YYYY/MM/DD') : '',
  //         'ETA': (o.eta) ? dayjs(o.eta._seconds * 1000).format('YYYY/MM/DD') : ''
  //       }
  //     })
  //     if (data.length) {
  //       const csvExporter = new ExportToCsv(options)
  //       csvExporter.generateCsv(data)
  //     }
  //     setBtnLoading(false)
  //     return Promise.resolve()
  //   } catch (error) {
  //     setBtnLoading(false)
  //     return Promise.reject(error)
  //   }
  // }

  if (!value) return <Skeleton />
  return (
    <>
      <Card
        title={`Purchase Order List`}
        headStyle={{ fontWeight: 'bold', fontSize: '20px', lineHeight: '23px', backgroundColor: '#CFE2FF', color: '#154D9F' }}
        bodyStyle={{ boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)', borderRadius: '0 0 10px 10px' }}>
        <Row justify="space-between" gutter={[24, 24]} style={{ marginBottom: 16 }}>
          <Col flex="auto">
            <Space direction="horizontal">
              <Select defaultValue="All" style={{ width: 250 }} listHeight={400} onChange={handleSelect}>
                <Select.Option value="All">All</Select.Option>
                <Select.Option value="Waiting for Planner">Waiting for Planner</Select.Option>
                <Select.Option value="Booking Request">Booking Request</Select.Option>
                <Select.Option value="Waiting for Booking Confirm">Waiting for Booking Confirm</Select.Option>
                <Select.Option value="Booking Confirmed">Booking Confirmed</Select.Option>
                <Select.Option value="Warning">Warning</Select.Option>
                <Select.Option value="Problem">Problem</Select.Option>
                <Select.Option value="HoldedClosed">Holded & Closed</Select.Option>
                <Select.Option value="Loaded">Loaded</Select.Option>
                <Select.Option value="Shipped">Shipped</Select.Option>

                {/* {(roleFilter === 'purchasing') ? (
                  <>
                    <Select.Option value="all">All</Select.Option>
                    <Select.Option value="processing">Processing</Select.Option>
                    <Select.Option value="holdedClosed">Holded & Closed</Select.Option>
                    <Select.Option value="loaded">Loaded</Select.Option>
                    <Select.Option value="shipped">Shipped</Select.Option>
                  </>
                ) : (
                  <>
                    <Select.Option value="all">All</Select.Option>
                    <Select.Option value="new">New</Select.Option>
                    <Select.Option value="processing">Processing</Select.Option>
                    <Select.Option value="holdedClosed">Holded & Closed</Select.Option>
                    <Select.Option value="loaded">Loaded</Select.Option>
                    <Select.Option value="shipped">Shipped</Select.Option>
                  </>
                )} */}
              </Select>
              <Input.Search placeholder="search PO No." style={{ marginTop: 8 }} onSearch={handleSearchPO} />
            </Space>
          </Col>
          <Col flex="100px">
            {(['Planner', 'Customer'].indexOf(role) === -1) && (
              <Button type="primary" size="middle" icon={<FontAwesomeIcon icon={faPlus} className="fa-icon-button" />} onClick={() => navigate('/admin/purchase-order/create')}>Create PO</Button>
            )}
          </Col>
        </Row>
        <Row>
          <Table
            columns={columns.filter((o) => o.roleFilter.indexOf(roleFilter) !== -1)}
            key='key'
            dataSource={value}
            // scroll={{x: true}}
            style={{ overflowX: 'auto' }}
            pagination={false}
            expandable={{
              expandIconColumnIndex: 3,
              // columnWidth: 170
            }}
            bordered={true} />
        </Row>
        <Divider style={{ margin: '16px 0', borderTop: 'none' }} />
        <Row justify="end" gutter={[0, 24]}>
          <Pagination
            showSizeChanger
            onChange={onChange}
            pageSizeOptions={[5, 10, 20, 50, 100, 1000]}
            defaultPageSize={1000}
            total={total} />
        </Row>
        <Row>
          <ExportListPage></ExportListPage>
        </Row>
      </Card>
      {(holdedModalVisible) ? (
        <StatusConfirmModal headerText="Hold Purchase Order" title="Are you sure you want to HOLD this Purchase Order?" buttonText="Hold" visible={holdedModalVisible} onSubmit={onHoldSubmit} onCancel={onCancel} loading={loading} />
      ) : (activeModalVisible) ? (
        <StatusConfirmModal headerText="Active Purchase Order" title="Are you sure you want to ACTIVE this Purchase Order?" buttonText="Active" visible={activeModalVisible} onSubmit={onActiveSubmit} onCancel={onCancel} loading={loading} />
      ) : (canceledModalVisible) ? (
        <StatusConfirmModal headerText="Cancel Purchase Order" title="Are you sure you want to CANCEL this Purchase Order?" buttonText="Cancel" visible={canceledModalVisible} onSubmit={onCancelSubmit} onCancel={onCancel} loading={loading} />
      ) : null}
    </>
  )
}

export default List

import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAsync } from 'react-use'

import { Button, Skeleton, Table, Space, Card } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

import dayjs from 'dayjs'

import { useAuth } from '../../contexts/AuthContextProvider'

const History = () => {
  const navigate = useNavigate()
  const { poId } = useParams()
  const { db } = useAuth()

  const { value } = useAsync(async () => {
    const snap = await db.collection('poHistory').where('poId', '==', poId).get()
    return snap.docs.map((doc, i) => {
      const data = doc.data()
      return {
        id: doc.id,
        key: i,
        rev: data.rev,
        date: data.updatedAt,
        time: data.updatedAt
      }
    }).sort((x, y) => y.rev - x.rev)
  })

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      render: text => (
        <>
          {(text && text.seconds) ? dayjs(text.seconds * 1000).format('YYYY-MM-DD') : null}
        </>
      ),
      sorter: {
        compare: (a, b) => (a.date && b.date) ? a.date.seconds - b.date.seconds : -9999999999,
        multiple: 2
      }
    },
    {
      title: 'Time',
      dataIndex: 'time',
      render: text => (
        <>
          {(text && text.seconds) ? dayjs(text.seconds * 1000).format('HH:mm') : null}
        </>
      ),
      sorter: {
        compare: (a, b) => (a.time && b.time) ? a.time.seconds - b.time.seconds : -9999999999,
        multiple: 1
      }
    },
    {
      title: 'Operation',
      key: 'operation',
      fixed: 'right',
      width: 100,
      render: (_, record) => (
        <Space align="baseline">
          <Button
            type="text"
            size="middle"
            style={{ backgroundColor: '#154D9F', color: '#FFF' }}
            icon={<SearchOutlined style={{ fontSize: '24px' }} />}
            onClick={() => navigate(`/admin/purchase-order/${poId}/history/${record.id}`)}>
          </Button>
        </Space>
      )
    }
  ]

  if (!value) return <Skeleton />
  return (
    <Card
      title={'History'}
      headStyle={{ fontWeight: 'bold', fontSize: '20px', lineHeight: '23px', backgroundColor: '#CFE2FF', color: '#154D9F' }}
      bodyStyle={{ boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)', borderRadius: '0 0 10px 10px' }}>
      <Table
        style={{ overflowX: 'auto' }}
        columns={columns}
        dataSource={value}
        sticky
        bordered={true} />
    </Card>
  )
}

export default History

import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Skeleton } from 'antd';

import _ from 'lodash'

import firebase, { auth, db, functions, storage } from '../utils/firebase'

import logo from '../assets/images/foodFarm.png'

const companyMaster = {
  name: 'FOOD FARM CO.,LTD.',
  address: '6/145 KRUNGTHEPKREETHA ROAD, THAPCHANG, SAPHANSUNG, BANKOK 10250 THAILAND',
  email: 'NARUMON@CROFTFOODS.CO.TH',
  bankname: 'KASIKORN BANK',
  bankbranch: 'THE MALL BANG KAPI',
  bankaddress: 'ROOM NO.2A-L10 2ND FLOOR THE MALL DEPARTMENT STORE BUILDING 3522 LAT PHRAO ROAD KHLONG CHAN BANG KAPI BANGKOK 10240 THAILAND\n(HEAD OFFICE ADDRESS) : NO.400/22 PHAHON YOTHIN ROAD, SAM SEN NAI, PHAYA THAI, BANGKOK 10400 THAILAND',
  bankaccount: 'FOOD FARM CO.,LTD',
  banknumber: '052-8-30039-1',
  bankswift: 'kasithbk',
  url: logo
}

const AuthContext = React.createContext({
  firebaseApp: firebase,
  auth,
  db,
  functions,
  storage,
  /**
   * @type {firebase.User}
   */
  user: auth().currentUser,
  role: '',
  companyId: ''
})

const AuthContextProvider = ({
  children
}) => {
  const navigate = useNavigate()
  const tempUser = auth().currentUser
  const [user, setUser] = useState(tempUser)
  const [role, setRole] = useState()
  const [companyId, setCompanyId] = useState()
  const [companySelected, setCompanySelected] = useState({})
  const [loaded, setLoaded] = useState(false)

  const updateCompanySelected = (v) => {
    setCompanyId(v.id)
    setCompanySelected(v)
  }

  const contextValue = {
    firebaseApp: firebase,
    auth,
    db,
    functions,
    storage,
    user,
    role,
    companyId,
    companySelected,
    companyMaster,
    updateCompanySelected
  }

  const setUserInfo = async (user) => {
    let userInfo = {}
    if (user && user.email) {
      const res = await db.collection('users').where('email', '==', user.email).limit(1).get()
      const [data] = res.docs.map(item => {
        return {
          id: item.id,
          ...item.data()
        }
      })

      if (data) {
        userInfo = {
          email: data.email,
          role: data.role,
          userName: data.userName,
          companies: data.companies
        }
      }
    }
    return userInfo
  }
  useEffect(() => {
    let firstUrl = window.location.pathname
    const unsubscribe = auth().onAuthStateChanged(async (userAuth) => {
      let userRole = ''
      if (userAuth) {
        const idToken = await userAuth.getIdTokenResult()
        userRole = _.get(idToken, 'claims.role', '')
        userRole = (userRole === 'Planner') ? 'Purchasing' : userRole
      }
      const userInfo = await setUserInfo(userAuth)
      if (userInfo.email) userAuth.userInfo = userInfo
      setUser(userAuth)
      setRole(userRole)
      setLoaded(true)
      const pathName = window.location.pathname
      if (userAuth && userAuth.email) {
        const url = new URL(window.location)
        const params = new URLSearchParams(url.search)
        const reUrl = params.get('reUrl')
        firstUrl = '/'
        if (reUrl) navigate(reUrl)
        else if (['/', '/login'].indexOf(pathName) !== -1) {
          if (['Planner', 'Financial', 'Purchasing', 'Customer'].indexOf(userRole) !== -1) {
            navigate('/admin/purchase-order')
          } else {
            navigate('/admin/product')
          }
        }
      }
      else if (['/forget-password'].indexOf(pathName) !== -1) {
        navigate(pathName)
      }
      else if (pathName.startsWith('/register/')) {
        navigate(pathName)
      }
      else {
        if (['/', '/login'].indexOf(firstUrl) === -1) navigate(`/login?reUrl=${encodeURIComponent(firstUrl)}`)
        else navigate(`/login`)
      }
    })
    return () => {
      unsubscribe()
    }
  }, [navigate])

  if (!loaded) return <Skeleton></Skeleton>
  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  )
}
const useAuth = () => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('Cannot useAuth outside AuthContextProvider!')
  }
  return context
}

export default AuthContextProvider
export {
  useAuth
}

import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAsync } from 'react-use'

import { Button, Card, Col, Form as AntdForm, Input, message, Row, Select, Skeleton } from 'antd'

import _ from 'lodash'

import { useAuth } from '../../contexts/AuthContextProvider'

const Form = () => {
  const navigate = useNavigate()
  const { db } = useAuth()
  const { userId } = useParams()
  const [btnLoading, setLoading] = useState(false)
  const [companies, setCompanies] = useState([])

  const { value, loading } = useAsync(async () => {
    const comSnap = await db.collection('company').get()
    const list = comSnap.docs.map(doc => ({
      ...doc.data(),
      id: doc.id,
    }))
    setCompanies(list)
    if (!userId) {
      return {}
    }
    const snap = await db.collection('users').doc(userId).get()
    return {
      ...snap.data(),
      id: snap.id
    }
  }, [userId])
  const mode = userId ? 'Edit' : 'Create'
  const checkDuplicateEmail = async (email) => {
    if (userId) return true
    const snap = await db.collection('users').where('email', '==', email).limit(1).get()
    return snap.docs.length ? false : true
  }
  const handleSubmit = async (formData) => {
    setLoading(true)
    formData.email = formData.email.toLowerCase()
    const resultCheck = await checkDuplicateEmail(formData.email)
    if (resultCheck) {
      const ref = db.collection('users')
      let doc = ref.doc()
      if (userId) {
        doc = ref.doc(userId)
      }
      formData = _.pickBy(formData)
      await doc.set(formData, {
        merge: true
      })
      setLoading(false)
      navigate('/admin/user')
    } else {
      setLoading(false)
      message.warning('Email is duplicate');
    }
  }
  if (loading) {
    return <Skeleton />
  }
  return (
    <Card
      title={`${mode} User`}
      // style={{ margin: '1em', borderRadius: '15px', boxShadow: '0px 2px 12px rgba(0,0,0,0.1)', border: 0 }}
      // headStyle={{ backgroundColor: '#CFE2FF', color: '#154D9F', fontWeight: 'bold', borderRadius: '10px 10px 0px 0px' }}
      headStyle={{ fontWeight: 'bold', fontSize: '20px', lineHeight: '23px', backgroundColor: '#CFE2FF', color: '#154D9F' }}
      bodyStyle={{ boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)', borderRadius: '0 0 10px 10px' }}
    >
      <Row>
        <Col span={24}>
          <AntdForm
            labelCol={{ span: 16 }}
            wrapperCol={{ span: 16 }}
            layout="vertical"
            onFinish={handleSubmit}
            initialValues={value}
          >
            <Row style={{ paddingLeft: '150px', marginTop: '30px' }}>
              <Col span={12}>
                <AntdForm.Item name="userName" label="User Name" >
                  <Input />
                </AntdForm.Item>
              </Col>
              <Col span={12}>
                <AntdForm.Item name="email" label="Email" rules={[{ required: true, message: 'Email cannot be blank' }]}>
                  <Input disabled={mode === 'Edit'} />
                </AntdForm.Item>
              </Col>
            </Row>
            <Row style={{ paddingLeft: '150px' }}>
              <Col span={12}>
                <AntdForm.Item name="role" label="Role" rules={[{ required: true, message: 'Role cannot be blank' }]}>
                  <Select>
                    <Select.Option value="Admin">Admin</Select.Option>
                    <Select.Option value="Planner">Planner & Production</Select.Option>
                    <Select.Option value="Purchasing">Sales Management</Select.Option>
                    <Select.Option value="Financial">Financial</Select.Option>
                    <Select.Option value="Customer">Customer</Select.Option>
                  </Select>
                </AntdForm.Item>
              </Col>
              <Col span={12}>
                <AntdForm.Item name="status" label="Active" rules={[{ required: true, message: 'Active cannot be blank' }]}  >
                  <Select >
                    <Select.Option value="Active">Active</Select.Option>
                    {
                      userId &&
                      <Select.Option value="InActive">InActive</Select.Option>
                    }
                  </Select>
                </AntdForm.Item>
              </Col>
            </Row>
            <Row style={{ paddingLeft: '150px' }}>
              <Col span={12}>
                <AntdForm.Item name="companies" label="Company" rules={[{ required: true, message: 'Company cannot be blank' }]}>
                  <Select
                    mode='multiple'
                    options={(companies || []).map((o) => ({
                      label: o.name,
                      value: o.id
                    }))}
                  />
                </AntdForm.Item>
              </Col>
              <Col span={12}></Col>
            </Row>
            <div style={{ textAlign: 'center' }}>
              <Button loading={btnLoading} style={{ width: 300, borderRadius: '8px', height: 40, marginTop: 20 }} htmlType="submit" type="primary">{mode === 'Edit' ? 'Submit' : 'Create'}</Button>
            </div>
          </AntdForm>
        </Col>
      </Row>
    </Card>
  )
}

export default Form

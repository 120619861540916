import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { Menu } from 'antd'

import { useAuth } from '../../contexts/AuthContextProvider'

import './Sidebar.less'

const Sidebar = () => {
  const { role } = useAuth()
  const navigate = useNavigate()

  let location = useLocation()
  // let currentPatch = location.pathname

  // if (['/admin', '/admin/'].indexOf(location.pathname) !== -1) {
  //   if (['Planner', 'Purchasing', 'Customer'].indexOf(role) !== -1) {
  //     currentPatch = '/admin/purchase-order'
  //   } else {
  //     currentPatch = '/admin/product'
  //   }
  // }

  const getItem = (label, key, icon, children, role, roles, order, type) => {
    if (roles && roles.indexOf(role) === -1) {
      return null
    }
    return { key, icon, children, label, type, order }
  }

  const onClick = (e) => {
    if (!e?.key) {
      return
    }
    navigate(e.key)
  }

  const items = [
    getItem('Products', '/admin/product', null, null, role, ['Admin', 'Financial', 'Purchasing', 'Customer', 'Planner']),
    getItem('Purchase Order', '/admin/purchase-order', null, null, role, ['Admin', 'Financial', 'Purchasing', 'Customer', 'Planner']),
    getItem('Financial', '/admin/proforma-invoice', null, null, role, ['Admin', 'Financial']),
    getItem('Create Users', '/admin/user', null, null, role, ['Admin']),
    getItem('Master Data', null, null, [
      getItem('Container', '/admin/master-data/container/list', null, null, role, ['Admin']),
      getItem('Loading Type', '/admin/master-data/loadingType/list', null, null, role, ['Admin']),
      getItem('Consignee', '/admin/master-data/consignee/list', null, null, role, ['Admin']),
      getItem('Country & Port', '/admin/master-data/country-port/list', null, null, role, ['Admin']),
      getItem('Company Information', '/admin/master-data/company/list', null, null, role, ['Admin']),
      getItem('Plate & Pallet', '/admin/master-data/plate-pallet/list', null, null, role, ['Admin']),
    ], role, ['Admin'])
  ]

  return (
    <div className='sidebar'>
      <Menu
        theme='light'
        mode='inline'
        style={{ backgroundColor: '#154D9F' }}
        defaultSelectedKeys={[location.pathname]}
        onClick={onClick}
        items={items}
      />
    </div>
  )
}

export default Sidebar

import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'
import 'firebase/compat/storage'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY || 'AIzaSyCVcfLR0AJxKXqOhD1U8H02JFIB9WvPU7w',
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || 'craftfood-bcaac.firebaseapp.com',
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL || 'https://craftfood-bcaac.firebaseio.com',
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || 'craftfood-bcaac',
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || 'craftfood-bcaac.appspot.com',
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '606647570718',
  appId: process.env.REACT_APP_FIREBASE_APP_ID || '1:606647570718:web:2010a5325440c45caf790f'
}

// TODO: Use a configuration object
firebase.initializeApp(firebaseConfig)

const auth = firebase.auth
const db = firebase.firestore()
const functions = firebase.app().functions('asia-southeast2')
const storage = firebase.storage()

// eslint-disable-next-line no-restricted-globals
if (process.env.REACT_APP_USE_EMULATOR) {
  auth().useEmulator('http://localhost:9099/', { disableWarnings: true })
  db.useEmulator('localhost', 8080)
  functions.useEmulator('localhost', 5001)
  storage.useEmulator('localhost', 9199)
}

export default firebase
export { auth, db, functions, storage }
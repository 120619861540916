import React, { createContext, useContext, useState } from 'react'

const PurchaseOrderContext = createContext({})

const usePurchaseOrderContext = () => {
  const context = useContext(PurchaseOrderContext)
  if (!context) {
    throw new Error('Cannot usePurchaseOrderContext outside PurchaseOrderContextProvider!')
  }
  return context
}

const PurchaseOrderContextProvider = ({ children }) => {
  const [productId, setProductId] = useState()
  const [productStatus, setProductStatus] = useState()
  const [productData, setProductData] = useState([])

  const setProduct = (id, status) => {
    setProductId(id)
    setProductStatus(status)
  }

  const addProduct = (product) => {
    setProductData(prevProducts => ([
      ...prevProducts,
      { ...product, key: prevProducts.length }
    ]))
  }

  const updateProduct = (list) => {
    setProductData([...list.map((o, i) => ({ ...o, key: i }))])
  }

  const clearProduct = () => {
    setProductId()
    setProductStatus()
    setProductData([])
  }

  const contextValue = {
    productId,
    productStatus,
    productData,
    productAction: {
      setProduct,
      addProduct,
      updateProduct,
      clearProduct
    }
  }
  return (
    <PurchaseOrderContext.Provider value={contextValue}>
      {children}
    </PurchaseOrderContext.Provider>
  )
}

export default PurchaseOrderContextProvider
export {
  usePurchaseOrderContext
}

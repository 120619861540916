import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAsync } from 'react-use'

import { Button, Card, Col, Form as AntdForm, Input, InputNumber, Row, Select, Skeleton } from 'antd'

import _ from 'lodash'
// import * as firebase from 'firebase'

import { useAuth } from '../../contexts/AuthContextProvider'

const Form = () => {
  const { TextArea } = Input
  const navigate = useNavigate()
  const { firebaseApp, db, companyId } = useAuth()
  const { productId, containerId } = useParams()
  const [btnLoading, setBtnLoading] = useState(false)
  const [containerType, setContainerType] = useState('')
  // const [selectData, setSelect] = useState('')
  const [productForm] = AntdForm.useForm()
  const { value, loading } = useAsync(async () => {
    // const dataSelect = await db.collection('container').get()
    // const dataContainer = dataSelect.docs.map(item => {
    //   return {
    //     id: item.id,
    //     ...item.data()
    //   }
    // })
    // setSelect(dataContainer)
    if (!productId && !containerId) {
      return {
        bagPerCarton: '',
        containerType: '',
        hsCode: '',
        itemCode: '',
        marks: '',
        price: '',
        productNameEN: '',
        productNameTH: '',
        productRef: '',
        quantityBAG: '',
        quantityCTN: '',
        remarks: '',
        weight: '',
      }
    }
    const snap = await db.collection('products').doc(productId).get()
    setContainerType(_.get(snap.data(), 'containerType'))
    return {
      ...snap.data(),
      id: snap.id
    }
  }, [productId, containerId])
  const mode = productId ? 'Edit' : 'Create'
  const status = productId ? 'Save' : 'Create'
  const handleSubmit = async (formData) => {
    try {
      setBtnLoading(true)
      const ref = db.collection('products')
      let doc = ref.doc()
      if (productId) {
        doc = ref.doc(productId)
      }
      const data = {
        // ..._.pickBy(formData),
        ...formData,
        productRef: _.get(formData, 'productRef', ''),
        productNameEN: _.get(formData, 'productNameEN', ''),
        productNameTH: _.get(formData, 'productNameTH', ''),
        weight: _.get(formData, 'weight', ''),
        bagPerCarton: _.get(formData, 'bagPerCarton', ''),
        quantityCTN: _.get(formData, 'quantityCTN', ''),
        quantityBAG: _.get(formData, 'quantityBAG', ''),
        price: _.get(formData, 'price', ''),
        hsCode: _.get(formData, 'hsCode', ''),
        marks: _.get(formData, 'marks', ''),
        remarks: _.get(formData, 'remarks', ''),
        containerType: _.get(formData, 'containerType', ''),
        createdAt: firebaseApp.firestore.FieldValue.serverTimestamp(),
        cid: companyId
      }
      await doc.set(data, {
        merge: true
      })
      setBtnLoading(false)
      if (productId) {
        navigate(`/admin/product/view/${productId}`)
      } else {
        navigate('/admin/product')
      }
    } catch (error) {
      setBtnLoading(false)
    }
  }
  const typeChange = (data) => {
    productForm.setFieldsValue({
      ...productForm.getFieldValue(),
      reefer20DcTallPaletCtn: "",
      reefer20DcTallPallet: "",
      reefer20Rf: "",
      reefer20RfDbPallet: "",
      reefer20RfDbPalletCtn: "",
      reefer40HcRf: "",
      reefer40HcRfDbPallet: "",
      reefer40HcRfDbPalletCtn: "",
      reefer40HcRfTallPallet: "",
      reefer40HcRfTallPalletCtn: "",
      reeferOdCartonCm: "",
      reeferOdCartonInch: "",
      reeferPalletSize: "",
      dry20Dc: "",
      dry20DcDbPallet: "",
      dry20DcDbPalletCtn: "",
      dry20DcTallPaletCtn: "",
      dry20DcTallPallet: "",
      dry40Hc: "",
      dry40HcDbPallet: "",
      dry40HcDbPalletCtn: "",
      dry40HcTallPallet: "",
      dry40HcTallPalletCtn: "",
      dryOdCartonCm: "",
      dryOdCartonInch: "",
      dryPalletSize: "",
    })
    setContainerType(data)
  }
  if (loading) {
    return <Skeleton />
  }
  return (
    <AntdForm
      form={productForm}
      layout="vertical"
      initialValues={value}
      onFinish={handleSubmit}
    >
      <Card
        title={`${mode} Product`}
        // style={{ margin: '1em', borderRadius: '15px', boxShadow: '0px 2px 12px rgba(0,0,0,0.1)', border: 0 }}
        // headStyle={{ backgroundColor: '#CFE2FF', color: '#154D9F', fontWeight: 'bold', borderRadius: '10px 10px 0px 0px' }}
        headStyle={{ fontWeight: 'bold', fontSize: '20px', lineHeight: '23px', backgroundColor: '#CFE2FF', color: '#154D9F' }}
        bodyStyle={{ boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)', borderRadius: '0 0 10px 10px' }}
      >
        <Row>
          <Col span={3}></Col>
          <Col span={18}>
            <Row gutter={[0, 24]}>
              <div className="content-title">Product Information</div>
            </Row>
            <Row gutter={[32, 0]}>
              <Col span={12}>
                <AntdForm.Item name="itemCode" label="Item Code" rules={[{ required: true, message: 'Item Code cannot be blank' }]}>
                  <Input />
                </AntdForm.Item>
              </Col>
              <Col span={12}>
                <AntdForm.Item name="productRef" label="Product (Ref. Customer PO)" >
                  <Input />
                </AntdForm.Item>
              </Col>
            </Row>
            <Row gutter={[32, 0]}>
              <Col span={12}>
                <AntdForm.Item name="productNameEN" label="Name of Product (EN)" >
                  <Input />
                </AntdForm.Item>
              </Col>
              <Col span={12}>
                <AntdForm.Item name="productNameTH" label="Name of Product (TH)" >
                  <Input />
                </AntdForm.Item>
              </Col>
            </Row>
            <Row gutter={[32, 0]}>
              <Col span={12}>
                <AntdForm.Item name="weight" label="Weight per Bag (Gram)" >
                  <InputNumber
                    formatter={value => new Intl.NumberFormat('en-US').format(value)}
                    parser={value => value.replace(/\\s?|(,*)/g, '')}
                  />
                </AntdForm.Item>
              </Col>
              <Col span={12}>
                <AntdForm.Item name="bagPerCarton" label="Bag per Carton" >
                  <InputNumber
                    formatter={value => new Intl.NumberFormat('en-US').format(value)}
                    parser={value => value.replace(/\\s?|(,*)/g, '')}
                  />
                </AntdForm.Item>
              </Col>
            </Row>
            <Row gutter={[32, 0]}>
              <Col span={12}>
                <AntdForm.Item name="quantityCTN" label="QTY per Container (CTN)">
                  <InputNumber
                    formatter={value => new Intl.NumberFormat('en-US').format(value)}
                    parser={value => value.replace(/\\s?|(,*)/g, '')}
                  />
                </AntdForm.Item>
              </Col>
              <Col span={12}>
                <AntdForm.Item name="quantityBAG" label="QTY per Container (BAG)">
                  <InputNumber
                    formatter={value => new Intl.NumberFormat('en-US').format(value)}
                    parser={value => value.replace(/\\s?|(,*)/g, '')}
                  />
                </AntdForm.Item>
              </Col>
            </Row>
            <Row gutter={[32, 0]}>
              <Col span={12}>
                <AntdForm.Item name="price" label="Price Each (USD)" >
                  <InputNumber
                    formatter={value => new Intl.NumberFormat('en-US').format(value)}
                    parser={value => value.replace(/\\s?|(,*)/g, '')}
                  />
                </AntdForm.Item>
              </Col>
              <Col span={12}>
                <AntdForm.Item name="hsCode" label="H.S. Code" >
                  <Input />
                </AntdForm.Item>
              </Col>
            </Row>
            <Row gutter={[32, 0]}>
              <Col span={12}>
                <AntdForm.Item name="marks" label="Marks" >
                  <Input />
                </AntdForm.Item>
              </Col>
              <Col span={12}>
              </Col>
            </Row>
            <Row >
              <Col span={24}>
                <AntdForm.Item name="remarks" label="Remarks" >
                  <TextArea />
                </AntdForm.Item>
              </Col>
            </Row>
            <Row gutter={[0, 24]}>
              <div className="content-title">Container Loading Details</div>
            </Row>
            <Row >
              <Col span={12}>
                <AntdForm.Item name="containerType" label="Type" >
                  <Select placeholder="Select Type" onChange={(data) => typeChange(data)}>
                    <Select.Option value="dryContainer">DRY CONTAINER</Select.Option>
                    <Select.Option value="reeferContainer" >REEFER CONTAINER</Select.Option>
                  </Select>
                </AntdForm.Item>
              </Col>
            </Row>
            {
              containerType === 'dryContainer' &&
              <div>
                <Row gutter={[32, 0]}>
                  <Col span={12}>
                    <AntdForm.Item name="dryOdCartonCm" label="OD Cartons Size(LxWxH)" >
                      <Input addonAfter="CM" />
                    </AntdForm.Item>
                    <AntdForm.Item name="dryOdCartonInch"  >
                      <Input addonAfter="INCH" />
                    </AntdForm.Item>
                  </Col>
                  <Col span={12}>
                    <AntdForm.Item name="dryPalletSize" label="Pallet Size" >
                      <Input addonAfter="mm." />
                    </AntdForm.Item>
                  </Col>
                </Row>
                <Row gutter={[32, 0]}>
                  <Col span={12}>
                    <AntdForm.Item name="dry20Dc" label="20'Dc (On Floor)" >
                      <Input addonAfter="CTNS" />
                    </AntdForm.Item>
                  </Col>
                  <Col span={12}>
                    <AntdForm.Item name="dry40Hc" label="40'HC (On Floor)" >
                      <Input addonAfter="CTNS" />
                    </AntdForm.Item>
                  </Col>
                </Row>
                <Row gutter={[32, 0]}>
                  <Col span={12}>
                    <AntdForm.Item name="dry20DcTallPallet" label="20'Dc (Tall Pallet)">
                      <Input />
                    </AntdForm.Item>
                    <AntdForm.Item name="dry20DcTallPaletCtn" label="" >
                      <Input addonAfter="CTNS" />
                    </AntdForm.Item>
                  </Col>
                  <Col span={12}>
                    <AntdForm.Item name="dry40HcTallPallet" label="40'HC (Tall Pallet)" >
                      <Input />
                    </AntdForm.Item>
                    <AntdForm.Item name="dry40HcTallPalletCtn" label="" >
                      <Input addonAfter="CTNS" />
                    </AntdForm.Item>
                  </Col>
                </Row>
                <Row gutter={[32, 0]}>
                  <Col span={12}>
                    <AntdForm.Item name="dry20DcDbPallet" label="20'Dc (Double Stack Pallet)" >
                      <Input />
                    </AntdForm.Item>
                    <AntdForm.Item name="dry20DcDbPalletCtn" label="" >
                      <Input addonAfter="CTNS" />
                    </AntdForm.Item>
                  </Col>
                  <Col span={12}>
                    <AntdForm.Item name="dry40HcDbPallet" label="40'HC (Double Stack Pallet)">
                      <Input />
                    </AntdForm.Item>
                    <AntdForm.Item name="dry40HcDbPalletCtn" label="">
                      <Input addonAfter="CTNS" />
                    </AntdForm.Item>
                  </Col>
                </Row>
              </div>
            }
            {
              containerType === 'reeferContainer' &&
              <div>
                <Row gutter={[32, 0]}>
                  <Col span={12}>
                    <AntdForm.Item name="reeferOdCartonCm" label="OD Cartons Size(LxWxH)" >
                      <Input addonAfter="CM" />
                    </AntdForm.Item>
                    <AntdForm.Item name="reeferOdCartonInch" >
                      <Input addonAfter="INCH" />
                    </AntdForm.Item>
                  </Col>
                  <Col span={12}>
                    <AntdForm.Item name="reeferPalletSize" label="Pallet Size" >
                      <Input addonAfter="mm." />
                    </AntdForm.Item>
                  </Col>
                </Row>
                <Row gutter={[32, 0]}>
                  <Col span={12}>
                    <AntdForm.Item name="reefer20Rf" label="20'RF (On Floor)" >
                      <Input addonAfter="CTNS" />
                    </AntdForm.Item>
                  </Col>
                  <Col span={12}>
                    <AntdForm.Item name="reefer40HcRf" label="40'HC RF (On Floor)" >
                      <Input addonAfter="CTNS" />
                    </AntdForm.Item>
                  </Col>
                </Row>
                <Row gutter={[32, 0]}>
                  <Col span={12}>
                    <AntdForm.Item name="reefer20DcTallPallet" label="20'RF (Tall Pallet)" >
                      <Input />
                    </AntdForm.Item>
                    <AntdForm.Item name="reefer20DcTallPaletCtn" label="" >
                      <Input addonAfter="CTNS" />
                    </AntdForm.Item>
                  </Col>
                  <Col span={12}>
                    <AntdForm.Item name="reefer40HcRfTallPallet" label="40'HC RF (Tall Pallet)">
                      <Input />
                    </AntdForm.Item>
                    <AntdForm.Item name="reefer40HcRfTallPalletCtn" label="">
                      <Input addonAfter="CTNS" />
                    </AntdForm.Item>
                  </Col>
                </Row>
                <Row gutter={[32, 0]}>
                  <Col span={12}>
                    <AntdForm.Item name="reefer20RfDbPallet" label="20'RF (Double Stack Pallet)" >
                      <Input />
                    </AntdForm.Item>
                    <AntdForm.Item name="reefer20RfDbPalletCtn" label="" >
                      <Input addonAfter="CTNS" />
                    </AntdForm.Item>
                  </Col>
                  <Col span={12}>
                    <AntdForm.Item name="reefer40HcRfDbPallet" label="40'HC RF (Double Stack Pallet)" >
                      <Input />
                    </AntdForm.Item>
                    <AntdForm.Item name="reefer40HcRfDbPalletCtn" label="" >
                      <Input addonAfter="CTNS" />
                    </AntdForm.Item>
                  </Col>
                </Row>
              </div>
            }
            <Row style={{ marginTop: '30px' }}>
              <Col span={24} style={{ textAlign: 'center' }}>
                <Button style={{ width: 300, borderRadius: '8px', height: 40 }} type="primary" htmlType="submit" loading={btnLoading}>{status}</Button>
              </Col>
            </Row>
          </Col>
          <Col span={3}></Col>
        </Row>
      </Card>
    </AntdForm>
  )
}

export default Form

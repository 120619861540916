import React, { useState } from 'react'

import { Button } from 'antd'

import { useAuth } from '../../contexts/AuthContextProvider'
import config from '../../config'

const EmailVerifyPage = () => {
  const { user, auth } = useAuth()
  const [loading, setLoading] = useState(false)
  const handleResent = () => {
    const actionCodeSetting = {
      url: config.hostName,
      handleCodeInApp: true,
    }
    user.sendEmailVerification(actionCodeSetting)
  }
  const handleSignOut = async () => {
    try {
      setLoading(true)
      await auth().signOut()
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  return (
    <div>
      Please check your email
      <Button onClick={handleResent} loading={loading}>Resend email</Button>
      <div>
        <Button onClick={handleSignOut} loading={loading}>Sign Out</Button>
      </div>
    </div>
  )
}

export default EmailVerifyPage
